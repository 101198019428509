// Handy helpers for formatting strings -- rendering times, pluralizing, etc.
export function formatCallTime(callStartTime, time) {
    // Input times are in milliseconds.
    const sign = callStartTime > time ? "-" : "";
    const callTime = Math.round(Math.abs(time - callStartTime) / 1000);
    const minutes = Math.floor(callTime / 60);
    const seconds = callTime % 60;
    // Format the time as MM:SS.
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");
    return `${sign}${formattedMinutes}:${formattedSeconds}`;
}
export const formatTime = (date) => {
    return date.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    });
};
export const formatDate = (date) => {
    return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
    });
};
export const formatToMM_DD_YYYY = (date) => {
    return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
    });
};
export const formatToYYYY_MM_DD = (date) => {
    // if you lookup online, you'll find `date.toISOString().split('T')[0]` or `date.toISOString().substring(10)`
    // that doesn't work because toISOString() will return the UTC time, not local, so it'd sometimes give wrong date.
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
};
export const truncateString = (str, maxLength) => {
    if (str.length <= maxLength)
        return str;
    return str.slice(0, maxLength - 3) + "...";
};
export var CallFilter;
(function (CallFilter) {
    CallFilter["DATES"] = "dates";
    CallFilter["ACCOUNTS"] = "accounts";
    CallFilter["CONTACTS"] = "contacts";
})(CallFilter || (CallFilter = {}));
export const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};
export const isValidUrl = (url) => {
    if (!url)
        return false;
    try {
        new URL(url);
        return true;
    }
    catch (e) {
        return false;
    }
};
