import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import BaseTable from "./base_table";
import axios from "axios";
import BaseModal from "./base_modal";
import { getCookie } from "./cookies";
import { showErrorModal } from "./error_modal";
import WaitingSpinner from "./waiting_spinner";
import ContactView from "./contact_view";
import usePagination from "./pagination/use_pagination";
import { Link } from "react-router-dom";
export default function CallTable() {
    const [rows, setRows] = useState([]);
    const [addCallOpen, setAddCallOpen] = useState(false);
    const [editingCallOpen, setEditingCallOpen] = useState(false);
    const [editingCall, setEditingCall] = useState({
        cells: [],
        id: -1,
    });
    const [isSaving, setIsSaving] = useState(false);
    const paginationStateControl = usePagination();
    // Map from call_id => set of Contact[] participants on this call.
    const [participantsForCall, setParticipantsForCall] = useState(new Map());
    let localTimeToUTC = (localTime) => {
        // You'd think we'd need to do something more complicated to convert this, but Date() seems
        // to know it's ingesting a local time, and toISOString() always returns UTC.
        let localDate = new Date(localTime);
        return localDate.toISOString();
    };
    let UTCToLocalTime = (utcTime) => {
        return new Date(utcTime).toLocaleString("en-US", {
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });
    };
    let lookupCalls = () => {
        axios
            .get("/api/get_live_calls_paginated", {
            params: {
                page_size: paginationStateControl.pageSize,
                page: paginationStateControl.currentPage,
            },
            headers: {
                Authorization: localStorage.getItem("wiser_jwt"),
            },
        })
            .then((res) => {
            var _a, _b, _c, _d;
            let calls = res.data.data;
            let newRows = [];
            let participantMap = new Map();
            for (let i = 0; i < calls.length; i++) {
                let name = calls[i].name;
                let participants = ((_a = calls[i].participants) === null || _a === void 0 ? void 0 : _a.map((contact) => contact.email).join(",")) ||
                    "";
                participantMap.set(calls[i].id, calls[i].participants || []);
                let time = UTCToLocalTime(calls[i].call_time);
                newRows.push({
                    id: calls[i].id,
                    cells: [
                        name,
                        participants,
                        (_c = (_b = calls[i].zoom_meeting_id) === null || _b === void 0 ? void 0 : _b.toString()) !== null && _c !== void 0 ? _c : "",
                        (_d = calls[i].zoom_meeting_passcode) !== null && _d !== void 0 ? _d : "",
                        time,
                        _jsx(Link, Object.assign({ className: "text-blue-600", to: `/call_transcript/${calls[i].id}` }, { children: "View Transcript" })),
                    ],
                });
            }
            setParticipantsForCall(participantMap);
            setRows(newRows);
            paginationStateControl.setTotalRows(res.data.total);
        });
    };
    useEffect(() => {
        lookupCalls();
    }, [paginationStateControl.currentPage]);
    let createCall = (name, participants, zoom_meeting_id, zoom_passcode, time) => {
        setIsSaving(true);
        axios
            .post("/api/create_live_call", {
            name: name,
            zoom_meeting_id: zoom_meeting_id,
            zoom_passcode: zoom_passcode,
            call_time: localTimeToUTC(time),
        }, {
            // TODO(mark): move this into a wrapper around axios.
            headers: {
                Authorization: localStorage.getItem("wiser_jwt"),
                "X-CSRFToken": getCookie("csrftoken"),
            },
        })
            .then((res) => {
            if (participants.length > 0) {
                axios
                    .post("/api/set_call_participants", {
                    call_id: res.data.id,
                    participants: participants
                        .split(",")
                        .map((email) => email.trim())
                        // Removes empty strings.
                        .filter((email) => email.length > 0),
                }, {
                    headers: {
                        Authorization: localStorage.getItem("wiser_jwt"),
                        "X-CSRFToken": getCookie("csrftoken"),
                    },
                })
                    .catch((error) => {
                    showErrorModal(error);
                })
                    .then((res) => {
                    setIsSaving(false);
                    setAddCallOpen(false);
                    lookupCalls();
                });
            }
            else {
                setIsSaving(false);
                setAddCallOpen(false);
                lookupCalls();
            }
        })
            .catch((error) => {
            showErrorModal(error);
        });
    };
    let editCall = (id, name, participants, zoom_meeting_id, zoom_passcode, time) => {
        setIsSaving(true);
        axios
            .post("/api/update_live_call", {
            id: id,
            name: name,
            zoom_meeting_id: zoom_meeting_id,
            zoom_passcode: zoom_passcode,
            call_time: localTimeToUTC(time),
        }, {
            headers: {
                Authorization: localStorage.getItem("wiser_jwt"),
                "X-CSRFToken": getCookie("csrftoken"),
            },
        })
            .catch((error) => {
            showErrorModal(error);
        })
            .then((res) => {
            // Consider blending this into the update_live_call RPC above.
            if (participants.length > 0) {
                axios
                    .post("/api/set_call_participants", {
                    call_id: id,
                    participants: participants
                        .split(",")
                        .map((email) => email.trim())
                        // Removes empty strings.
                        .filter((email) => email.length > 0),
                }, {
                    headers: {
                        Authorization: localStorage.getItem("wiser_jwt"),
                        "X-CSRFToken": getCookie("csrftoken"),
                    },
                })
                    .catch((error) => {
                    showErrorModal(error);
                })
                    .then((res) => {
                    setIsSaving(false);
                    setEditingCallOpen(false);
                    lookupCalls();
                });
            }
            else {
                setIsSaving(false);
                setEditingCallOpen(false);
                lookupCalls();
            }
        });
    };
    let deleteCall = (id) => {
        axios
            .post("/api/delete_live_call", {
            id: id,
        }, {
            headers: {
                Authorization: localStorage.getItem("wiser_jwt"),
                "X-CSRFToken": getCookie("csrftoken"),
            },
        })
            .catch((error) => {
            showErrorModal(error);
        })
            .then((res) => {
            lookupCalls();
        });
    };
    let fieldsToMap = (fields) => {
        let map = new Map();
        for (let i = 0; i < fields.length; i++) {
            map.set(fields[i].name, fields[i].value);
        }
        return map;
    };
    let editingDate = new Date();
    if (editingCall && editingCall.cells.length > 2) {
        editingDate = new Date(editingCall.cells[4]);
    }
    // Our time-picker is picky: it needs a certain time resolution, and wants an ISO string, which
    // Date() doesn't like to give us in a local time zone. So, we force it to adjust to the local time zone.
    // What's returned is a date string, which works fine, as Date() assumes the date it's reading is local
    // (perhaps because it lacks the 'Z' suffix).
    const currentDate = new Date();
    let editingDateString = new Date(editingDate.getTime() - currentDate.getTimezoneOffset() * 60000)
        .toISOString()
        .slice(0, 16);
    return (_jsxs(_Fragment, { children: [_jsx(BaseTable, { title: "Calls", description: "Upcoming calls on your calendar", addName: "Add Call", addCallback: () => {
                    setAddCallOpen(true);
                }, columnNames: [
                    "Name",
                    "Participants",
                    "Zoom Meeting Id",
                    "Zoom Passcode",
                    "Start Time",
                    "Transcript",
                ], rows: rows, paginationStateControl: paginationStateControl, editCallback: (call) => {
                    setEditingCall(call);
                    setEditingCallOpen(true);
                }, deleteCallback: (call) => {
                    deleteCall(call.id);
                } }), _jsx(BaseModal, { title: "Add Call", description: "A call is an upcoming meeting with a set of participants.", open: addCallOpen, setOpen: setAddCallOpen, submitCallback: (fields) => {
                    let fieldMap = fieldsToMap(fields);
                    createCall(fieldMap.get("Name"), fieldMap.get("Participants"), Number(fieldMap.get("Zoom Meeting Id")), fieldMap.get("Zoom Passcode"), fieldMap.get("Time"));
                }, fields: [
                    {
                        name: "Name",
                        type: "text",
                        value: "",
                        required: true,
                        placeholder: "Name of this call",
                    },
                    {
                        name: "Participants",
                        type: "text",
                        value: "",
                        placeholder: "Participants",
                    },
                    {
                        name: "Zoom Meeting Id",
                        type: "number",
                        value: "",
                        placeholder: "Zoom Meeting Id",
                    },
                    {
                        name: "Zoom Passcode",
                        type: "text",
                        value: "",
                        placeholder: "Zoom Passcode",
                    },
                    {
                        name: "Time",
                        type: "datetime-local",
                        value: "",
                        required: true,
                        placeholder: "Start time",
                    },
                ], submitText: isSaving ? _jsx(WaitingSpinner, { text: "Saving..." }) : "Add Call" }), _jsx(BaseModal, { title: "Edit Call", description: "Edit the existing call called " + (editingCall === null || editingCall === void 0 ? void 0 : editingCall.cells[0]), open: editingCallOpen, setOpen: setEditingCallOpen, submitCallback: (fields) => {
                    let fieldMap = fieldsToMap(fields);
                    // There is an extra refresh that happens on submit, causing all the fields to look
                    // like they've reverted while we are saving the contact. The following is a hack to
                    // make it look like the fields are in their new state while we are saving.
                    var call = editingCall;
                    call.cells[0] = fieldMap.get("Name");
                    call.cells[1] = fieldMap.get("Participants");
                    call.cells[2] = fieldMap.get("Zoom Meeting Id");
                    call.cells[3] = fieldMap.get("Zoom Passcode");
                    call.cells[4] = fieldMap.get("Time");
                    setEditingCall(call);
                    editCall(editingCall.id, fieldMap.get("Name"), fieldMap.get("Participants"), Number(fieldMap.get("Zoom Meeting Id")), fieldMap.get("Zoom Passcode"), fieldMap.get("Time"));
                }, fields: [
                    {
                        name: "Name",
                        type: "text",
                        value: editingCall === null || editingCall === void 0 ? void 0 : editingCall.cells[0],
                        required: true,
                        placeholder: "Name of this call",
                    },
                    {
                        name: "Participants",
                        type: "text",
                        value: editingCall === null || editingCall === void 0 ? void 0 : editingCall.cells[1],
                        placeholder: "Participants",
                    },
                    {
                        name: "Zoom Meeting Id",
                        type: "number",
                        value: editingCall === null || editingCall === void 0 ? void 0 : editingCall.cells[2],
                        placeholder: "Zoom Meeting Id",
                    },
                    {
                        name: "Zoom Passcode",
                        type: "text",
                        value: editingCall === null || editingCall === void 0 ? void 0 : editingCall.cells[3],
                        placeholder: "Zoom Passcode",
                    },
                    {
                        name: "Time",
                        type: "datetime-local",
                        value: editingDateString,
                        required: true,
                        placeholder: "Start time",
                    },
                ], submitText: isSaving ? _jsx(WaitingSpinner, { text: "Saving..." }) : "Save Changes", children: _jsxs("div", { children: [_jsx("hr", { className: "h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" }), _jsx(ContactView, { contacts: participantsForCall.get(editingCall === null || editingCall === void 0 ? void 0 : editingCall.id) || [] })] }) })] }));
}
