var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ArrowRightIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import Dropdown from "../common/dropdown";
import { PostCallOutputTarget, } from "../types";
import { useEffect, useState } from "react";
import classNames from "../class_names";
import Ellipse from "../common/ellipse";
import { UPDATE_TYPES } from "./data_processing_config";
import ErrorPopover from "../common/error_popover";
import FieldTargetPopover from "./field_target_popover";
import { confirmModal } from "../common/confirmation_modal";
import { getEmailContentFromText, getFieldSchemaForPostCallOutputItem, isEmailItem, } from "./utils";
import { SalesforceTargetItem } from "./sfdc_target_renderers";
import { SlackTargetItem } from "./slack_target_renderers";
import { EmptyTargetFieldMappingValue } from "./field_mapping_new_value";
import { WiserCrmTargetItem } from "./wiser_crm_target_renderers";
import { FieldMappingCardValue } from "./field_mapping_card_value";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { Button } from "../components/button";
import { EmailLink } from "../common/email_link";
import { HubspotTargetItem } from "./hubspot_target_renderer";
const SourcePlaybookItem = ({ playbookName, playbookItemName, }) => {
    return (_jsxs("div", Object.assign({ className: "px-2 py-1 flex items-center gap-4 max-w-xs" }, { children: [_jsx("img", { src: "https://storage.googleapis.com/wiser-ai-public2/wiser_logo_color.png", className: "w-6 h-6" }), _jsxs("div", Object.assign({ className: "inline-flex flex-col items-start" }, { children: [_jsx("span", Object.assign({ className: classNames("flex items-center self-stretch gap-2", "text-xs font-medium text-gray-600") }, { children: playbookName })), _jsx("span", Object.assign({ className: "text-sm font-semibold text-black" }, { children: playbookItemName }))] }))] })));
};
const SourceManualNote = () => {
    return (_jsxs("div", Object.assign({ className: "px-2 py-1 flex items-center gap-4 max-w-xs" }, { children: [_jsx("img", { src: "https://storage.googleapis.com/wiser-ai-public2/wiser_logo_color.png", className: "w-6 h-6" }), _jsxs("div", Object.assign({ className: "inline-flex flex-col items-start" }, { children: [_jsx("span", Object.assign({ className: classNames("flex items-center self-stretch gap-2", "text-xs font-medium text-gray-600") }, { children: "Manual note" })), _jsx("span", Object.assign({ className: "text-sm font-semibold text-black" }, { children: "Notes captured during the call" }))] }))] })));
};
const TargetItem = ({ postCallOutputItem, processing, integrations, integrationsData, }) => {
    var _a, _b;
    const targetProcessingOrIgnored = processing ||
        postCallOutputItem.state === "PROCESSED" ||
        postCallOutputItem.state === "PROCESSING" ||
        postCallOutputItem.state === "IGNORED";
    const targetIntegrationData = integrationsData.find((integrationData) => integrationData.name === postCallOutputItem.target);
    return (_jsxs("div", Object.assign({ className: classNames("px-2 py-1 flex items-center gap-4 shrink-0", targetProcessingOrIgnored
            ? ""
            : "hover:outline-none hover:rounded-lg hover:bg-gray-200 hover:cursor-pointer") }, { children: [!postCallOutputItem.target && (_jsx("span", Object.assign({ className: "font-bold" }, { children: "Select a target" }))), postCallOutputItem.target === PostCallOutputTarget.SALESFORCE && (_jsx(SalesforceTargetItem, { targetDetails: postCallOutputItem.targetDetails, sfdcFields: targetIntegrationData
                    ? targetIntegrationData.sfdcFields
                    : undefined, instanceUrl: (_b = (_a = integrations.find((i) => i.name === PostCallOutputTarget.SALESFORCE)) === null || _a === void 0 ? void 0 : _a.metadata) === null || _b === void 0 ? void 0 : _b.instanceUrl })), postCallOutputItem.target === PostCallOutputTarget.HUBSPOT && (_jsx(HubspotTargetItem, { targetDetails: postCallOutputItem.targetDetails, hubspotFields: targetIntegrationData
                    ? targetIntegrationData.crmFields
                    : undefined })), postCallOutputItem.target === PostCallOutputTarget.SLACK && (_jsx(SlackTargetItem, { targetDetails: postCallOutputItem.targetDetails })), postCallOutputItem.target === PostCallOutputTarget.WISER_CRM && (_jsx(WiserCrmTargetItem, { targetDetails: postCallOutputItem.targetDetails, crmFields: targetIntegrationData.crmFields })), !targetProcessingOrIgnored && (_jsx(ChevronDownIcon, { className: "w-5 h-5 text-gray-400" }))] })));
};
const TargetActions = ({ postCallOutputItem, onSendClick, itemLoadingStatus, onDataItemValueUpdate, }) => {
    var _a, _b, _c, _d;
    const itemBeingSynced = itemLoadingStatus === "SYNCING" ||
        postCallOutputItem.state === "PROCESSING";
    const itemUpdating = itemLoadingStatus === "UPDATING" || itemLoadingStatus === "USER_EDITING";
    const itemInInvalidValueState = itemLoadingStatus === "INVALID_FIELD_VALUE";
    const itemHasNonEmptyValueToPush = ((_b = (_a = postCallOutputItem.valueToUpdate) !== null && _a !== void 0 ? _a : postCallOutputItem.proposedValue) !== null && _b !== void 0 ? _b : "")
        .length > 0;
    const sendDisabled = itemInInvalidValueState ||
        itemBeingSynced ||
        itemUpdating ||
        postCallOutputItem.state === "IGNORED" ||
        !postCallOutputItem.target ||
        !postCallOutputItem.targetDetails ||
        !itemHasNonEmptyValueToPush;
    const updateTypeOptions = [
        ...(postCallOutputItem.target
            ? (_c = UPDATE_TYPES.get(postCallOutputItem.target)) !== null && _c !== void 0 ? _c : []
            : []),
    ];
    const [selectedUpdateType, setSelectedUpdateType] = useState(updateTypeOptions.length > 0 ? updateTypeOptions[0].value : null);
    useEffect(() => {
        setSelectedUpdateType(updateTypeOptions.length > 0 ? updateTypeOptions[0].value : null);
    }, [postCallOutputItem.target]);
    return (_jsxs("div", Object.assign({ className: "flex items-center justify-center py-1 opacity-100" }, { children: [updateTypeOptions.length > 0 && (_jsx(Dropdown, { options: updateTypeOptions.map((updateType) => ({
                    value: updateType.value,
                    label: updateType.label,
                })), defaultSelected: selectedUpdateType, selectedOption: selectedUpdateType, onOptionSelect: (updateTypeValue) => __awaiter(void 0, void 0, void 0, function* () {
                    yield confirmModal(
                    /* onConfirm= */ () => __awaiter(void 0, void 0, void 0, function* () {
                        var _e;
                        yield ((_e = updateTypeOptions
                            .find((updateType) => updateType.value === updateTypeValue)) === null || _e === void 0 ? void 0 : _e.applyLambda(postCallOutputItem, onDataItemValueUpdate));
                        // Update the selected update type if the user confirmed.
                        setSelectedUpdateType(updateTypeValue);
                    }), 
                    /* title= */ "Your changes will be reset", 
                    /* message= */ "Changing the way that data is sent will reset all edits that you made to the new value. If you want to preserve your changes, go back now.", 
                    /* confirmLabel= */ "Save and reset", 
                    /* cancelLabel= */ "Go back", 
                    /* id= */ `update-type-change-confirmation-${postCallOutputItem.callId}`);
                }), disabled: itemBeingSynced ||
                    itemInInvalidValueState ||
                    postCallOutputItem.state === "PROCESSED" ||
                    postCallOutputItem.state === "PROCESSING" ||
                    postCallOutputItem.state === "IGNORED" ||
                    !postCallOutputItem.target ||
                    !postCallOutputItem.targetDetails })), _jsxs("div", Object.assign({ className: "flex items-center gap-2" }, { children: [postCallOutputItem.state === "FAILED" && (_jsx(ErrorPopover, { errorTitle: postCallOutputItem.statusCode, errorMessage: (_d = postCallOutputItem.failureReason) !== null && _d !== void 0 ? _d : "" })), postCallOutputItem.state === "PROCESSED" ? (_jsxs("div", Object.assign({ className: "flex items-center gap-2 py-1 px-2 rounded-lg border border-solid border-green-200 bg-green-100 text-green-800" }, { children: [_jsx(Ellipse, { classes: "w-1 h-1 bg-green-700" }), _jsx("span", { children: "Sent" })] }))) : (_jsx("button", Object.assign({ className: classNames("px-4 py-2 rounded-full border border-solid bg-white font-bold text-sm", sendDisabled
                            ? "text-gray-500 border-gray-400 hover:cursor-not-allowed"
                            : "text-blue-500 border-blue-400"), onClick: onSendClick, disabled: sendDisabled }, { children: itemBeingSynced ? "Sending..." : "Send" })))] }))] })));
};
const FieldMappingTarget = ({ call, postCallOutputItem, itemLoadingStatus, onSendClick, onDataItemTargetUpdate, onDataItemValueUpdate, postCallIntegrations, integrationsData, }) => {
    var _a, _b, _c, _d;
    const processing = itemLoadingStatus === "SYNCING" ||
        itemLoadingStatus === "UPDATING" ||
        itemLoadingStatus === "USER_EDITING";
    return (_jsxs("div", Object.assign({ className: "inline-flex flex-row items-center justify-between py-1 px-4 bg-gray-100 rounded" }, { children: [_jsxs("div", Object.assign({ className: classNames("inline-flex flex-row gap-4 items-center", postCallOutputItem.state === "IGNORED" ? "opacity-50" : "") }, { children: [postCallOutputItem.fieldMap.sourcePlaybook &&
                        postCallOutputItem.fieldMap.sourcePlaybookItem ? (_jsx(SourcePlaybookItem, { playbookName: postCallOutputItem.fieldMap.sourcePlaybook.name, playbookItemName: postCallOutputItem.fieldMap.sourcePlaybookItem.name })) : (_jsx(SourceManualNote, {})), _jsx(ArrowRightIcon, { className: "w-5 h-5" }), isEmailItem(postCallOutputItem) ? (_jsx("div", Object.assign({ className: "flex items-center gap-4 py-1 px-2" }, { children: _jsx("img", { src: "https://storage.googleapis.com/wiser-ai-public2/gmail_logo.png", className: "w-6 h-6 shrink-0" }) }))) : (_jsx(FieldTargetPopover, { call: call, postCallOutputItem: postCallOutputItem, popoverButton: _jsx(TargetItem, { postCallOutputItem: postCallOutputItem, processing: processing, integrations: postCallIntegrations, integrationsData: integrationsData }), disabled: processing ||
                            postCallOutputItem.state === "PROCESSING" ||
                            postCallOutputItem.state === "PROCESSED" ||
                            postCallOutputItem.state === "IGNORED", onDataItemTargetUpdate: onDataItemTargetUpdate, postCallIntegrations: postCallIntegrations, integrationsData: integrationsData }))] })), isEmailItem(postCallOutputItem) ? (_jsx(Button, Object.assign({ variant: "link", className: classNames("px-4 py-2 rounded-full border border-solid bg-white font-bold text-sm", "text-blue-500 border-blue-400") }, { children: _jsx(EmailLink, { subject: getEmailContentFromText((_b = (_a = postCallOutputItem.valueToUpdate) !== null && _a !== void 0 ? _a : postCallOutputItem.proposedValue) !== null && _b !== void 0 ? _b : "").subject, body: getEmailContentFromText((_d = (_c = postCallOutputItem.valueToUpdate) !== null && _c !== void 0 ? _c : postCallOutputItem.proposedValue) !== null && _d !== void 0 ? _d : "").body, label: _jsxs("div", Object.assign({ className: "flex gap-2 items-center justify-center" }, { children: ["Open in Gmail", _jsx(ArrowTopRightOnSquareIcon, { className: "w-4 h-4" })] })) }) }))) : (_jsx(TargetActions, { postCallOutputItem: postCallOutputItem, onSendClick: onSendClick, itemLoadingStatus: itemLoadingStatus, onDataItemValueUpdate: onDataItemValueUpdate }))] })));
};
const FieldMappingValue = ({ postCallOutputItem, undoStack, onDataItemValueUpdate, toggleDataItemEditOpen, fieldSchema, showTrackerNotes, }) => {
    return (_jsx(_Fragment, { children: postCallOutputItem.target || isEmailItem(postCallOutputItem) ? (_jsx(FieldMappingCardValue, { postCallOutputItem: postCallOutputItem, undoStack: undoStack, onDataItemValueUpdate: onDataItemValueUpdate, toggleDataItemEditOpen: toggleDataItemEditOpen, fieldSchema: fieldSchema, showCurrentFieldValue: 
            // TODO: This should be moved to the integration config
            postCallOutputItem.target === PostCallOutputTarget.SALESFORCE ||
                postCallOutputItem.target === PostCallOutputTarget.WISER_CRM, showTrackerNotes: showTrackerNotes })) : (_jsx(EmptyTargetFieldMappingValue, { postCallOutputItem: postCallOutputItem })) }));
};
const FieldMappingCard = (props) => {
    const fieldSchema = getFieldSchemaForPostCallOutputItem(props.postCallOutputItem, props.integrationsData);
    return (_jsxs("div", Object.assign({ className: "flex flex-col items-stretch gap-2 p-1 border border-solid border-gray-300 rounded-lg bg-white" }, { children: [_jsx(FieldMappingTarget, { call: props.call, postCallOutputItem: props.postCallOutputItem, itemLoadingStatus: props.itemLoadingStatus, onSendClick: props.onSendClick, onDataItemTargetUpdate: props.onDataItemTargetUpdate, onDataItemValueUpdate: props.onDataItemValueUpdate, postCallIntegrations: props.postCallIntegrations, integrationsData: props.integrationsData }), _jsx(FieldMappingValue, { postCallOutputItem: props.postCallOutputItem, undoStack: props.undoStack, onDataItemValueUpdate: props.onDataItemValueUpdate, toggleDataItemEditOpen: props.toggleDataItemEditOpen, fieldSchema: fieldSchema, showTrackerNotes: !!props.showTrackerNotes })] })));
};
const TargetItemSummary = ({ postCallOutputItem, }) => {
    return (_jsxs("div", Object.assign({ className: classNames("px-2 py-1 flex items-center gap-4 shrink-0") }, { children: [!postCallOutputItem.target && (_jsx("span", Object.assign({ className: "font-bold" }, { children: "No target selected" }))), postCallOutputItem.target === PostCallOutputTarget.SALESFORCE && (_jsx(SalesforceTargetItem, { targetDetails: postCallOutputItem.targetDetails })), postCallOutputItem.target === PostCallOutputTarget.SLACK && (_jsx(SlackTargetItem, { targetDetails: postCallOutputItem.targetDetails })), postCallOutputItem.target === PostCallOutputTarget.WISER_CRM && (_jsx(WiserCrmTargetItem, { targetDetails: postCallOutputItem.targetDetails })), postCallOutputItem.target === PostCallOutputTarget.HUBSPOT && (_jsx(HubspotTargetItem, { targetDetails: postCallOutputItem.targetDetails }))] })));
};
const FieldMappingTargetSummary = ({ postCallOutputItem, }) => {
    return (_jsx("div", Object.assign({ className: "inline-flex flex-row items-stretch items-center justify-between py-1 px-4 bg-gray-100 rounded" }, { children: _jsxs("div", Object.assign({ className: classNames("inline-flex flex-row gap-4 items-center", postCallOutputItem.state === "IGNORED" ? "opacity-50" : "") }, { children: [postCallOutputItem.fieldMap.sourcePlaybook &&
                    postCallOutputItem.fieldMap.sourcePlaybookItem ? (_jsx(SourcePlaybookItem, { playbookName: postCallOutputItem.fieldMap.sourcePlaybook.name, playbookItemName: postCallOutputItem.fieldMap.sourcePlaybookItem.name })) : (_jsx(SourceManualNote, {})), _jsx(ArrowRightIcon, { className: "w-5 h-5" }), _jsx(TargetItemSummary, { postCallOutputItem: postCallOutputItem })] })) })));
};
/**
 * This component is used to render the summary of a post call output item
 * before the complete details are fetched for the post call output item.
 * This renders a loading spinner on top of the value summary to indicate
 * that the details are being fetched.
 *
 * @param postCallOutputItem The post call output item object to render
 * @returns
 */
const FieldMappingCardSummary = ({ postCallOutputItem, }) => {
    return (_jsxs("div", Object.assign({ className: "flex flex-col items-stretch gap-2 p-1 border border-solid border-gray-300 rounded-lg bg-white" }, { children: [_jsx(FieldMappingTargetSummary, { postCallOutputItem: postCallOutputItem }), _jsx(EmptyTargetFieldMappingValue, { loading: true, postCallOutputItem: postCallOutputItem })] })));
};
export { FieldMappingCardSummary };
export default FieldMappingCard;
