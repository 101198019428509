import { useEffect, useState } from "react";
// This hook is used to manage the internal state of the CRM table.
// It stores the column filters, global filter query, and sort state,
// column ordering and other details to manage the view of the table.
export const useCrmTableState = () => {
    // This is the column filters state of the table. It is a list of objects
    // containing the column ID and the filter value.
    const [columnFilters, setColumnFilters] = useState([]);
    // This is the global filter query that is used to filter the rows based on
    // the values matching in any of the columns.
    const [globalFilterQuery, setGlobalFilterQuery] = useState("");
    // This is the sort state of the columns in the table. It is a list of
    // objects containing the column ID and the sort direction (ascending or descending).
    const [sortState, setSortState] = useState([]);
    // This is the ordering of the columns in the table. This is different than
    // the sort state which is used to sort the rows based on the values in the columns.
    // It is a list of column IDs in the order they should be displayed.
    const [columnOrder, setColumnOrder] = useState([]);
    // This is the visibility of the columns in the table. It is a map of column IDs
    // to a boolean value indicating whether the column is visible or not.
    const [columnVisibility, setColumnVisibility] = useState({});
    // This is the row selection state of the table. It is a map of row IDs to a boolean
    // value indicating whether the row is selected or not.
    const [rowSelection, setRowSelection] = useState({});
    // This is an internal state for the view of the columns in the table.
    // It is controlled using column view/enrichment feature.
    const [columnViewState, setColumnViewState] = useState([]);
    const updateColumnVisibilityState = (columnIds) => {
        setColumnOrder([...columnIds]);
        const updatedVisibility = {};
        columnIds.forEach((columnId) => {
            updatedVisibility[columnId] = true;
        });
        setColumnVisibility(updatedVisibility);
    };
    useEffect(() => {
        updateColumnVisibilityState(columnViewState);
    }, [columnViewState]);
    return {
        columnFilters,
        setColumnFilters,
        globalFilterQuery,
        setGlobalFilterQuery,
        sortState,
        setSortState,
        columnOrder,
        setColumnOrder,
        columnVisibility,
        setColumnVisibility,
        columnViewState,
        setColumnViewState,
        rowSelection,
        setRowSelection,
    };
};
