import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Popover } from "@headlessui/react";
import { FilterCondition } from "./types";
import { TableOptionButton } from "./table_option_button";
import { FunnelIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { FIELD_TYPE_APPLICABLE_FILTERS } from "./crm_field_type_config";
import Dropdown from "../common/dropdown";
export const TableFilter = (props) => {
    const filterableCols = props.tableCols.filter((col) => col.enableColumnFilter);
    const renderDateInput = (filter, index) => {
        return (_jsx("input", { type: "date", value: filter.value.value &&
                !isNaN(parseInt(filter.value.value))
                ? new Date(parseInt(filter.value.value))
                    .toISOString()
                    .split("T")[0]
                : "", className: "h-8 px-3 w-[560px] font-normal text-black text-base border border-solid border-gray-300 rounded-lg focus:outline-none focus:ring-0", onChange: (e) => {
                const dateValue = new Date(e.target.value);
                const updatedFilters = [...props.columnFilters];
                updatedFilters[index].value.value = dateValue
                    .getTime()
                    .toString();
                props.setColumnFilters(updatedFilters);
            } }));
    };
    const renderTextInput = (filter, index) => {
        return (_jsx("input", { type: "text", value: filter.value.value, className: "h-8 px-3 text-base border border-solid border-gray-300 rounded-lg focus:outline-none focus:ring-0", onChange: (e) => {
                const updatedFilters = [...props.columnFilters];
                updatedFilters[index].value.value =
                    e.target.value;
                props.setColumnFilters(updatedFilters);
            } }));
    };
    const renderFilterInput = (filter, index) => {
        if ([FilterCondition.IS_EMPTY, FilterCondition.IS_NOT_EMPTY].includes(filter.value.filterCondition)) {
            // No input for IS_EMPTY/IS_NOT_EMPTY filter.
            return null;
        }
        return filterableCols.find((col) => col.id === filter.id).type === "date"
            ? renderDateInput(filter, index)
            : renderTextInput(filter, index);
    };
    const renderFilter = (filter, index) => {
        return (_jsxs("div", Object.assign({ className: "flex gap-4 items-center" }, { children: [_jsx("span", Object.assign({ className: "flex w-20 shrink-0 h-8 px-3 items-center text-sm font-medium text-gray-500" }, { children: index > 0 ? "and" : "Where" })), _jsx(Dropdown, { options: filterableCols.map((col) => ({
                        value: col.id,
                        label: col.label,
                    })), defaultSelected: filter.id, onOptionSelect: (selected) => {
                        const updatedFilters = [...props.columnFilters];
                        updatedFilters[index].id = selected;
                        updatedFilters[index].columnName = filterableCols.find((col) => col.id === selected).label;
                        updatedFilters[index].value = {
                            filterCondition: FIELD_TYPE_APPLICABLE_FILTERS[filterableCols.find((col) => col.id === selected).type][0],
                            value: "",
                            columnType: filterableCols.find((col) => col.id === selected)
                                .type,
                        };
                        props.setColumnFilters(updatedFilters);
                    }, buttonClasses: "grow w-max h-8 self-center gap-2 hover:none bg-white border border-solid border-gray-300 rounded-lg" }), _jsx(Dropdown, { options: FIELD_TYPE_APPLICABLE_FILTERS[filterableCols.find((col) => col.id === filter.id).type].map((filterCondition) => ({
                        value: filterCondition,
                        label: filterCondition,
                    })), defaultSelected: filter.value.filterCondition, onOptionSelect: (selected) => {
                        const updatedFilters = [...props.columnFilters];
                        updatedFilters[index].value.filterCondition =
                            selected;
                        props.setColumnFilters(updatedFilters);
                    }, buttonClasses: "grow w-max h-8 self-center gap-2 hover:none bg-white border border-solid border-gray-300 rounded-lg" }), renderFilterInput(filter, index), _jsx("button", Object.assign({ onClick: () => props.setColumnFilters(props.columnFilters.filter((_, i) => i !== index)), className: "text-gray-500 shrink-0" }, { children: _jsx(XMarkIcon, { className: "h-5 w-5" }) }))] }), index));
    };
    return (_jsxs(Popover, Object.assign({ as: "div", className: "relative" }, { children: [_jsx(Popover.Button, Object.assign({ className: "focus:outline-none hover:bg-gray-200 rounded-lg py-1" }, { children: _jsx(TableOptionButton, { icon: _jsx(FunnelIcon, { className: "h-4 w-4" }), text: "Filter" }) })), _jsx(Popover.Overlay, { className: "fixed inset-0 bg-black opacity-10 z-10" }), _jsx(Popover.Panel, Object.assign({ className: "flex grow absolute z-10 lg:w-[800px]" }, { children: _jsxs("div", Object.assign({ className: "flex grow shrink-0 items-start flex-col gap-4 px-6 py-4 bg-white border border-gray-300 rounded-2xl" }, { children: [_jsxs("div", Object.assign({ className: "flex gap-6 items-center justify-between self-stretch" }, { children: [_jsx("span", Object.assign({ className: "text-gray-500 font-bold text-sm" }, { children: `Show me ${props.crmObjectType === "Opportunity" ? "opportunities" : `${props.crmObjectType.toLowerCase()}s`}` })), props.columnFilters.length > 0 && (_jsx("button", Object.assign({ onClick: () => props.setColumnFilters([]), className: "text-sm font-bold text-blue-500" }, { children: "Clear all" })))] })), _jsx("div", Object.assign({ className: "flex flex-col gap-2 grow shrink-0" }, { children: props.columnFilters.map((filter, index) => (_jsx("div", { children: renderFilter(filter, index) }, index))) })), _jsx("div", { children: _jsx("button", Object.assign({ className: "text-sm font-bold text-blue-500", onClick: () => {
                                    props.setColumnFilters([
                                        ...props.columnFilters,
                                        {
                                            id: filterableCols[0].id,
                                            columnName: filterableCols[0].label,
                                            value: {
                                                filterCondition: FIELD_TYPE_APPLICABLE_FILTERS[filterableCols[0].type][0],
                                                value: "",
                                                columnType: filterableCols[0].type,
                                            },
                                        },
                                    ]);
                                } }, { children: "+ Add filter" })) })] })) }))] })));
};
