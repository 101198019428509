import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getActiveTenant, getCrmAccount } from "../../common/endpoints";
import { CenterScreen } from "../../settings/styled_components";
import { SettingsLoader } from "../../common/loaders";
import { AccountPageHeader } from "./account_page_header";
import LiveCardsChatBot from "../../live_cards_chat_bot";
import { useLlmOutputFeedback } from "../../llm_output_feedback/use_llm_output_feedback";
import { useEffect, useMemo, useState } from "react";
import WiserSparklesIcon from "../../wiser_sparkles_icon";
import classNames from "../../class_names";
import useChatMessages from "../../live_cards_chat_bot/use_chat_messages";
import { useStreamSocket, } from "../../common/stream_socket";
import WiserAvatar from "../../live_cards_chat_bot/wiser_avatar";
import { AccountBlocks } from "./account_blocks";
import { useAccountResearchBlocks } from "./use_account_research_blocks";
export const AccountPage = () => {
    const { accountIdParam } = useParams();
    const accountId = Number(accountIdParam);
    // State to manage the collapsible chat
    const [chatCollapsed, setChatCollapsed] = useState(true);
    // Client ID for the chat box
    const clientId = useMemo(() => Math.floor(Math.random() * 1000000), []);
    const { chatMessages, addChatMessage, handleChatMessage } = useChatMessages({
        sender: "Bot",
        messageType: "Message",
        messageContent: "Hey, I'm Wiser! You can ask me anything about this account and I'll answer it for you.",
    });
    const streamSocket = useStreamSocket();
    const [socketReady, setSocketReady] = useState(false);
    useEffect(() => {
        streamSocket.addListener("chat_message", handleChatMessage);
        streamSocket.setOpenCallback(() => {
            setSocketReady(true);
        });
        streamSocket.setCloseCallback(() => {
            setSocketReady(false);
        });
    }, [accountId]);
    const { data: activeTenant } = useQuery({
        queryKey: ["activeTenant"],
        queryFn: getActiveTenant,
    });
    const { data: account, isLoading: accountLoading } = useQuery({
        queryKey: ["crmAccount", accountId],
        queryFn: () => getCrmAccount(accountId),
    });
    const { allLlmOutputFeedback, updateLlmOutputFeedbackMutation, deleteLlmOutputFeedbackMutation, } = useLlmOutputFeedback({ accountId });
    const { accountBlocks: accountBlocksToRender, scrollToBlock: scrollToBlockHandler, blocksRefCallback, } = useAccountResearchBlocks({
        account,
    });
    if (accountLoading || !account || !activeTenant) {
        return (_jsx(CenterScreen, { children: _jsx(SettingsLoader, {}) }));
    }
    return (_jsxs("div", Object.assign({ className: "flex flex-row w-full h-[calc(100vh - 3.125rem)] lg:h-screen bg-neutral-100 overflow-hidden" }, { children: [_jsxs("div", Object.assign({ className: "flex flex-col items-start grow p-1 pb-0" }, { children: [_jsx(AccountPageHeader, { tenant: activeTenant, account: account, accountBlocksToRender: accountBlocksToRender, scrollToBlockHandler: scrollToBlockHandler }), _jsx("div", Object.assign({ className: "flex flex-col w-full py-4 px-8 gap-4 items-start overflow-y-auto h-lvh" }, { children: _jsx(AccountBlocks, { accountBlocksToRender: accountBlocksToRender, blocksRefCallback: blocksRefCallback }) }))] })), _jsx("div", Object.assign({ className: "flex flex-col self-stretch p-1 pl-0" }, { children: chatCollapsed ? (_jsxs("div", Object.assign({ className: classNames("flex flex-col flex-auto justify-between items-center", "self-stretch py-4 hover:cursor-pointer", "w-12", "rounded-lg border border-purple-600 bg-gradient-to-b from-white via-blue-100 to-pink-100"), onClick: () => setChatCollapsed(false) }, { children: [_jsx(WiserSparklesIcon, {}), _jsx(WiserAvatar, {})] }))) : (_jsx("div", Object.assign({ className: "flex flex-col h-full w-[428px] bg-white rounded-lg border border-purple-600" }, { children: _jsx(LiveCardsChatBot, { allCards: [], clientId: clientId, chatMessages: chatMessages, addChatMessage: addChatMessage, setCollapse: () => setChatCollapsed((prev) => !prev), socketReady: socketReady, allLlmOutputFeedback: allLlmOutputFeedback !== null && allLlmOutputFeedback !== void 0 ? allLlmOutputFeedback : [], updateLlmOutputFeedbackMutation: updateLlmOutputFeedbackMutation, deleteLlmOutputFeedbackMutation: deleteLlmOutputFeedbackMutation }) }))) }))] })));
};
