export var FilterCondition;
(function (FilterCondition) {
    FilterCondition["EQ"] = "=";
    FilterCondition["NEQ"] = "!=";
    FilterCondition["LT"] = "<";
    FilterCondition["LTE"] = "<=";
    FilterCondition["GT"] = ">";
    FilterCondition["GTE"] = ">=";
    FilterCondition["CONTAINS"] = "contains";
    FilterCondition["NOT_CONTAINS"] = "does not contain";
    FilterCondition["IS_EMPTY"] = "is empty";
    FilterCondition["IS_NOT_EMPTY"] = "is not empty";
})(FilterCondition || (FilterCondition = {}));
