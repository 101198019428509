var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { useCrmTableState } from "./use_crm_table_state";
import { createColumnHelper, flexRender, getCoreRowModel, getFilteredRowModel, getSortedRowModel, useReactTable, } from "@tanstack/react-table";
import { wiserCrmTableFilterFn } from "./crm_table_filter_fns";
import { CrmNewRow } from "./crm_new_row";
import { TableOptions } from "./table_options";
import { getColumnSize, getCommonPinningStyles, } from "./crm_table_column_styles";
import { ColumnSortButton } from "./column_sort_button";
import { getActiveTenant, getCrmFields, getStoredCrmViews, } from "../common/endpoints";
import CrmFieldValue from "./crm_field_value";
import { useQuery, useQueryClient } from "react-query";
import { getConnectedCrmLogo } from "../common/crm_logo";
import { Tooltip } from "react-tooltip";
export const CrmTable = (crmTableProps) => {
    const [crmTableColumnDefs, setCrmTableColumnDefs] = useState([]);
    const [currentCrmView, setCurrentCrmView] = useState(null);
    const { columnFilters, setColumnFilters, globalFilterQuery, setGlobalFilterQuery, sortState, setSortState, columnOrder, setColumnOrder, columnVisibility, setColumnVisibility, columnViewState, setColumnViewState, rowSelection, setRowSelection, } = useCrmTableState();
    const queryClient = useQueryClient();
    const { data: activeTenant } = useQuery({
        queryKey: ["activeTenant"],
        queryFn: getActiveTenant,
    });
    const { data: crmFields, isLoading: crmFieldsLoading, isError: crmFieldsError, } = useQuery({
        queryKey: ["crmFieldsQueryKey"],
        queryFn: getCrmFields,
    });
    const fetchCrmFields = () => {
        // Force fetch all CRM fields
        queryClient.invalidateQueries(["crmFieldsQueryKey"]);
    };
    const columnHelper = createColumnHelper();
    useEffect(() => {
        if (crmFieldsLoading || crmFieldsError) {
            return;
        }
        setCrmTableColumnDefs([
            ...crmTableProps.columns,
            ...(crmFields !== null && crmFields !== void 0 ? crmFields : [])
                .filter((field) => field.applicable_objects.includes(crmTableProps.tableData.crmObject))
                .sort((a, _) => (a.nullable ? 1 : -1))
                .map((field) => columnHelper.accessor((row) => {
                var _a, _b;
                return (_b = (_a = row.crm_data.find((crmFieldValue) => crmFieldValue.crm_field.id === field.id)) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : "";
            }, {
                id: field.name,
                size: getColumnSize(field),
                header: field.name,
                filterFn: "wiserCrmTableFilterFn",
                sortingFn: field.content_type_definition.type === "number" ||
                    field.content_type_definition.type === "date"
                    ? "alphanumeric"
                    : "auto",
                sortUndefined: false,
                meta: {
                    fieldId: field.id,
                    fieldSchema: field.content_type_definition,
                    type: field.content_type_definition.type,
                    columnInfo: {
                        columnType: field.column_type,
                        sourcePlaybookItemId: field.source_playbook_item_id,
                    },
                    required: !field.nullable,
                },
                cell: (props) => {
                    var _a;
                    return (_jsx(CrmFieldValue, { crmObjectId: parseInt(props.row.id), crmFieldId: field.id, crmFieldSchema: field.content_type_definition, crmFieldValue: props.getValue(), onNewValueChange: (newValue) => __awaiter(void 0, void 0, void 0, function* () {
                            yield crmTableProps.onCrmFieldValueChange(parseInt(props.row.id), field, newValue);
                        }), crmFieldValueStatus: field.column_type === "ENRICHMENT"
                            ? (_a = props.row.original.crm_data.find((crmFieldValue) => crmFieldValue.crm_field.id === field.id)) === null || _a === void 0 ? void 0 : _a.status
                            : undefined }));
                },
            })),
        ]);
    }, [crmTableProps.columns, crmFields, crmFieldsLoading, crmFieldsError]);
    const tableCols = useMemo(() => {
        return (crmTableColumnDefs
            .filter((col) => col.id && col.header)
            // Adding type assertion here because we can be sure that col.id and col.header
            // are not undefined because of the above filter.
            .map((col) => {
            var _a, _b, _c, _d, _e, _f, _g;
            return ({
                id: col.id,
                // Row-select is a special column that is used for row selection.
                // We want to display "Select" in the column header because the actual header is a
                // checkbox for this column.
                label: col.id === "row-select" ? "Select" : col.header.toString(),
                type: (_c = (_b = (_a = col.meta) === null || _a === void 0 ? void 0 : _a.fieldSchema) === null || _b === void 0 ? void 0 : _b.type) !== null && _c !== void 0 ? _c : "text",
                enableColumnFilter: !!col.filterFn,
                enableSorting: !!col.sortingFn,
                columnInfo: {
                    columnType: (_e = (_d = col.meta) === null || _d === void 0 ? void 0 : _d.columnInfo.columnType) !== null && _e !== void 0 ? _e : "CUSTOM",
                    sourcePlaybookItemId: (_f = col.meta) === null || _f === void 0 ? void 0 : _f.columnInfo.sourcePlaybookItemId,
                },
                defaultVisible: col.id === "row-select" || !!((_g = col.meta) === null || _g === void 0 ? void 0 : _g.required),
            });
        }));
    }, [crmTableColumnDefs]);
    useQuery({
        queryKey: ["crmStoredViewsQueryKey", crmTableProps.tableData.crmObject],
        queryFn: () => getStoredCrmViews(crmTableProps.tableData.crmObject),
        enabled: tableCols.length > 0,
        onSuccess: (crmStoredViews) => {
            if (!crmStoredViews) {
                return;
            }
            switchCurrentCrmView(crmStoredViews.length > 0 ? crmStoredViews[0] : null);
        },
    });
    const switchCurrentCrmView = (newView) => {
        setCurrentCrmView(newView);
        setColumnViewState(newView
            ? newView.columns
            : tableCols.filter((col) => col.defaultVisible).map((col) => col.id));
    };
    const table = useReactTable({
        columns: crmTableColumnDefs,
        data: crmTableProps.tableData.rows,
        state: {
            globalFilter: globalFilterQuery,
            columnFilters: columnFilters,
            sorting: sortState,
            columnOrder: columnOrder,
            columnVisibility: tableCols.reduce((acc, col) => {
                acc[col.id] =
                    col.id in columnVisibility ? columnVisibility[col.id] : false;
                return acc;
            }, {}),
            rowSelection: rowSelection,
            columnPinning: {
                left: ["row-select"],
            },
        },
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getRowId: (row) => `${row.id}`,
        enableGlobalFilter: true,
        globalFilterFn: "includesString",
        onGlobalFilterChange: setGlobalFilterQuery,
        enableColumnFilters: true,
        filterFns: {
            wiserCrmTableFilterFn: wiserCrmTableFilterFn,
        },
        onColumnFiltersChange: setColumnFilters,
        enableSorting: true,
        onSortingChange: setSortState,
        onColumnOrderChange: setColumnOrder,
        onColumnVisibilityChange: setColumnVisibility,
        enableRowSelection: true,
        onRowSelectionChange: setRowSelection,
    });
    const isFieldSyncedToExternalCrm = (fieldId) => {
        if (!fieldId)
            return false;
        // Default fields like Name, Email etc. are always synced back to external CRM.
        if (fieldId < 0)
            return true;
        if (!activeTenant || !crmFields)
            return false;
        const crmField = crmFields.find((field) => field.id === fieldId);
        if (!crmField)
            return false;
        return !!activeTenant.connected_crm && !!crmField.external_id;
    };
    return (_jsxs("div", Object.assign({ className: "flex flex-col gap-3" }, { children: [_jsx(TableOptions, { crmObjectType: crmTableProps.tableData.crmObject, tableCols: tableCols, showFilter: true, showSort: true, showColumnOptions: true, showSearch: true, tableGlobalFilterQuery: globalFilterQuery, setTableGlobalFilterQuery: setGlobalFilterQuery, columnFilters: columnFilters, setColumnFilters: setColumnFilters, sortState: sortState, setSortState: setSortState, columnViewState: columnViewState, setColumnViewState: setColumnViewState, refetchCrmFields: fetchCrmFields, refetchCrmData: crmTableProps.refetchCrmData, currentCrmView: currentCrmView !== null && currentCrmView !== void 0 ? currentCrmView : undefined, setCurrentCrmView: switchCurrentCrmView }), _jsx("div", Object.assign({ className: "overflow-auto h-[620px] m-4 border border-gray-300 rounded-lg max-w-fit" }, { children: _jsxs("table", Object.assign({ className: "bg-white" }, { children: [_jsx("thead", Object.assign({ className: "sticky -top-px z-[2] ring-1 ring-gray-300" }, { children: table.getHeaderGroups().map((headerGroup) => (_jsx("tr", { children: headerGroup.headers.map((header) => {
                                    var _a;
                                    return (_jsx("th", Object.assign({ className: "font-bold text-sm text-gray-500 bg-gray-100 p-2 border-r border-gray-300 h-full", style: Object.assign({}, getCommonPinningStyles(header.column)) }, { children: _jsxs("div", Object.assign({ className: "flex flex-row items-center justify-between self-stretch px-2 gap-4" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center gap-2 self-stretch" }, { children: [header.isPlaceholder
                                                            ? null
                                                            : flexRender(header.column.columnDef.header, header.getContext()), activeTenant &&
                                                            isFieldSyncedToExternalCrm((_a = header.column.columnDef.meta) === null || _a === void 0 ? void 0 : _a.fieldId) && (_jsxs("div", Object.assign({ className: "flex h-6 w-6 shrink-0 items-center justify-center", "data-tooltip-id": "column-update-tooltip", "data-tooltip-content": `Any changes to this field will be instantly synced to ${activeTenant.connected_crm}` }, { children: [_jsx("img", { src: getConnectedCrmLogo(activeTenant.connected_crm), className: "w-4 h-4 shrink-0" }), _jsx(Tooltip, { id: "column-update-tooltip", noArrow: true, className: "wiser-frame-tooltip" })] })))] })), header.column.getCanSort() && (_jsx(ColumnSortButton, { columnId: header.column.columnDef.id, sortState: sortState, setSortState: setSortState }))] })) }), header.id));
                                }) }, headerGroup.id))) })), _jsx("tbody", { children: table.getRowModel().rows.map((row) => (_jsx("tr", { children: row.getVisibleCells().map((cell) => (_jsx("td", Object.assign({ style: Object.assign({ borderRight: "1px solid #D1D5DB", borderTop: "1px solid #E5E7EB", height: "100%", backgroundColor: "white" }, getCommonPinningStyles(cell.column)) }, { children: flexRender(cell.column.columnDef.cell, cell.getContext()) }), cell.id))) }, row.id))) }), table
                            .getAllFlatColumns()
                            .filter((col) => { var _a; return !!((_a = col.columnDef.meta) === null || _a === void 0 ? void 0 : _a.required); })
                            .every((col) => col.getIsVisible()) && (_jsx("tfoot", Object.assign({ className: "sticky z-[2] bottom-0 ring-1 ring-gray-300" }, { children: _jsx(CrmNewRow, { crmTableInfo: {
                                    crmObject: crmTableProps.tableData.crmObject,
                                    columns: table.getVisibleFlatColumns(),
                                    tableState: table.getState(),
                                    columnData: crmTableProps.columnData,
                                }, onNewRowCreate: crmTableProps.onNewRowCreate }) })))] })) }))] })));
};
