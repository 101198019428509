import { jsx as _jsx } from "react/jsx-runtime";
import { DocumentDuplicateIcon, HandThumbUpIcon, HandThumbDownIcon, ArrowPathIcon, } from "@heroicons/react/24/outline";
import { CheckCircleIcon as SolidCheckCircleIcon, HandThumbUpIcon as SolidHandThumbUpIcon, HandThumbDownIcon as SolidHandThumbDownIcon, } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import tw from "tailwind-styled-components";
import WaitingSpinner from "../waiting_spinner";
const TrackerNotesActionButtonContainer = tw.div `
    flex
    w-8
    h-8
    items-center
    justify-center
    text-wds-gray-5
`;
export const TrakcerNotesUpvoteButton = ({ note }) => {
    // TODO: Implement Upvote functionality
    const [upvoted, setUpvoted] = useState(false);
    useEffect(() => {
        setUpvoted(false);
    }, [note.playbook_item_id]);
    return (_jsx("button", Object.assign({ onClick: () => setUpvoted(!upvoted) }, { children: _jsx(TrackerNotesActionButtonContainer, { children: upvoted ? (_jsx(SolidHandThumbUpIcon, { className: "w-5 h-5 shrink-0" })) : (_jsx(HandThumbUpIcon, { className: "w-5 h-5 shrink-0" })) }) })));
};
export const TrackerNotesDownvoteButton = ({ note }) => {
    // TODO: Implement downvote functionality
    const [downvoted, setDownvoted] = useState(false);
    useEffect(() => {
        setDownvoted(false);
    }, [note.playbook_item_id]);
    return (_jsx("button", Object.assign({ onClick: () => setDownvoted(!downvoted) }, { children: _jsx(TrackerNotesActionButtonContainer, { children: downvoted ? (_jsx(SolidHandThumbDownIcon, { className: "w-5 h-5 shrink-0" })) : (_jsx(HandThumbDownIcon, { className: "w-5 h-5 shrink-0" })) }) })));
};
export const TrackerNotesCopyButton = ({ content }) => {
    const [copyClicked, setCopyClicked] = useState(false);
    useEffect(() => {
        if (copyClicked) {
            setTimeout(() => {
                setCopyClicked(false);
            }, 500);
        }
    }, [copyClicked]);
    return (_jsx("button", Object.assign({ onClick: () => {
            navigator.clipboard.writeText(content);
            setCopyClicked(true);
        } }, { children: _jsx(TrackerNotesActionButtonContainer, { children: copyClicked ? (_jsx(SolidCheckCircleIcon, { className: "w-5 h-5 shrink-0" })) : (_jsx(DocumentDuplicateIcon, { className: "w-5 h-5 shrink-0" })) }) })));
};
export const TrackerNotesRegenerateButton = ({ note, onRegenerateNotes, noteRegenerationInProgress, postCall, }) => {
    if (postCall) {
        return null;
    }
    return (_jsx("button", Object.assign({ onClick: () => {
            onRegenerateNotes(note.playbook_item_id);
        }, disabled: noteRegenerationInProgress }, { children: _jsx(TrackerNotesActionButtonContainer, { children: noteRegenerationInProgress ? (_jsx(WaitingSpinner, { text: "", classes: "ml-4" })) : (_jsx(ArrowPathIcon, { className: "w-5 h-5 shrink-0" })) }) })));
};
