import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef, useEffect, memo, useMemo } from "react";
import { ArrowsPointingOutIcon, ForwardIcon, PauseIcon, PlayIcon, VideoCameraIcon, } from "@heroicons/react/24/outline";
import VolumeSlider from "./volume_slider";
import { PlaybackTimelineControls } from "./video_playback_timeline";
import classNames from "./class_names";
import { InformationCircleIcon } from "@heroicons/react/24/solid";
const VideoPlayback = ({ playbackTimeline, currentVideoTime, setCurrentVideoTime, transcriptLengthMsec, src, callSourceSupported, }) => {
    const videoRef = useRef(null);
    const [videoLengthMsec, setVideoLengthMsec] = React.useState(transcriptLengthMsec);
    const [volume, setVolume] = React.useState(100);
    useEffect(() => {
        if (!videoRef.current)
            return;
        videoRef.current.volume = volume / 100;
    }, [volume]);
    const [isPlaying, setIsPlaying] = React.useState(() => {
        if (!videoRef.current)
            return false;
        return !videoRef.current.paused;
    });
    useEffect(() => {
        if (!videoRef.current)
            return;
        if (Math.abs(videoRef.current.currentTime * 1000 - currentVideoTime) < 1000)
            return;
        videoRef.current.currentTime = currentVideoTime / 1000;
    }, [currentVideoTime]);
    const VideoComponent = useMemo(() => {
        // Using memo here to avoid re-creating the video element on every render.
        // However, in performance tests, it seems to make little difference.
        if (!src)
            return (_jsx("div", Object.assign({ className: "flex items-center justify-center w-full h-[256px] lg:h-[400px] bg-wds-gray-3 rounded-xl" }, { children: _jsx(VideoCameraIcon, { className: "h-24 w-24 text-wds-gray-5 fill-white stroke-0" }) })));
        return (_jsx("video", { ref: videoRef, className: "video-js vjs-big-play-centered rounded-xl", preload: "auto", src: src, style: { width: "100%", height: "auto" }, onTimeUpdate: (e) => {
                setCurrentVideoTime(e.currentTarget.currentTime * 1000);
            }, onSeeked: (e) => {
                setCurrentVideoTime(e.currentTarget.currentTime * 1000);
            }, onLoadedMetadata: (e) => {
                const duration = e.currentTarget.duration;
                if (duration === 0)
                    return;
                if (Number.isNaN(duration))
                    return;
                setVideoLengthMsec(duration * 1000);
            }, onEnded: () => {
                setIsPlaying(false);
            }, onPlay: () => {
                setIsPlaying(true);
            }, onPause: () => {
                setIsPlaying(false);
            } }));
    }, [src]);
    return (_jsxs("div", Object.assign({ className: "flex flex-col w-full items-center" }, { children: [VideoComponent, _jsxs("div", Object.assign({ className: "flex items-center justify-between py-4 w-full" }, { children: [_jsx(VolumeSlider, { volume: volume, setVolume: setVolume, disabled: !src }), _jsxs("div", Object.assign({ className: "flex justify-center space-x-4" }, { children: [_jsxs("button", Object.assign({ className: classNames("p-2 rounded-full focus:outline-none", src ? "hover:bg-wds-blue-2" : ""), onClick: () => {
                                    setCurrentVideoTime(currentVideoTime - 15000);
                                }, disabled: !src }, { children: [_jsx("span", Object.assign({ className: "sr-only" }, { children: "Rewind 15s" })), _jsx(ForwardIcon, { className: "stroke-wds-gray-5 text-black h-6 w-6 scale-x-[-1]" })] })), _jsxs("button", Object.assign({ className: classNames("p-2 rounded-full focus:outline-none items-center", src ? "bg-wds-blue-4 hover:bg-wds-blue-3" : "bg-wds-gray-3"), onClick: () => { var _a, _b; return isPlaying ? (_a = videoRef.current) === null || _a === void 0 ? void 0 : _a.pause() : (_b = videoRef.current) === null || _b === void 0 ? void 0 : _b.play(); }, disabled: !src }, { children: [_jsx("span", Object.assign({ className: "sr-only" }, { children: "Play/Pause" })), isPlaying ? (_jsx(PauseIcon, { className: "stroke-white h-6 w-6" })) : (_jsx(PlayIcon, { className: "stroke-white fill-white h-6 w-6" }))] })), _jsxs("button", Object.assign({ className: classNames("p-2 rounded-full focus:outline-none", src ? "hover:bg-wds-blue-2" : ""), onClick: () => {
                                    setCurrentVideoTime(currentVideoTime + 15000);
                                }, disabled: !src }, { children: [_jsx("span", Object.assign({ className: "sr-only" }, { children: "Forward" })), _jsx(ForwardIcon, { className: "stroke-wds-gray-5 text-black h-6 w-6" })] }))] })), _jsx("div", Object.assign({ className: "flex" }, { children: _jsx("button", Object.assign({ className: "p-2 rounded-full hover:bg-wds-blue-2 focus:outline-none", onClick: () => {
                                var _a;
                                (_a = videoRef.current) === null || _a === void 0 ? void 0 : _a.requestFullscreen();
                            }, disabled: !src }, { children: _jsx(ArrowsPointingOutIcon, { className: "stroke-wds-gray-5 text-black h-6 w-6" }) })) }))] })), src ? (_jsx(PlaybackTimelineControls, { timeline: playbackTimeline, endMsec: videoLengthMsec, currentMsec: currentVideoTime, setCurrentMsec: setCurrentVideoTime })) : (_jsx("div", Object.assign({ className: "w-full h-24 flex items-center justify-center border border-wds-blue-3 p-4 bg-white rounded-lg border-l-4 flex-inline" }, { children: _jsxs("div", Object.assign({ className: "flex flex-row items-start gap-2" }, { children: [_jsx(InformationCircleIcon, { className: "h-6 w-6 text-white fill-wds-blue-3 stroke-white" }), callSourceSupported
                            ? "The call recording is still being processed. Check back again in a few minutes."
                            : "We don't yet collect video recordings for this call type."] })) })))] })));
};
export default memo(VideoPlayback);
