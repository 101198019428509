import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
 * This component is used to display the free time between calls in the call list.
 * It takes the free time in minutes as a prop and calculates the hours and minutes.
 * Then it displays the free time, for example:
 * If minutesFree is 125, it will display "Free for 2 hours & 5 minutes"
 * If minutesFree is 45, it will display "Free for 45 minutes"
 * If minutesFree is 60, it will display "Free for 1 hour"
 * Now, if minutesFree is a fraction, it will be rounded to the nearest minute.
 */
const FreeTimeDisplay = ({ minutesFree }) => {
    const roundedMinutesFree = Math.round(minutesFree);
    const hours = Math.floor(roundedMinutesFree / 60);
    const minutes = roundedMinutesFree % 60;
    const hoursDisplay = hours > 1 ? "hours" : "hour";
    const minutesDisplay = minutes > 1 ? "minutes" : "minute";
    let displayMessage = "";
    if (hours > 0 && minutes > 0) {
        displayMessage = `Free for ${hours} ${hoursDisplay} & ${minutes} ${minutesDisplay}`;
    }
    else if (hours > 0) {
        displayMessage = `Free for ${hours} ${hoursDisplay}`;
    }
    else {
        displayMessage = `Free for ${minutes} ${minutesDisplay}`;
    }
    return (_jsxs("div", Object.assign({ className: "w-full flex items-center" }, { children: [_jsx("div", { className: "flex-1 h-[1px] bg-wds-gray-3 mr-4" }), _jsx("div", Object.assign({ className: "text-wds-gray-6 text-[12px] font-normal font-lato tracking-[-.12px] text-center whitespace-nowrap" }, { children: displayMessage })), _jsx("div", { className: "flex-1 h-[1px] bg-wds-gray-3 ml-4" })] })));
};
export default FreeTimeDisplay;
