// This file contains the filter functions for Wiser CRM tables.
import { FilterCondition } from "./types";
// This is a custom filter function that is used for filtering columns in the CRM tables
// this handles different types of fields and filter conditions for each.
export const wiserCrmTableFilterFn = (row, columnId, filterValue) => {
    let columnValue = row.getValue(columnId);
    if (filterValue.filterCondition === FilterCondition.IS_NOT_EMPTY) {
        return !!columnValue && columnValue.length > 0;
    }
    if (filterValue.filterCondition === FilterCondition.IS_EMPTY) {
        return !columnValue || columnValue.length === 0;
    }
    if (!columnValue) {
        return false;
    }
    let filterQuery = filterValue.value;
    if (filterValue.columnType === "number") {
        columnValue = parseFloat(columnValue);
        filterQuery = parseFloat(filterQuery);
        if (isNaN(columnValue) || isNaN(filterQuery)) {
            return false;
        }
    }
    else if (filterValue.columnType === "date") {
        // Convert the date string to a Date object
        columnValue = new Date(parseInt(columnValue));
        filterQuery = new Date(parseInt(filterQuery));
        if (isNaN(columnValue.getTime()) || isNaN(filterQuery.getTime())) {
            return false;
        }
        // Set the time to 00:00:00 to compare only the date
        columnValue.setHours(0, 0, 0, 0);
        filterQuery.setHours(0, 0, 0, 0);
        // Convert the date object back to a number to compare
        columnValue = columnValue.getTime();
        filterQuery = filterQuery.getTime();
    }
    switch (filterValue.filterCondition) {
        case FilterCondition.EQ:
            return columnValue === filterQuery;
        case FilterCondition.NEQ:
            return columnValue !== filterQuery;
        case FilterCondition.LT:
            return columnValue < filterQuery;
        case FilterCondition.LTE:
            return columnValue <= filterQuery;
        case FilterCondition.GT:
            return columnValue > filterQuery;
        case FilterCondition.GTE:
            return columnValue >= filterQuery;
        case FilterCondition.CONTAINS:
            return columnValue.includes(filterQuery);
        case FilterCondition.NOT_CONTAINS:
            return !columnValue.includes(filterQuery);
    }
};
wiserCrmTableFilterFn.autoRemove = (filterValue) => {
    return !filterValue || !filterValue.filterCondition || !filterValue.value;
};
