var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Popover } from "@headlessui/react";
import { TableCellsIcon } from "@heroicons/react/24/outline";
import { useEffect, useMemo, useState } from "react";
import * as endpoints from "../common/endpoints";
import { SearchableDropdown } from "../common/searchable_dropdown";
import classNames from "../class_names";
const CrmViewSwitcher = ({ currentCrmView, storedViews, updateCrmTableView, }) => {
    return (_jsx(SearchableDropdown, { options: [
            {
                value: "default",
                label: "Default view",
            },
            ...storedViews.map((view) => ({
                value: `${view.id}`,
                label: view.name,
            })),
        ], onSelect: (option) => {
            if (option) {
                updateCrmTableView(parseInt(option.value));
            }
        }, selectedOption: currentCrmView
            ? {
                value: `${currentCrmView.id}`,
                label: currentCrmView.name,
            }
            : {
                value: "default",
                label: "Default view",
            }, placeholder: "Select a view", buttonClasses: "grow hover:none bg-white" }));
};
export const TableViewButton = (props) => {
    const [storedViews, setStoredViews] = useState([]);
    const [newViewName, setNewViewName] = useState("");
    const isViewUnsaved = useMemo(() => {
        if (!props.currentCrmView) {
            return (JSON.stringify(props.tableCols
                .filter((col) => col.defaultVisible)
                .map((col) => col.id)) !== JSON.stringify(props.columnViewState));
        }
        return (JSON.stringify(props.columnViewState) !==
            JSON.stringify(props.currentCrmView.columns));
    }, [props.tableCols, props.columnViewState, props.currentCrmView]);
    const fetchStoredViews = () => __awaiter(void 0, void 0, void 0, function* () {
        const storedViews = yield endpoints.getStoredCrmViews(props.crmObjectType);
        setStoredViews(storedViews);
    });
    useEffect(() => {
        fetchStoredViews();
    }, [props.crmObjectType]);
    const updateCrmTableView = (viewId) => {
        var _a;
        props.setCurrentCrmView(
        // null will reset the view to the default.
        (_a = storedViews.find((view) => view.id === viewId)) !== null && _a !== void 0 ? _a : null);
    };
    const saveNewView = () => __awaiter(void 0, void 0, void 0, function* () {
        const newView = yield endpoints.updateOrCreateCrmObjectView(props.crmObjectType, newViewName, props.columnViewState);
        yield fetchStoredViews();
        props.setCurrentCrmView(newView);
    });
    return (_jsx("div", Object.assign({ className: "" }, { children: _jsxs(Popover, Object.assign({ as: "div", className: "relative" }, { children: [_jsxs(Popover.Button, Object.assign({ className: classNames("flex items-center gap-2 py-1 px-2 h-8 rounded-lg", isViewUnsaved
                        ? "text-red-700 bg-red-100"
                        : props.currentCrmView
                            ? "bg-green-100 text-green-700"
                            : "bg-wds-blue-1 text-wds-blue-4", "focus:outline-none focus:ring-0") }, { children: [_jsx(TableCellsIcon, { className: "h-4 w-4" }), _jsx("span", Object.assign({ className: "text-sm font-medium" }, { children: isViewUnsaved
                                ? "Unsaved view"
                                : props.currentCrmView
                                    ? props.currentCrmView.name
                                    : "Default view" }))] })), _jsx(Popover.Overlay, { className: "fixed inset-0 bg-black opacity-10 z-10" }), _jsx(Popover.Panel, Object.assign({ className: "flex flex-col gap-2 absolute z-10 w-[400px]" }, { children: _jsx("div", Object.assign({ className: "flex grow shrink-0 items-start flex-col gap-4 px-6 py-4 bg-white border border-gray-300 rounded-2xl" }, { children: isViewUnsaved ? (_jsxs("div", Object.assign({ className: "flex flex-col gap-4 w-full" }, { children: [_jsxs("div", Object.assign({ className: "flex flex-col gap-2 w-full" }, { children: [_jsx("span", Object.assign({ className: "text-xs text-gray-500 font-bold" }, { children: "Name this view" })), _jsxs("div", Object.assign({ className: "flex w-full justify-between items-center gap-4" }, { children: [_jsx("div", Object.assign({ className: "self-stretch w-full" }, { children: _jsx("input", { type: "text", value: newViewName, className: "w-full h-8 px-3 text-sm border border-solid border-gray-300 rounded-lg focus:outline-none focus:ring-0", onChange: (e) => {
                                                            setNewViewName(e.target.value);
                                                        }, placeholder: "Save as" }) })), _jsx("button", Object.assign({ className: classNames("bg-wds-blue-1 h-8 px-4 rounded-lg", !newViewName
                                                        ? "opacity-50 hover:cursor-not-allowed"
                                                        : "hover:bg-wds-blue-2 hover:cursor-pointer"), onClick: saveNewView, disabled: !newViewName }, { children: "Save" }))] }))] })), _jsxs("div", Object.assign({ className: "flex flex-col gap-2 w-full" }, { children: [_jsx("span", Object.assign({ className: "text-xs text-gray-500 font-bold" }, { children: "Switch to a different view" })), _jsx(CrmViewSwitcher, { currentCrmView: props.currentCrmView, storedViews: storedViews, updateCrmTableView: updateCrmTableView })] }))] }))) : (_jsx("div", Object.assign({ className: "w-full" }, { children: _jsxs("div", Object.assign({ className: "flex flex-col gap-2 w-full" }, { children: [_jsx("span", Object.assign({ className: "text-xs text-gray-500 font-bold" }, { children: "Switch to a different view" })), _jsx(CrmViewSwitcher, { currentCrmView: props.currentCrmView, storedViews: storedViews, updateCrmTableView: updateCrmTableView })] })) }))) })) }))] })) })));
};
