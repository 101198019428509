import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Popover } from "@headlessui/react";
import { TableOptionButton } from "./table_option_button";
import { ArrowsUpDownIcon, XMarkIcon } from "@heroicons/react/24/outline";
import Dropdown from "../common/dropdown";
import { DragDropContext, Draggable, Droppable, } from "react-beautiful-dnd";
import DragIndicatorIcon from "../icons/drag_indicator_icon";
const getSortRuleLabel = (sortRule, type) => {
    if (!type) {
        return sortRule === "asc" ? "Ascending" : "Descending";
    }
    switch (type) {
        case "text":
            return sortRule === "asc" ? "A -> Z" : "Z -> A";
        case "number":
            return sortRule === "asc" ? "1 -> 9" : "9 -> 1";
        case "boolean":
            return sortRule === "asc" ? "A -> Z" : "Z -> A";
        case "single_select":
        case "multi_select":
            return sortRule === "asc" ? "A -> Z" : "Z -> A";
        case "date":
            return sortRule === "asc" ? "Earliest -> Latest" : "Latest -> Earliest";
        default:
            return sortRule === "asc" ? "A -> Z" : "Z -> A";
    }
};
export const TableSort = (props) => {
    const sortableCols = props.tableCols.filter((col) => col.enableSorting);
    const renderSortRule = (sortRule, index) => {
        var _a, _b;
        return (_jsxs("div", Object.assign({ className: "flex gap-2 w-max" }, { children: [_jsx(Dropdown, { options: sortableCols.map((col) => ({
                        value: col.id,
                        label: col.label,
                    })), defaultSelected: sortRule.id, onOptionSelect: (selected) => {
                        const updatedSortRules = [...props.sortState];
                        updatedSortRules[index].id = selected;
                        updatedSortRules[index].desc = false;
                        props.setSortState(updatedSortRules);
                    }, buttonClasses: "grow min-w-[280px] w-fit hover:none bg-white border border-solid border-gray-300 rounded-lg" }), _jsx(Dropdown, { options: [
                        {
                            value: "asc",
                            label: getSortRuleLabel("asc", (_a = sortableCols.find((col) => col.id === sortRule.id)) === null || _a === void 0 ? void 0 : _a.type),
                        },
                        {
                            value: "desc",
                            label: getSortRuleLabel("desc", (_b = sortableCols.find((col) => col.id === sortRule.id)) === null || _b === void 0 ? void 0 : _b.type),
                        },
                    ], defaultSelected: sortRule.desc ? "desc" : "asc", onOptionSelect: (selected) => {
                        const updatedSortState = [...props.sortState];
                        updatedSortState[index].desc = selected === "desc";
                        props.setSortState(updatedSortState);
                    }, buttonClasses: "grow w-[120px] hover:none bg-white border border-solid border-gray-300 rounded-lg" }), _jsx("button", Object.assign({ onClick: () => props.setSortState(props.sortState.filter((_, i) => i !== index)), className: "text-gray-500" }, { children: _jsx(XMarkIcon, { className: "h-4 w-4" }) }))] })));
    };
    const onSortRulesDragEnd = (result) => {
        if (!result.destination)
            return;
        const reorderedRules = Array.from(props.sortState);
        const [reorderedRule] = reorderedRules.splice(result.source.index, 1);
        reorderedRules.splice(result.destination.index, 0, reorderedRule);
        props.setSortState(reorderedRules);
    };
    return (_jsxs(Popover, Object.assign({ as: "div", className: "relative" }, { children: [_jsx(Popover.Button, Object.assign({ className: "focus:outline-none hover:bg-gray-200 rounded-lg py-1" }, { children: _jsx(TableOptionButton, { icon: _jsx(ArrowsUpDownIcon, { className: "h-4 w-4" }), text: "Sort" }) })), _jsx(Popover.Overlay, { className: "fixed inset-0 bg-black opacity-10 z-10" }), _jsx(Popover.Panel, Object.assign({ className: "flex grow absolute z-10 w-[440px]" }, { children: _jsxs("div", Object.assign({ className: "flex grow shrink-0 items-start flex-col gap-4 px-6 py-4 bg-white border border-gray-300 rounded-2xl" }, { children: [_jsxs("div", Object.assign({ className: "flex gap-6 items-center justify-between self-stretch" }, { children: [_jsx("span", Object.assign({ className: "text-gray-500 font-bold text-sm" }, { children: `Sort ${props.crmObjectType === "Opportunity" ? "opportunities" : `${props.crmObjectType.toLowerCase()}s`} by` })), props.sortState.length > 0 && (_jsx("button", Object.assign({ onClick: () => props.setSortState([]), className: "text-sm font-bold text-blue-500" }, { children: "Clear all" })))] })), _jsx(DragDropContext, Object.assign({ onDragEnd: onSortRulesDragEnd }, { children: _jsx(Droppable, Object.assign({ droppableId: "sortRules" }, { children: (dndProps) => {
                                    return (_jsxs("div", Object.assign({ className: "flex flex-col gap-2" }, dndProps.droppableProps, { ref: dndProps.innerRef }, { children: [props.sortState.map((sortRule, index) => (_jsx(Draggable, Object.assign({ index: index, draggableId: `${sortRule.id}-${index}` }, { children: (dndProps) => {
                                                    return (_jsxs("div", Object.assign({}, dndProps.draggableProps, dndProps.dragHandleProps, { ref: dndProps.innerRef, className: "flex gap-2 w-max items-center" }, { children: [renderSortRule(sortRule, index), _jsx(DragIndicatorIcon, {})] })));
                                                } }), `${sortRule.id}-${index}`))), dndProps.placeholder] })));
                                } })) })), _jsx("div", { children: _jsx("button", Object.assign({ className: "text-sm font-bold text-blue-500", onClick: () => {
                                    props.setSortState([
                                        ...props.sortState,
                                        {
                                            id: sortableCols[0].id,
                                            desc: false,
                                        },
                                    ]);
                                } }, { children: "+ Add sorting rule" })) })] })) }))] })));
};
