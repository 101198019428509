import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Popover, PopoverContent, PopoverTrigger } from "../components/popover";
import { CallFilter, MatchOptions } from "../types";
import DatePicker from "react-datepicker";
import DateInputPicker from "../common/date_input_picker";
import { AsyncSearchMultiSelect, } from "../components/async_search_multi_select";
import FilterIcon from "../icons/filter_icon";
import { Checkbox } from "../components/checkbox";
const SecondaryFilterPopoverV2 = ({ selectedMatchOption, setSelectedMatchOption, primaryFilterType, startDate, endDate, setStartDate, setEndDate, accounts, contacts, owners, setAccounts, setContacts, setOwners, accountsFetcher, contactsFetcher, usersFetcher, onHandleClearAllClick, setRecordedOnly: setFilterRecordedCalls, recordedOnly: filterRecordedCalls, }) => {
    const onDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        setSelectedMatchOption(MatchOptions.MATCH_ALL);
    };
    const changeDate = (days) => {
        let newDate;
        if (endDate) {
            newDate = new Date(endDate.setDate(endDate.getDate() + days));
        }
        else {
            newDate = new Date(startDate.setDate(startDate.getDate() + days));
        }
        setStartDate(newDate);
        setEndDate(newDate);
        setSelectedMatchOption(MatchOptions.MATCH_ALL);
    };
    const enableButtons = () => {
        if (accounts.length > 0 ||
            contacts.length > 0 ||
            owners.length > 0 ||
            startDate !== null ||
            endDate !== null) {
            return true;
        }
        return false;
    };
    return (_jsxs(Popover, { children: [_jsx(PopoverTrigger, Object.assign({ className: "justify-center items-center gap-2 flex" }, { children: _jsx("div", Object.assign({ className: "w-[40px] h-[40px] flex items-center justify-center hover:bg-blue-100 rounded-full" }, { children: _jsx(FilterIcon, {}) })) })), _jsxs(PopoverContent, Object.assign({ className: "w-[400px] p-4 bg-white rounded-lg shadow border border-neutral-200 flex-col flex gap-4 text-wds-black" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center gap-2" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center gap-1" }, { children: [_jsx("input", { type: "radio", value: MatchOptions.MATCH_ANY, checked: selectedMatchOption === MatchOptions.MATCH_ANY, onChange: (e) => {
                                            if (e.target.checked) {
                                                setSelectedMatchOption(MatchOptions.MATCH_ANY);
                                            }
                                        }, className: "focus:ring-0 h-3 w-3" }), _jsx("label", Object.assign({ htmlFor: "MATCH_ANY", className: `${selectedMatchOption === MatchOptions.MATCH_ANY ? "font-bold" : "font-normal"} text-sm tracking-[-0.14px]` }, { children: "Match any" }))] })), _jsxs("div", Object.assign({ className: "pl-8 flex items-center gap-1" }, { children: [_jsx("input", { type: "radio", value: MatchOptions.MATCH_ALL, checked: selectedMatchOption === MatchOptions.MATCH_ALL, onChange: (e) => {
                                            if (e.target.checked) {
                                                setSelectedMatchOption(MatchOptions.MATCH_ALL);
                                            }
                                        }, className: "focus:ring-0 h-3 w-3" }), _jsx("label", Object.assign({ htmlFor: "MATCH_ALL", className: `${selectedMatchOption === MatchOptions.MATCH_ALL ? "font-bold" : "font-normal"} text-sm tracking-[-0.14px]` }, { children: "Match all" }))] })), _jsxs("div", Object.assign({ className: "pl-8 flex items-center gap-1" }, { children: [_jsx(Checkbox, { id: "RECORDED_ONLY", onClick: () => setFilterRecordedCalls((prev) => !prev), checked: filterRecordedCalls }), _jsx("div", Object.assign({ className: "grid gap-1.5 leading-none" }, { children: _jsx("label", Object.assign({ htmlFor: "RECORDED_ONLY", className: "text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70" }, { children: "Recorded only" })) }))] }))] })), _jsxs("div", Object.assign({ className: "flex flex-col gap-4" }, { children: [primaryFilterType !== CallFilter.DATE && (_jsx("div", Object.assign({ className: "flex flex-col gap-2" }, { children: _jsx("div", Object.assign({ className: "flex flex-col items-center justify-center h-[40px]" }, { children: _jsx(DatePicker, { selected: startDate, onChange: onDateChange, startDate: startDate, endDate: endDate, selectsRange: true, customInput: _jsx(DateInputPicker, { date: startDate, startDate: startDate, endDate: endDate, onPreviousDateClick: () => changeDate(-1), onNextDateClick: () => changeDate(1), onTodayClick: () => {
                                                setStartDate(new Date());
                                                setEndDate(new Date());
                                            }, customWidth: "w-full" }) }) })) }))), primaryFilterType !== CallFilter.ACCOUNT && (_jsx(AsyncSearchMultiSelect, { id: "secondary-accounts", dataFetcher: accountsFetcher, onChange: (options) => {
                                    setAccounts(options);
                                }, selectedOptions: accounts, placeholder: "Select account", wrapPills: true, className: "w-full" })), primaryFilterType !== CallFilter.CONTACT && (_jsx(AsyncSearchMultiSelect, { id: "secondary-contacts", dataFetcher: contactsFetcher, onChange: (options) => {
                                    setContacts(options);
                                }, selectedOptions: contacts, placeholder: "Select contact", wrapPills: true, className: "w-full" })), primaryFilterType !== CallFilter.OWNER && (_jsx(AsyncSearchMultiSelect, { id: "secondary-users", dataFetcher: usersFetcher, onChange: (options) => {
                                    setOwners(options);
                                }, selectedOptions: owners, placeholder: "Select user", wrapPills: true, className: "w-full" }))] })), _jsx("div", Object.assign({ className: "flex justify-end items-center gap-2" }, { children: _jsx("button", Object.assign({ onClick: enableButtons() ? onHandleClearAllClick : () => { } }, { children: _jsx("span", Object.assign({ className: `p-4 font-['Lato'] text-[15px] font-bold ${enableButtons() ? "text-wds-gray-5 cursor-pointer" : "text-wds-gray-3 cursor-default"}`, style: { lineHeight: "22px" } }, { children: "Clear all" })) })) }))] }))] }));
};
export default SecondaryFilterPopoverV2;
