var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from "react";
import * as endpoints from "../common/endpoints";
import { useFieldMappings } from "./use_field_mappings";
/**
 * This hook is used to get the post call outputs for a call.
 * This hooks wraps all the logic to lazily fetch the complete post call output
 * which happens parallely for each field map.
 *
 * @param callId - The call id for which the post call outputs are to be fetched.
 */
const usePostCallOutputs = ({ callId }) => {
    const [postCallOutputMap, setPostCallOutputMap] = useState(new Map());
    const [fetchInProgress, setFetchInProgress] = useState(false);
    const { postCallOutputs } = useFieldMappings({ callId });
    useEffect(() => {
        if (!postCallOutputs)
            return;
        setPostCallOutputMap(new Map(postCallOutputs.map((item) => [item.id, { isLoaded: false, item }])));
    }, [postCallOutputs]);
    useEffect(() => {
        if (fetchInProgress) {
            return;
        }
        setFetchInProgress(true);
        // Fetch each post call output parallely if it is not already loaded.
        const fetchPostCallOutput = (id) => __awaiter(void 0, void 0, void 0, function* () {
            const item = postCallOutputMap.get(id);
            if (item && !item.isLoaded) {
                const output = yield endpoints.getPostCallOutput(id);
                postCallOutputMap.set(id, { isLoaded: true, item: output });
                setPostCallOutputMap(new Map(postCallOutputMap));
            }
        });
        const fetchPromises = [];
        Array.from(postCallOutputMap.keys()).forEach((id) => {
            fetchPromises.push(fetchPostCallOutput(id));
        });
        Promise.all(fetchPromises).then(() => {
            setFetchInProgress(false);
        });
    }, [postCallOutputMap]);
    const updatePostCallOutputInState = (updatedItem) => {
        setPostCallOutputMap((prev) => {
            const updatedMap = new Map(prev);
            updatedMap.set(updatedItem.id, { isLoaded: true, item: updatedItem });
            return updatedMap;
        });
    };
    const fetchPostCallOutput = (id) => __awaiter(void 0, void 0, void 0, function* () {
        const output = yield endpoints.getPostCallOutput(id);
        setPostCallOutputMap((prev) => {
            const updatedMap = new Map(prev);
            updatedMap.set(id, { isLoaded: true, item: output });
            return updatedMap;
        });
    });
    return {
        postCallOutputMap,
        updatePostCallOutputInState,
        setPostCallOutputMap,
        fetchPostCallOutput,
    };
};
export default usePostCallOutputs;
