import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from "react-router-dom";
import { Button } from "../../components/button";
import { BriefcaseIcon, ChevronDownIcon, ChevronLeftIcon, } from "@heroicons/react/24/outline";
import { getConnectedCrmLogo } from "../../common/crm_logo";
import { getCrmAccountDescription, getCrmAccountName, } from "../../common/call_account_utils";
import Dropdown from "../../common/dropdown";
import { Separator } from "../../components/separator";
export const AccountPageHeader = ({ tenant, account, accountBlocksToRender, scrollToBlockHandler, }) => {
    const AccountLinks = () => {
        return (_jsxs("div", Object.assign({ className: "flex items-center gap-2" }, { children: [account.linkedin_profile_url &&
                    account.linkedin_profile_url.length > 0 && (_jsx("div", Object.assign({ className: "flex w-10 h-10 p-2 items-center justify-center shrink-0" }, { children: _jsx("a", Object.assign({ href: account.linkedin_profile_url, target: "_blank", rel: "noopener noreferrer", className: "inline-block" }, { children: _jsx("img", { className: "h-5 w-5", src: "https://storage.googleapis.com/wiser-ai-public2/linkedin-logo-enable.png" }) })) }))), tenant.connected_crm &&
                    account.external_id &&
                    account.external_id.length > 0 && (_jsx("div", Object.assign({ className: "flex w-10 h-10 p-2 items-center justify-center shrink-0" }, { children: _jsx("a", Object.assign({ 
                        // TODO: Update the CRM URL link here by fetching the instance URL from paragon
                        href: account.linkedin_profile_url, target: "_blank", rel: "noopener noreferrer", className: "inline-block" }, { children: _jsx("img", { className: "h-5 w-5", src: getConnectedCrmLogo(tenant.connected_crm) }) })) })))] })));
    };
    const AccountOverview = () => {
        var _a;
        return (_jsx("div", Object.assign({ className: "flex flex-col items-start justify-center py-4 px-7 gap-2 self-stretch" }, { children: _jsxs("div", Object.assign({ className: "flex items-start gap-12 self-stretch" }, { children: [_jsxs("div", Object.assign({ className: "flex items-start gap-6 grow" }, { children: [account.logo_url && account.logo_url.length > 0 ? (_jsx("img", { className: "w-16 h-16 rounded-lg", src: account.logo_url })) : (_jsx(BriefcaseIcon, { className: "w-16 h-16 rounded-lg text-wds-gray-5" })), _jsxs("div", Object.assign({ className: "flex flex-col items-start gap-2 self-stretch" }, { children: [_jsxs("div", Object.assign({ className: "flex flex-col items-start gap-1 self-stretch" }, { children: [_jsx("span", Object.assign({ className: "text-3xl" }, { children: getCrmAccountName(account) })), _jsx("span", { children: (_a = getCrmAccountDescription(account)) !== null && _a !== void 0 ? _a : `No description found for ${getCrmAccountName(account)}` })] })), _jsx(AccountBlocksDropdown, {})] }))] })), _jsxs(Button, Object.assign({ variant: "outline", onClick: console.log, className: "flex items-center justify-center gap-1" }, { children: ["Add block", _jsx(ChevronDownIcon, { className: "h-4 w-4 shrink-0" })] }))] })) })));
    };
    const AccountBlocksDropdown = () => {
        return (_jsxs("div", Object.assign({ className: "flex items-center justify-center gap-1" }, { children: [_jsx("span", Object.assign({ className: "text-sm text-wds-gray-5 font-medium shrink-0" }, { children: "Jump to" })), _jsx(Dropdown, { options: accountBlocksToRender.map((block) => {
                        return {
                            value: block.id,
                            label: block.label,
                        };
                    }), defaultSelected: "account-overview", onOptionSelect: (option) => {
                        scrollToBlockHandler(option);
                    }, buttonClasses: "h-8 bg-white rounded-lg border border-wds-gray-3" })] })));
    };
    return (_jsxs("div", Object.assign({ className: "sticky z-[2] top-0 flex flex-col items-start self-stretch border border-wds-gray-3 rounded-lg bg-white" }, { children: [_jsxs("div", Object.assign({ className: "flex px-3 py-1 justify-between items-center self-stretch" }, { children: [_jsx(Link, Object.assign({ to: "/crm/home/accounts" }, { children: _jsxs(Button, Object.assign({ variant: "link", className: "flex gap-2 px-3 items-center h-8" }, { children: [_jsx(ChevronLeftIcon, { className: "w-4 h-4" }), "All accounts"] })) })), _jsx(AccountLinks, {})] })), _jsx(Separator, {}), _jsx(AccountOverview, {})] })));
};
