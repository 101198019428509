import { useEffect, useMemo, useState } from "react";
import * as endpoints from "../common/endpoints";
export const useCrmColumnEnrichment = (crmObjectType, tableCols, columnViewState, setColumnViewState, refetchCrmFields, refetchCrmData) => {
    const [allColumns, setAllColumns] = useState([]);
    const [hiddenColumns, setHiddenColumns] = useState([]);
    const [playbookItems, setPlaybookItems] = useState([]);
    useEffect(() => {
        endpoints.getPlaybookItems().then((response) => setPlaybookItems(response));
    }, []);
    const enrichmentColumnPlaybookItemIds = useMemo(() => {
        return tableCols
            .filter((col) => col.columnInfo.columnType === "ENRICHMENT" &&
            !!col.columnInfo.sourcePlaybookItemId)
            .map((col) => col.columnInfo.sourcePlaybookItemId);
    }, [tableCols]);
    useEffect(() => {
        setAllColumns([
            ...tableCols,
            ...playbookItems
                // Filter out playbook items that are already added as CrmFields.
                .filter((item) => !enrichmentColumnPlaybookItemIds.includes(item.id))
                .map((item) => {
                return {
                    id: item.name,
                    label: item.name,
                    columnInfo: {
                        columnType: "ENRICHMENT",
                        sourcePlaybookItemId: item.id,
                    },
                    enableColumnFilter: true,
                    enableSorting: true,
                    type: "text",
                };
            }),
        ]);
    }, [tableCols, playbookItems, enrichmentColumnPlaybookItemIds]);
    useEffect(() => {
        setHiddenColumns(allColumns.filter((col) => !columnViewState.includes(col.id)));
    }, [allColumns, columnViewState]);
    const addColumn = (columnId, index) => {
        const newColumn = allColumns.find((col) => col.id === columnId);
        if (!newColumn) {
            console.error(`Column with id ${columnId} not found in allColumns`);
            return;
        }
        if (newColumn.columnInfo.columnType === "ENRICHMENT" &&
            // This check ensures that if the playbook item is already added as an
            // ENRICHMENT column, we don't create a new one for the same.
            !enrichmentColumnPlaybookItemIds.includes(newColumn.columnInfo.sourcePlaybookItemId)) {
            endpoints
                .createCrmField(newColumn.label, {
                type: "text",
                restrictions: { length: 10000 },
            }, [crmObjectType], newColumn.columnInfo.sourcePlaybookItemId)
                .then((resp) => {
                endpoints.executeColumnEnrichment(resp.id, crmObjectType);
                const newColumnViewState = Array.from(columnViewState);
                newColumnViewState.splice(index, 1, resp.name);
                setColumnViewState(newColumnViewState);
            })
                .then(() => refetchCrmFields())
                .then(() => refetchCrmData());
            return;
        }
        const newColumnViewState = Array.from(columnViewState);
        newColumnViewState.splice(index, 1, columnId);
        setColumnViewState(newColumnViewState);
    };
    const removeColumn = (columnId) => {
        setColumnViewState(columnViewState.filter((colId) => colId !== columnId));
    };
    return {
        allColumns,
        hiddenColumns,
        addColumn,
        removeColumn,
    };
};
