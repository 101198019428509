var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import * as endpoints from "../common/endpoints";
import { createColumnHelper } from "@tanstack/react-table";
import CrmFieldValue from "./crm_field_value";
import { IndeterminateCheckbox } from "./select_checkbox";
import { CrmTable } from "./crm_table";
import { CrmObjectReferenceSwitcher } from "./crm_object_reference_switcher";
const CrmContacts = () => {
    // State to store the CRM contacts keyed by their ID.
    const [crmContactsMap, setCrmContactsMap] = useState(new Map());
    // State to store the CRM accounts keyed by their ID.
    const [crmAccountsMap, setCrmAccountsMap] = useState(new Map());
    const columnHelper = createColumnHelper();
    const columns = useMemo(() => {
        // Contact specific table columns.
        return [
            columnHelper.accessor("id", {
                id: "row-select",
                header: (props) => (_jsx(IndeterminateCheckbox, Object.assign({}, {
                    checked: props.table.getIsAllRowsSelected(),
                    indeterminate: props.table.getIsSomeRowsSelected(),
                    onChange: props.table.getToggleAllRowsSelectedHandler(),
                }))),
                cell: (props) => (_jsx("div", Object.assign({ className: "p-4" }, { children: _jsx(IndeterminateCheckbox, Object.assign({}, {
                        checked: props.row.getIsSelected(),
                        disabled: !props.row.getCanSelect(),
                        indeterminate: props.row.getIsSomeSelected(),
                        onChange: props.row.getToggleSelectedHandler(),
                    })) }))),
                size: 60,
                enableSorting: false,
                enableColumnFilter: false,
            }),
            columnHelper.accessor((row) => { var _a; return (_a = row.contact_name) !== null && _a !== void 0 ? _a : ""; }, {
                id: "contact_name",
                header: "Name",
                cell: (props) => (_jsx(CrmFieldValue, { crmObjectId: parseInt(props.row.id), crmFieldId: -1, crmFieldSchema: { type: "text", restrictions: { length: 100 } }, crmFieldValue: props.getValue(), onNewValueChange: (newValue) => __awaiter(void 0, void 0, void 0, function* () {
                        yield endpoints.updateCrmContact(parseInt(props.row.id), 
                        /* name= */ newValue, 
                        /* email= */ undefined, 
                        /* accountId= */ undefined, 
                        /* linkedinProfileUrl= */ undefined);
                        setCrmContactsMap((prevMap) => {
                            const updatedContact = prevMap.get(parseInt(props.row.id));
                            if (updatedContact) {
                                updatedContact.contact_name = newValue;
                                return new Map([
                                    ...prevMap,
                                    [parseInt(props.row.id), updatedContact],
                                ]);
                            }
                            return prevMap;
                        });
                    }) })),
                meta: {
                    fieldId: -1,
                    fieldSchema: {
                        type: "text",
                        restrictions: { length: 100 },
                    },
                    required: true,
                    columnInfo: {
                        columnType: "CUSTOM",
                    },
                },
                filterFn: "wiserCrmTableFilterFn",
                sortingFn: "alphanumeric",
                size: 320,
            }),
            columnHelper.accessor((row) => { var _a; return (_a = row.email) !== null && _a !== void 0 ? _a : ""; }, {
                id: "email",
                header: "Email",
                cell: (props) => (_jsx(CrmFieldValue, { crmObjectId: parseInt(props.row.id), crmFieldId: -1, crmFieldSchema: { type: "text", restrictions: { length: 60 } }, crmFieldValue: props.getValue(), onNewValueChange: (newValue) => __awaiter(void 0, void 0, void 0, function* () {
                        yield endpoints.updateCrmContact(parseInt(props.row.id), 
                        /* name= */ undefined, 
                        /* email= */ newValue, 
                        /* accountId= */ undefined, 
                        /* linkedinProfileUrl= */ undefined);
                        setCrmContactsMap((prevMap) => {
                            const updatedContact = prevMap.get(parseInt(props.row.id));
                            if (updatedContact) {
                                updatedContact.email = newValue;
                                return new Map([
                                    ...prevMap,
                                    [parseInt(props.row.id), updatedContact],
                                ]);
                            }
                            return prevMap;
                        });
                    }) })),
                meta: {
                    fieldId: -2,
                    fieldSchema: {
                        type: "text",
                        restrictions: { length: 60 },
                    },
                    required: true,
                    columnInfo: {
                        columnType: "CUSTOM",
                    },
                },
                filterFn: "wiserCrmTableFilterFn",
                sortingFn: "alphanumeric",
                size: 320,
            }),
            columnHelper.accessor((row) => { var _a; return (_a = row.linkedin_profile_url) !== null && _a !== void 0 ? _a : ""; }, {
                id: "linkedin_profile_url",
                header: "LinkedIn URL",
                cell: (props) => (_jsx(CrmFieldValue, { crmObjectId: parseInt(props.row.id), crmFieldId: -1, crmFieldSchema: { type: "text", restrictions: { length: 500 } }, crmFieldValue: props.getValue(), onNewValueChange: (newValue) => __awaiter(void 0, void 0, void 0, function* () {
                        yield endpoints.updateCrmContact(parseInt(props.row.id), 
                        /* name= */ undefined, 
                        /* email= */ undefined, 
                        /* accountId= */ undefined, 
                        /* linkedinProfileUrl= */ newValue);
                        setCrmContactsMap((prevMap) => {
                            const updatedContact = prevMap.get(parseInt(props.row.id));
                            if (updatedContact) {
                                updatedContact.linkedin_profile_url = newValue;
                                return new Map([
                                    ...prevMap,
                                    [parseInt(props.row.id), updatedContact],
                                ]);
                            }
                            return prevMap;
                        });
                    }) })),
                meta: {
                    fieldId: -3,
                    fieldSchema: {
                        type: "text",
                        restrictions: { length: 500 },
                    },
                    required: true,
                    columnInfo: {
                        columnType: "CUSTOM",
                    },
                },
                filterFn: "wiserCrmTableFilterFn",
                sortingFn: "alphanumeric",
                size: 320,
            }),
            columnHelper.accessor((row) => {
                var _a, _b;
                return ((_a = row.account) === null || _a === void 0 ? void 0 : _a.id) && crmAccountsMap.has(row.account.id)
                    ? (_b = crmAccountsMap.get(row.account.id)) === null || _b === void 0 ? void 0 : _b.account_name
                    : "";
            }, {
                id: "account",
                header: "Account",
                cell: (props) => {
                    var _a;
                    return (_jsx(CrmObjectReferenceSwitcher, { idToLabelMap: new Map(Array.from(crmAccountsMap.values()).map((account) => {
                            var _a;
                            return [
                                account.id,
                                (_a = account.account_name) !== null && _a !== void 0 ? _a : "",
                            ];
                        })), selectedId: (_a = props.row.original.account) === null || _a === void 0 ? void 0 : _a.id, onSelectedIdChange: (newAccountId) => __awaiter(void 0, void 0, void 0, function* () {
                            const updatedContact = yield endpoints.updateCrmContact(parseInt(props.row.id), 
                            /* name= */ undefined, 
                            /* email= */ undefined, 
                            /* accountId= */ newAccountId, 
                            /* linkedinProfileUrl= */ undefined);
                            setCrmContactsMap((prevMap) => {
                                const updatedMap = new Map(prevMap);
                                updatedMap.set(updatedContact.id, updatedContact);
                                return updatedMap;
                            });
                        }) }));
                },
                meta: {
                    fieldId: -4,
                    // This is used for filtering, and since we use account name as the column value,
                    // we can use text as field type and use filter and sorting fns for text cells.
                    fieldSchema: {
                        type: "text",
                        restrictions: { length: 1000 },
                    },
                    required: true,
                    columnInfo: {
                        columnType: "CUSTOM",
                    },
                },
                filterFn: "wiserCrmTableFilterFn",
                sortingFn: "alphanumeric",
                size: 320,
            }),
            columnHelper.accessor((row) => { var _a; return (_a = row.role_name) !== null && _a !== void 0 ? _a : ""; }, {
                id: "contact_role_in_wiser",
                header: "Role",
                cell: (props) => (_jsx(CrmFieldValue, { crmObjectId: parseInt(props.row.id), crmFieldId: -5, crmFieldSchema: {
                        type: "single_select",
                        restrictions: { picklist_values: ["Customer", "Salesperson"] },
                    }, crmFieldValue: props.getValue(), onNewValueChange: (newValue) => __awaiter(void 0, void 0, void 0, function* () {
                        yield endpoints.updateCrmContact(parseInt(props.row.id), 
                        /* name= */ undefined, 
                        /* email= */ undefined, 
                        /* accountId= */ undefined, 
                        /* linkedinProfileUrl= */ undefined, 
                        /* role= */ newValue);
                        setCrmContactsMap((prevMap) => {
                            const updatedContact = prevMap.get(parseInt(props.row.id));
                            if (updatedContact) {
                                updatedContact.role_name = newValue;
                                return new Map([
                                    ...prevMap,
                                    [parseInt(props.row.id), updatedContact],
                                ]);
                            }
                            return prevMap;
                        });
                    }) })),
                meta: {
                    fieldId: -5,
                    // This is used for filtering, and since we use account name as the column value,
                    // we can use text as field type and use filter and sorting fns for text cells.
                    fieldSchema: {
                        type: "single_select",
                        restrictions: { picklist_values: ["Customer", "Salesperson"] },
                    },
                    columnInfo: {
                        columnType: "CUSTOM",
                    },
                },
                filterFn: "wiserCrmTableFilterFn",
                sortingFn: "alphanumeric",
                size: 240,
            }),
        ];
    }, [crmAccountsMap]);
    // Memoize the table data to prevent unnecessary re-renders.
    const tableData = useMemo(() => {
        return Array.from(crmContactsMap.values());
    }, [crmContactsMap]);
    const fetchCrmContacts = () => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield endpoints.getCrmContacts();
        setCrmContactsMap(new Map(response.map((contact) => [contact.id, contact])));
    });
    useEffect(() => {
        fetchCrmContacts();
        endpoints.getCrmAccounts().then((response) => {
            setCrmAccountsMap(new Map(response.map((account) => [account.id, account])));
        });
    }, []);
    const onNewRowCreate = (newRowData, crmFieldValues) => __awaiter(void 0, void 0, void 0, function* () {
        const newCrmContact = yield endpoints.createCrmContact(newRowData.find((rowData) => rowData.columnId === "contact_name").value, newRowData.find((rowData) => rowData.columnId === "email").value, newRowData.find((rowData) => rowData.columnId === "linkedin_profile_url")
            .value, newRowData.find((rowData) => rowData.columnId === "account").value, crmFieldValues);
        const updatedCrmContact = yield endpoints.getCrmContact(newCrmContact.id);
        setCrmContactsMap((prevMap) => {
            return new Map([...prevMap, [updatedCrmContact.id, updatedCrmContact]]);
        });
    });
    const onCrmFieldValueChange = (contactId, crmField, newValue) => __awaiter(void 0, void 0, void 0, function* () {
        const updatedCrmDataId = yield endpoints.createOrUpdateCrmContactData(contactId, crmField.id, newValue);
        setCrmContactsMap((prevMap) => {
            const updatedContact = prevMap.get(contactId);
            if (updatedContact) {
                // If crm_data contains a field with the same ID, update the value.
                // else add a new crm_data object.
                if (updatedContact.crm_data.some((crmData) => crmData.crm_field.id === crmField.id)) {
                    updatedContact.crm_data = updatedContact.crm_data.map((crmData) => crmData.crm_field.id === crmField.id
                        ? Object.assign(Object.assign({}, crmData), { id: updatedCrmDataId, value: newValue }) : crmData);
                }
                else {
                    updatedContact.crm_data.push({
                        id: updatedCrmDataId,
                        crm_field: crmField,
                        value: newValue,
                    });
                }
                return new Map([...prevMap, [contactId, updatedContact]]);
            }
            return prevMap;
        });
    });
    return (_jsx(CrmTable, { tableData: {
            crmObject: "Contact",
            rows: tableData,
        }, columns: columns, columnData: [
            {
                columnId: "account",
                idToAccountNameMap: new Map(Array.from(crmAccountsMap.values()).map((account) => {
                    var _a;
                    return [
                        account.id,
                        (_a = account.account_name) !== null && _a !== void 0 ? _a : "",
                    ];
                })),
            },
        ], onNewRowCreate: onNewRowCreate, onCrmFieldValueChange: onCrmFieldValueChange, refetchCrmData: fetchCrmContacts }));
};
export default CrmContacts;
