var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import CrmFieldValue from "./crm_field_value";
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import classNames from "../class_names";
import { CrmObjectReferenceSwitcher } from "./crm_object_reference_switcher";
export const CrmNewRow = (props) => {
    const [rowEditing, setRowEditing] = useState(false);
    const [newRowData, setNewRowData] = useState([]);
    const [showRequiredFieldError, setShowRequiredFieldError] = useState(false);
    const newRowRef = useRef(null);
    const getCrmFieldValueRecordsFromNewRowData = (data) => {
        // Helper method to compute the CRM field value records from the
        // new row data entered by the user. These field value records are then
        // sent to the onNewRowCreate handler.
        return data
            .filter((rowData) => 
        // Negative ids are used for default fields.
        !!rowData.crmFieldId && rowData.crmFieldId > 0)
            .reduce((acc, rowData) => {
            acc[rowData.crmFieldId] = rowData.value;
            return acc;
        }, {});
    };
    const triggerNewRowCreation = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        setShowRequiredFieldError(false);
        yield props.onNewRowCreate(newRowData, getCrmFieldValueRecordsFromNewRowData(newRowData));
        setNewRowData([]);
        setRowEditing(false);
    }), [newRowData]);
    const allRequiredFieldsFilled = useMemo(() => {
        return props.crmTableInfo.columns
            .filter((col) => { var _a; return (_a = col.columnDef.meta) === null || _a === void 0 ? void 0 : _a.required; })
            .every((col) => { var _a; return !!((_a = newRowData.find((d) => d.columnId === col.id)) === null || _a === void 0 ? void 0 : _a.value); });
    }, [newRowData]);
    useEffect(() => {
        // Registers a click event handler which triggers props.onNewRowCreate when the user clicks outside the new row
        const handleOutsideClick = (event) => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            const clickedElement = event.target;
            if (!((_a = newRowRef.current) === null || _a === void 0 ? void 0 : _a.contains(clickedElement))) {
                if (rowEditing) {
                    if (newRowData.length > 0) {
                        if (!allRequiredFieldsFilled) {
                            setShowRequiredFieldError(true);
                            return;
                        }
                        yield triggerNewRowCreation();
                    }
                }
            }
        });
        document.addEventListener("click", handleOutsideClick, false);
        return () => {
            document.removeEventListener("click", handleOutsideClick, false);
        };
    }, [newRowData, showRequiredFieldError, allRequiredFieldsFilled]);
    const orderedColumnsAsPerTableState = props.crmTableInfo.columns.sort((colA, colB) => {
        // Sort on the basis of whether colA.id appears before colB.id in tableState.columnOrder
        return (props.crmTableInfo.tableState.columnOrder.indexOf(colA.id) -
            props.crmTableInfo.tableState.columnOrder.indexOf(colB.id));
    });
    return (_jsxs(_Fragment, { children: [showRequiredFieldError && (_jsx("div", Object.assign({ className: "absolute -top-3 left-0 w-60 bg-red-700 text-white text-xs flex items-center justify-center rounded-lg" }, { children: "All required fields must be filled out" }))), _jsx("tr", Object.assign({ ref: newRowRef, className: classNames("h-9", rowEditing ? "bg-white" : "bg-gray-100") }, { children: orderedColumnsAsPerTableState.map((col) => {
                    var _a, _b, _c, _d, _e, _f;
                    if (col.id === "row-select") {
                        return (_jsx("td", Object.assign({ className: "border-r border-gray-300" }, { children: _jsx("button", Object.assign({ className: "flex items-center justify-center w-full h-full", onClick: () => {
                                    setRowEditing(!rowEditing);
                                    setNewRowData([]);
                                    setShowRequiredFieldError(false);
                                } }, { children: rowEditing ? (_jsx(XMarkIcon, { className: "h-5 w-5 text-gray-500" })) : (_jsx(PlusIcon, { className: "h-5 w-5 text-gray-500" })) })) }), `${col.id}-new-row`));
                    }
                    return (_jsx("td", Object.assign({ className: "border-r border-gray-300" }, { children: (props.crmTableInfo.crmObject === "Contact" ||
                            props.crmTableInfo.crmObject === "Opportunity") &&
                            col.id === "account" ? (_jsx(CrmObjectReferenceSwitcher, { idToLabelMap: props.crmTableInfo.columnData.find((columnData) => columnData.columnId === "account").idToAccountNameMap, selectedId: (_a = newRowData.find((rowData) => rowData.columnId === col.id)) === null || _a === void 0 ? void 0 : _a.value, onSelectedIdChange: (selectedId) => {
                                var _a;
                                setNewRowData([
                                    ...newRowData.filter((rowData) => rowData.columnId !== col.id),
                                    {
                                        columnId: col.id,
                                        value: selectedId,
                                        crmFieldId: (_a = col.columnDef.meta) === null || _a === void 0 ? void 0 : _a.fieldId,
                                    },
                                ]);
                            }, showRequired: rowEditing })) : (_jsx(CrmFieldValue, { crmObjectId: -1, crmFieldId: (_b = col.columnDef.meta) === null || _b === void 0 ? void 0 : _b.fieldId, crmFieldSchema: (_c = col.columnDef.meta) === null || _c === void 0 ? void 0 : _c.fieldSchema, crmFieldValue: (_e = (_d = newRowData.find((d) => d.columnId === col.id)) === null || _d === void 0 ? void 0 : _d.value) !== null && _e !== void 0 ? _e : "", onNewValueChange: (content) => __awaiter(void 0, void 0, void 0, function* () {
                                var _g;
                                setNewRowData([
                                    ...newRowData.filter((rowData) => rowData.columnId !== col.id),
                                    {
                                        columnId: col.id,
                                        value: content,
                                        crmFieldId: (_g = col.columnDef.meta) === null || _g === void 0 ? void 0 : _g.fieldId,
                                    },
                                ]);
                            }), editDisabled: !rowEditing, showRequired: ((_f = col.columnDef.meta) === null || _f === void 0 ? void 0 : _f.required) && rowEditing })) }), `${col.id}-new-row`));
                }) }))] }));
};
