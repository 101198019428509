// This file should be deleted once we add support
// for all the functionalities in the BE.
export const MOCK_SUMMARY = "Engagement with Rippling has been progressing positively. Feedback from Rippling has been encouraging, indicating strong interest in our solutions. The account shows promising potential, with Rippling demonstrating genuine interest and engagement in our offerings. It is recommended to continued proactive engagement and take a personalized approach to the deal.";
export const DUMMY_EXPERIENCES = [
    {
        company: "Wiser AI",
        title: "Engineer",
        description: null,
        start_date: "2023-10-01",
        end_date: null,
    },
    {
        company: "Google",
        title: "Software Engineer",
        description: null,
        start_date: "2021-09-01",
        end_date: "2023-10-01",
    },
    {
        company: "Rippling",
        title: "Software Engineer",
        description: null,
        start_date: "2019-07-01",
        end_date: "2021-09-01",
    },
    {
        company: "Morgan Stanley",
        title: "Spring Analyst",
        description: null,
        start_date: "2019-01-01",
        end_date: "2019-06-01",
    },
    {
        company: "Morgan Stanley",
        title: "Summer Analyst",
        description: null,
        start_date: "2018-05-01",
        end_date: "2018-07-01",
    },
];
