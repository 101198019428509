import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import classNames from "./class_names";
import { PlayCircleIcon } from "@heroicons/react/24/outline";
import { formatCallTime } from "./strings";
function TranscriptEntryCard(props) {
    useEffect(() => {
        props.onRender();
    }, []);
    return (_jsxs("div", Object.assign({ className: classNames("flex flex-col p-2 my-1 border rounded-lg shadow-sm w-5/6 mx-auto", props.focused ? "bg-green-100" : "bg-white") }, { children: [_jsxs("div", Object.assign({ className: "flex justify-between" }, { children: [_jsxs("div", Object.assign({ className: "text-gray-900" }, { children: [props.entry.speaker, " ", _jsxs("span", Object.assign({ className: "text-gray-500" }, { children: ["@", formatCallTime(props.callStartTime, props.entry.startTime)] }))] })), _jsx("button", Object.assign({ className: "text-gray-500", onClick: props.onAudioStarted }, { children: _jsx(PlayCircleIcon, { className: "h-5 w-5" }) }))] })), _jsx("div", Object.assign({ className: "text-gray-500" }, { children: props.entry.text }))] })));
}
export default TranscriptEntryCard;
