import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { CallDetailsChips } from "../call_heading/call_details_chips";
/**
 * Mostly copy-paste from home/call_heading.tsx. TODO; consolidate
 *
 */
const CallHeading = (props) => {
    return (_jsx("div", Object.assign({ className: "flex gap-4 w-full mt-4 px-12 bg-white flex-col justify-start items-start" }, { children: _jsx("div", Object.assign({ className: "self-stretch justify-start items-start flex" }, { children: _jsx("div", Object.assign({ className: "shrink basis-0 flex-col justify-start items-start gap-1 flex flex-grow" }, { children: _jsxs("div", Object.assign({ className: "flex flex-col items-center w-full" }, { children: [_jsx("div", Object.assign({ className: "flex w-full flex-row justify-start items-center text-wds-blue-3 font-bold pb-2 stroke-wds-blue-3" }, { children: _jsxs("a", Object.assign({ href: "/home", className: "flex items-center gap-2" }, { children: [_jsx(ChevronLeftIcon, { className: "w-4 h-4 stroke-2" }), "All calls"] })) })), _jsxs("div", Object.assign({ className: "flex w-full gap-2 flex-col md:flex-row items-center" }, { children: [_jsx("div", Object.assign({ className: "text-lg font-bold text-Black font-lato leading-7 md:border-r border-gray-300 pr-2" }, { children: props.call.name })), _jsx(CallDetailsChips, { call: props.call, updateCall: props.updateCall })] }))] })) })) })) })));
};
export default CallHeading;
