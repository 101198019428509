var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import CallCrmObjectBaseModal, { CallCrmObjectLinkForm, CrmInputField, EditableCrmField, } from "./call_crm_object_base_modal";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { createCrmAccount, fetchCrmObject, getCrmAccount, getCrmFields, getLiveCallById, queryCrmObjects, setCallAccountAndOpportunity, updateCrmAccount, } from "../common/endpoints";
import { getAccountNameForCall, getCrmAccountName } from "./call_account_utils";
import { AsyncSearchSelect } from "../components/async_search_select";
const CallCrmAccountModal = ({ tenant, call, open, setOpen, updateSelectedCall, }) => {
    const [accountSelector, setAccountSelector] = useState("EXISTING");
    const [selectedExistingAccountId, setSelectedExistingAccountId] = useState(null);
    const [updatedAccountData, setUpdatedAccountData] = useState({});
    const [isSaving, setIsSaving] = useState(false);
    const { data: selectedAccount, isLoading: crmAccountLoading, isError: crmAccountError, } = useQuery({
        queryKey: ["crmAccount", selectedExistingAccountId],
        queryFn: () => {
            if (!selectedExistingAccountId)
                return;
            return getCrmAccount(selectedExistingAccountId);
        },
        enabled: !!selectedExistingAccountId,
    });
    const { data: crmFields, isLoading: crmFieldsLoading, isError: crmFieldsError, } = useQuery({
        queryKey: ["crmFields", tenant.id],
        queryFn: getCrmFields,
    });
    const queryClient = useQueryClient();
    const accountUpdateMutation = useMutation({
        mutationFn: ({ accountId, accountData, }) => updateCrmAccount(accountId, accountData.name, accountData.website, accountData.linkedinProfileUrl),
    });
    const accountCreateMutation = useMutation({
        mutationFn: ({ accountData }) => {
            var _a;
            return createCrmAccount(accountData.name, accountData.website, accountData.linkedinProfileUrl, (_a = accountData.crmFieldValues) !== null && _a !== void 0 ? _a : {});
        },
    });
    const requiredCrmFields = useMemo(() => {
        if (crmFieldsLoading || crmFieldsError)
            return [];
        if (!crmFields)
            return [];
        return crmFields
            .filter((crmField) => crmField.applicable_objects.includes("Account"))
            .filter((crmField) => !crmField.nullable);
    }, [crmFieldsLoading, crmFieldsError, crmFields]);
    useEffect(() => {
        setAccountSelector("EXISTING");
        setSelectedExistingAccountId(call.account ? call.account.id : null);
        setIsSaving(false);
    }, [open, call.id]);
    useEffect(() => {
        if (!selectedAccount) {
            if (!call.account) {
                setUpdatedAccountData({});
                return;
            }
            setUpdatedAccountData({
                name: getAccountNameForCall(call, ""),
                website: call.account.website,
                linkedinProfileUrl: call.account.linkedin_profile_url,
            });
            return;
        }
        setUpdatedAccountData({
            name: selectedAccount.account_name,
            linkedinProfileUrl: selectedAccount.linkedin_profile_url,
            website: selectedAccount.website,
        });
    }, [selectedAccount, call]);
    const DEFAULT_ACCOUNT_FIELDS = [
        {
            id: 0,
            fieldLabel: "Account name",
            fieldValue: updatedAccountData.name,
            placeholder: "Enter a name for this Account",
            onFieldValueChange: (value) => {
                setUpdatedAccountData((prev) => {
                    return Object.assign(Object.assign({}, prev), { name: value });
                });
            },
        },
        {
            id: 1,
            fieldLabel: "Website",
            fieldValue: updatedAccountData.website,
            placeholder: "e.g. www.acme.com",
            onFieldValueChange: (value) => {
                setUpdatedAccountData((prev) => {
                    return Object.assign(Object.assign({}, prev), { website: value });
                });
            },
        },
        {
            id: 2,
            fieldLabel: "Linkedin profile URL",
            fieldValue: updatedAccountData.linkedinProfileUrl,
            placeholder: "e.g. www.linkedin.com/company/acme",
            onFieldValueChange: (value) => {
                setUpdatedAccountData((prev) => {
                    return Object.assign(Object.assign({}, prev), { linkedinProfileUrl: value });
                });
            },
        },
    ];
    const accountsFetcher = (userQuery, cursor) => __awaiter(void 0, void 0, void 0, function* () {
        const accountsQueryResponse = yield queryClient.fetchQuery({
            queryFn: () => queryCrmObjects("Account", 
            /* fieldToQuery= */ "name", userQuery, cursor),
        });
        return {
            results: accountsQueryResponse.results.map((account) => {
                return { label: account.name, value: account.id };
            }),
            next_cursor: accountsQueryResponse.next_cursor,
        };
    });
    const fetchAccountMutation = useMutation({
        mutationFn: ({ accountId }) => fetchCrmObject("Account", accountId),
        onSuccess: ({ id }) => {
            setSelectedExistingAccountId(id);
        },
    });
    const renderCrmAccountForm = () => {
        var _a, _b;
        return (_jsx(CallCrmObjectLinkForm, { objectSelector: accountSelector, setObjectSelector: (option) => {
                setAccountSelector(option);
                setSelectedExistingAccountId(null);
            }, existingObjectForm: _jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: "flex flex-col items-start gap-1 self-stretch" }, { children: [_jsx("span", Object.assign({ className: "text-wds-gray-5 text-xs font-medium" }, { children: "Account" })), _jsx(AsyncSearchSelect, { dataFetcher: accountsFetcher, onSelect: (option) => {
                                    if (!option)
                                        return;
                                    // No need to await here, the mutate onSuccess handler
                                    // will update the state.
                                    fetchAccountMutation.mutateAsync({ accountId: option.value });
                                }, placeholder: "No account mapped", selectedOption: selectedAccount
                                    ? {
                                        label: getCrmAccountName(selectedAccount),
                                        value: `${selectedAccount.id}`,
                                    }
                                    : undefined })] })), fetchAccountMutation.isLoading && _jsx("span", { children: "Syncing account..." }), !fetchAccountMutation.isLoading &&
                        selectedExistingAccountId &&
                        selectedAccount && (_jsxs("div", Object.assign({ className: "flex flex-col gap-4" }, { children: [_jsx(EditableCrmField, { fieldLabel: "Account name", fieldValue: selectedAccount.account_name, onValueChange: (newValue) => {
                                    setUpdatedAccountData((prev) => {
                                        return Object.assign(Object.assign({}, prev), { name: newValue });
                                    });
                                } }), _jsx(EditableCrmField, { fieldLabel: "Website", fieldValue: (_a = selectedAccount.website) !== null && _a !== void 0 ? _a : "", onValueChange: (newValue) => {
                                    setUpdatedAccountData((prev) => {
                                        return Object.assign(Object.assign({}, prev), { website: newValue });
                                    });
                                } }), _jsx(EditableCrmField, { fieldLabel: "Linkedin profile URL", fieldValue: (_b = selectedAccount.linkedin_profile_url) !== null && _b !== void 0 ? _b : "", onValueChange: (newValue) => {
                                    setUpdatedAccountData((prev) => {
                                        return Object.assign(Object.assign({}, prev), { linkedinProfileUrl: newValue });
                                    });
                                } })] })))] }), newObjectForm: _jsxs("div", Object.assign({ className: "flex flex-col gap-4 items-start" }, { children: [DEFAULT_ACCOUNT_FIELDS.map((field) => (_jsx(CrmInputField, { fieldId: field.id, fieldLabel: field.fieldLabel, fieldValue: field.fieldValue, onValueChange: field.onFieldValueChange, placeholder: field.placeholder }, field.fieldLabel))), requiredCrmFields.map((crmField) => (_jsx(CrmInputField, { fieldId: crmField.id, fieldSchema: crmField.content_type_definition, fieldLabel: crmField.name, fieldValue: updatedAccountData.crmFieldValues
                            ? updatedAccountData.crmFieldValues[crmField.id]
                            : undefined, onValueChange: (value) => {
                            setUpdatedAccountData((prev) => {
                                return Object.assign(Object.assign({}, prev), { crmFieldValues: Object.assign(Object.assign({}, prev.crmFieldValues), { [crmField.id]: value }) });
                            });
                        } }, crmField.id)))] })) }));
    };
    const enableSubmit = useMemo(() => {
        const isFieldValid = (field) => !!field && field.length > 0;
        const isAccountDataUpdateValid = (accountData) => {
            return [
                accountData.name,
                accountData.website,
                accountData.linkedinProfileUrl,
            ].every(isFieldValid);
        };
        const allRequiredFieldsFilled = (accountData) => {
            if (!isFieldValid(accountData.name) ||
                !isFieldValid(accountData.website) ||
                !isFieldValid(accountData.linkedinProfileUrl)) {
                return false;
            }
            if (requiredCrmFields.length === 0) {
                return true;
            }
            if (!accountData.crmFieldValues) {
                return false;
            }
            return requiredCrmFields.every((crmField) => isFieldValid(accountData.crmFieldValues[crmField.id]));
        };
        switch (accountSelector) {
            case "EXISTING":
                return (selectedExistingAccountId !== null &&
                    isAccountDataUpdateValid(updatedAccountData));
            case "NEW":
                return allRequiredFieldsFilled(updatedAccountData);
        }
    }, [
        accountSelector,
        selectedExistingAccountId,
        updatedAccountData,
        requiredCrmFields,
    ]);
    if (crmAccountLoading ||
        crmAccountError ||
        crmFieldsLoading ||
        crmFieldsError ||
        !crmFields) {
        // No loading/error state UI
        return null;
    }
    return (_jsx(CallCrmObjectBaseModal, { tenant: tenant, title: "Change account mapping", description: "Changing the mapped account will affect all of the mappings for all activities related to this call.", open: open, setOpen: (value) => {
            if (!value) {
                // Reset the internal state on modal close.
                setAccountSelector("EXISTING");
                setSelectedExistingAccountId(null);
                setUpdatedAccountData({});
            }
            setOpen(value);
        }, submitCallback: (_) => __awaiter(void 0, void 0, void 0, function* () {
            setIsSaving(true);
            // Null assertions here because we already check for null values
            // in enableSubmit above.
            const accountResponse = accountSelector === "EXISTING"
                ? yield accountUpdateMutation.mutateAsync({
                    accountId: selectedExistingAccountId,
                    accountData: updatedAccountData,
                })
                : yield accountCreateMutation.mutateAsync({
                    accountData: updatedAccountData,
                });
            yield setCallAccountAndOpportunity(call.id, accountResponse.id);
            const updatedLiveCall = yield queryClient.fetchQuery({
                queryFn: () => getLiveCallById(call.id),
            });
            updateSelectedCall(updatedLiveCall);
            setIsSaving(false);
            setOpen(false);
        }), child: renderCrmAccountForm(), disableSubmit: !enableSubmit || isSaving || fetchAccountMutation.isLoading, isSaving: isSaving }));
};
export default CallCrmAccountModal;
