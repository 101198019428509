var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import CallCrmObjectBaseModal, { CallCrmObjectLinkForm, CrmInputField, EditableCrmField, } from "./call_crm_object_base_modal";
import { SearchableDropdown } from "./searchable_dropdown";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getCrmAccounts, getCrmFields, getLiveCallById, setCallAccountAndOpportunity, updateCrmOpportunity, createCrmOpportunity, getCrmOpportunity, queryCrmObjects, fetchCrmObject, } from "../common/endpoints";
import { getCrmAccountName } from "./call_account_utils";
import { AsyncSearchSelect } from "../components/async_search_select";
const CallCrmOpportunityModal = ({ tenant, call, open, setOpen, updateSelectedCall, }) => {
    const [opportunitySelector, setOpportunitySelector] = useState("EXISTING");
    const [selectedExistingOpportunityId, setSelectedExistingOpportunityId] = useState(null);
    const [updatedOpportunityData, setUpdatedOpportunityData] = useState({});
    const [isSaving, setIsSaving] = useState(false);
    const { data: crmAccounts, isLoading: crmAccountsLoading, isError: crmAccountsError, } = useQuery({
        queryKey: ["crmAccounts", tenant.id],
        queryFn: getCrmAccounts,
    });
    const crmAccountsById = useMemo(() => {
        if (!crmAccounts)
            return {};
        return crmAccounts.reduce((acc, account) => {
            acc[account.id] = account;
            return acc;
        }, {});
    }, [crmAccounts]);
    const { data: selectedOpportunity, isLoading: crmOpportunityLoading, isError: crmOpportunityError, } = useQuery({
        queryKey: ["getCrmOpportunity", selectedExistingOpportunityId],
        queryFn: () => {
            if (!selectedExistingOpportunityId)
                return;
            return getCrmOpportunity(selectedExistingOpportunityId);
        },
        enabled: !!selectedExistingOpportunityId,
    });
    const { data: crmFields, isLoading: crmFieldsLoading, isError: crmFieldsError, } = useQuery({
        queryKey: ["crmFields", tenant.id],
        queryFn: getCrmFields,
    });
    const queryClient = useQueryClient();
    const opportunityUpdateMutation = useMutation({
        mutationFn: ({ opportunityId, opportunityData, }) => updateCrmOpportunity(opportunityId, opportunityData.name, opportunityData.accountId),
    });
    const opportunityCreateMutation = useMutation({
        mutationFn: ({ opportunityData }) => {
            var _a;
            return createCrmOpportunity(opportunityData.name, opportunityData.accountId, (_a = opportunityData.crmFieldValues) !== null && _a !== void 0 ? _a : {});
        },
    });
    const requiredCrmFields = useMemo(() => {
        if (crmFieldsLoading || crmFieldsError)
            return [];
        if (!crmFields)
            return [];
        return crmFields
            .filter((crmField) => crmField.applicable_objects.includes("Opportunity"))
            .filter((crmField) => !crmField.nullable);
    }, [crmFieldsLoading, crmFieldsError, crmFields]);
    useEffect(() => {
        setOpportunitySelector("EXISTING");
        setSelectedExistingOpportunityId(call.opportunity ? call.opportunity.id : null);
    }, [open, call.id]);
    useEffect(() => {
        if (!selectedOpportunity) {
            setUpdatedOpportunityData({
                accountId: call.account && call.account.id in crmAccountsById
                    ? call.account.id
                    : undefined,
            });
            return;
        }
        setUpdatedOpportunityData({
            name: selectedOpportunity.opportunity_name,
            accountId: selectedOpportunity.account_id in crmAccountsById
                ? selectedOpportunity.account_id
                : undefined,
        });
    }, [selectedOpportunity, crmAccountsById]);
    const opportunitiesFetcher = (userQuery, cursor) => __awaiter(void 0, void 0, void 0, function* () {
        const accountsQueryResponse = yield queryClient.fetchQuery({
            queryFn: () => queryCrmObjects("Opportunity", 
            /* fieldToQuery= */ "name", userQuery, cursor),
        });
        return {
            results: accountsQueryResponse.results.map((account) => {
                return { label: account.name, value: account.id };
            }),
            next_cursor: accountsQueryResponse.next_cursor,
        };
    });
    const fetchOpportunityMutation = useMutation({
        mutationFn: ({ opportunityId }) => fetchCrmObject("Opportunity", opportunityId),
        onSuccess: ({ id }) => {
            setSelectedExistingOpportunityId(id);
        },
    });
    const renderCrmOpportunityForm = () => {
        return (_jsx(CallCrmObjectLinkForm, { objectSelector: opportunitySelector, setObjectSelector: (option) => {
                setOpportunitySelector(option);
                setSelectedExistingOpportunityId(null);
            }, existingObjectForm: _jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: "flex flex-col items-start gap-1 self-stretch" }, { children: [_jsx("span", Object.assign({ className: "text-wds-gray-5 text-xs font-medium" }, { children: "Opportunity" })), _jsx(AsyncSearchSelect, { dataFetcher: opportunitiesFetcher, onSelect: (option) => {
                                    if (!option)
                                        return;
                                    fetchOpportunityMutation.mutateAsync({
                                        opportunityId: option.value,
                                    });
                                }, placeholder: "No opportunity mapped", selectedOption: selectedOpportunity
                                    ? {
                                        label: selectedOpportunity.opportunity_name,
                                        value: `${selectedOpportunity.id}`,
                                    }
                                    : undefined })] })), fetchOpportunityMutation.isLoading && (_jsx("span", { children: "Syncing opportunity..." })), !fetchOpportunityMutation.isLoading &&
                        selectedExistingOpportunityId &&
                        selectedOpportunity && (_jsx("div", Object.assign({ className: "flex flex-col gap-4" }, { children: _jsx(EditableCrmField, { fieldLabel: "Opportunity name", fieldValue: selectedOpportunity.opportunity_name, onValueChange: (newValue) => {
                                setUpdatedOpportunityData((prev) => {
                                    return Object.assign(Object.assign({}, prev), { name: newValue });
                                });
                            } }) })))] }), newObjectForm: _jsxs("div", Object.assign({ className: "flex flex-col gap-4 items-start" }, { children: [_jsx(CrmInputField, { fieldId: 0, fieldLabel: "Opportunity name", fieldValue: updatedOpportunityData.name, onValueChange: (value) => {
                            setUpdatedOpportunityData((prev) => {
                                return Object.assign(Object.assign({}, prev), { name: value });
                            });
                        }, placeholder: "Enter a name for this opportunity" }, "name"), _jsxs("div", Object.assign({ className: "flex flex-col items-start gap-1 self-stretch" }, { children: [_jsx("span", Object.assign({ className: "text-xs text-wds-gray-5 font-medium" }, { children: "Account" })), _jsx(SearchableDropdown, { options: crmAccounts
                                    ? crmAccounts.map((crmAccount) => {
                                        return {
                                            label: getCrmAccountName(crmAccount),
                                            value: `${crmAccount.id}`,
                                        };
                                    })
                                    : [], onSelect: (option) => {
                                    setUpdatedOpportunityData((prev) => {
                                        return Object.assign(Object.assign({}, prev), { accountId: option ? parseInt(option.value) : undefined });
                                    });
                                }, placeholder: "Linked account for this opportunity", selectedOption: updatedOpportunityData.accountId
                                    ? {
                                        value: `${updatedOpportunityData.accountId}`,
                                        label: getCrmAccountName(crmAccountsById[updatedOpportunityData.accountId]),
                                    }
                                    : undefined })] })), requiredCrmFields.map((crmField) => (_jsx(CrmInputField, { fieldId: crmField.id, fieldSchema: crmField.content_type_definition, fieldLabel: crmField.name, fieldValue: updatedOpportunityData.crmFieldValues
                            ? updatedOpportunityData.crmFieldValues[crmField.id]
                            : undefined, onValueChange: (value) => {
                            setUpdatedOpportunityData((prev) => {
                                return Object.assign(Object.assign({}, prev), { crmFieldValues: Object.assign(Object.assign({}, prev.crmFieldValues), { [crmField.id]: value }) });
                            });
                        } }, crmField.id)))] })) }));
    };
    const enableSubmit = useMemo(() => {
        const isFieldValid = (field) => !!field && field.length > 0;
        const isOpportunityDataValid = (opportunityData) => {
            return isFieldValid(opportunityData.name);
        };
        const allRequiredFieldsFilled = (opportunityData) => {
            if (!isFieldValid(opportunityData.name)) {
                return false;
            }
            if (!opportunityData.accountId)
                return false;
            if (requiredCrmFields.length === 0) {
                return true;
            }
            if (!opportunityData.crmFieldValues) {
                return false;
            }
            return requiredCrmFields.every((crmField) => isFieldValid(opportunityData.crmFieldValues[crmField.id]));
        };
        switch (opportunitySelector) {
            case "EXISTING":
                return (selectedExistingOpportunityId !== null &&
                    isOpportunityDataValid(updatedOpportunityData));
            case "NEW":
                return allRequiredFieldsFilled(updatedOpportunityData);
        }
    }, [
        opportunitySelector,
        selectedExistingOpportunityId,
        updatedOpportunityData,
        requiredCrmFields,
    ]);
    if (crmAccountsLoading ||
        crmAccountsError ||
        !crmAccounts ||
        crmFieldsLoading ||
        crmFieldsError ||
        !crmFields ||
        crmOpportunityLoading ||
        crmOpportunityError) {
        // No loading/error state UI
        return null;
    }
    return (_jsx(CallCrmObjectBaseModal, { tenant: tenant, title: "Change opportunity mapping", description: "Changing the mapped opportunity will affect all of the mappings for all activities related to this call.", open: open, setOpen: (value) => {
            if (!value) {
                // Reset the internal state of the modal.
                setOpportunitySelector("EXISTING");
                setSelectedExistingOpportunityId(null);
                setUpdatedOpportunityData({});
            }
            setOpen(value);
        }, submitCallback: (_) => __awaiter(void 0, void 0, void 0, function* () {
            setIsSaving(true);
            const opportunityResponse = opportunitySelector === "EXISTING"
                ? yield opportunityUpdateMutation.mutateAsync({
                    opportunityId: selectedExistingOpportunityId,
                    opportunityData: updatedOpportunityData,
                })
                : yield opportunityCreateMutation.mutateAsync({
                    opportunityData: updatedOpportunityData,
                });
            yield setCallAccountAndOpportunity(call.id, opportunityResponse.account_id, opportunityResponse.id);
            const updatedLiveCall = yield queryClient.fetchQuery({
                queryFn: () => getLiveCallById(call.id),
            });
            updateSelectedCall(updatedLiveCall);
            setIsSaving(false);
            setOpen(false);
        }), child: renderCrmOpportunityForm(), disableSubmit: !enableSubmit || isSaving || fetchOpportunityMutation.isLoading, isSaving: isSaving }));
};
export default CallCrmOpportunityModal;
