import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from "react-query";
import AccountPopoverV2 from "../common/account_popover_v2";
import ContactsPopoverV2 from "../common/contacts_popover_v2";
import OpportunityPopover from "../common/opportunity_popover";
import CallTimeDisplay from "../home/call_time_display";
import { getActiveTenant } from "../common/endpoints";
export const CallDetailsChips = ({ call, updateCall, }) => {
    const { data: tenant } = useQuery({
        queryKey: ["activeTenant", call.id],
        queryFn: getActiveTenant,
    });
    return (_jsxs("div", Object.assign({ className: "flex justify-start items-center gap-2 flex-wrap py-2" }, { children: [_jsx(CallTimeDisplay, { call: call, showDate: true, className: "text-wds-gray-6", showClockIcon: true, containerClassName: "flex py-[2px] items-center space-x-2 rounded-lg border border-wds-gray-3 bg-wds-gray-1" }), tenant && (_jsxs(_Fragment, { children: [_jsx(ContactsPopoverV2, { tenant: tenant, call: call, updateSelectedCall: updateCall }), _jsx(AccountPopoverV2, { tenant: tenant, call: call, updateSelectedCall: updateCall }), _jsx(OpportunityPopover, { tenant: tenant, call: call, updateSelectedCall: updateCall })] }))] })));
};
