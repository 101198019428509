import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from "react";
import BaseModal from "../base_modal";
import SelectionTable from "../selection_table";
import { useNavigate } from "react-router-dom";
const AddBriefingBlockModal = (props) => {
    const { open, onClose, onAddBriefingBlocks, allBlocks, existingBlockIds, createCallback, linkToBlock = false, } = props;
    const [selectedBlocks, setSelectedBlocks] = useState([]);
    const navigate = useNavigate();
    const expandCallback = (block) => {
        navigate("/research", {
            state: { editingBlockId: block.id, templateId: block.template_id },
        });
    };
    return (_jsx(BaseModal, Object.assign({ title: "Add briefing block", description: "A briefing block represents a single block of information that will be included in the pre-call briefing.", open: open, setOpen: onClose, fields: [], submitCallback: () => {
            onAddBriefingBlocks(selectedBlocks.map((block) => block.id));
        }, submitText: "Add" }, { children: _jsx(SelectionTable, { columns: [
                {
                    accessorKey: "name",
                    header: "Name",
                },
                {
                    accessorKey: "prompt",
                    header: "Prompt",
                    cell: ({ row }) => {
                        if (row.original.prompt.length > 45) {
                            return row.original.prompt.slice(0, 45) + "...";
                        }
                        return row.original.prompt;
                    },
                },
            ], rows: allBlocks, setSelectedRows: setSelectedBlocks, canSelectRow: (row) => !existingBlockIds.includes(row.id), createCallback: createCallback, createLabel: "Create new briefing block", expandCallback: linkToBlock ? expandCallback : undefined }) })));
};
export default AddBriefingBlockModal;
