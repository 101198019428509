var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useEffect, useMemo, useState } from "react";
import BaseTable from "./base_table";
import axios from "axios";
import BaseModal from "./base_modal";
import { getCookie } from "./cookies";
import { showErrorModal } from "./error_modal";
import usePagination from "./pagination/use_pagination";
import TenantPicker from "./tenant_picker";
import * as endpoints from "./common/endpoints";
import ArrowUpTrayIcon from "@heroicons/react/24/outline/ArrowUpTrayIcon";
import { UserContext } from "./App";
export default function TenantTable() {
    var _a;
    const { userIsStaff } = useContext(UserContext);
    const [rows, setRows] = useState([]);
    const [addTenantOpen, setAddTenantOpen] = useState(false);
    const [editingTenantOpen, setEditingTenantOpen] = useState(false);
    const [editingTenant, setEditingTenant] = useState({
        cells: [],
        id: -1,
    });
    const [tenants, setTenants] = useState([]);
    // Map containing tenant id to playbook details
    const [tenantToPlaybooksMap, setTenantToPlaybooksMap] = useState(new Map());
    const [isExporting, setIsExporting] = useState(false);
    const [uploaded, setUploaded] = useState(false);
    const [fileUrl, setFileUrl] = useState();
    const [importFileUrl, setImportFileurl] = useState();
    const [importModalOpen, setImportModalOpen] = useState(false);
    const [fileImportUrl, setFileImportUrl] = useState({
        cells: [],
        id: -1,
    });
    const [replaceExisting, setReplaceExisting] = useState(false);
    const [backupEnabled, setBackupEnabled] = useState(false);
    const [isImporting, setIsImporting] = useState(false);
    const [importUrl, setImportUrl] = useState();
    const paginationStateControl = usePagination();
    useEffect(() => {
        tenants.forEach((tenant) => {
            endpoints.getPlaybooks(tenant.id).then((playbooks) => {
                setTenantToPlaybooksMap((prev) => {
                    prev.set(tenant.id, playbooks);
                    return new Map(prev);
                });
            });
        });
    }, [tenants]);
    useEffect(() => {
        if (rows.length === 0)
            return;
        setRows((prevRows) => {
            return prevRows.map((row) => {
                const tenant = tenants.find((t) => t.id === row.id);
                if (!tenant || !tenant.default_playbook_id)
                    return row;
                return Object.assign(Object.assign({}, row), { cells: [
                        ...row.cells.slice(0, 7),
                        getPlaybookName(tenant.id, tenant.default_playbook_id),
                        tenant.default_chat_source_live_call,
                    ] });
            });
        });
    }, [tenantToPlaybooksMap]);
    const getPlaybookName = (tenantId, playbookId) => {
        var _a, _b, _c;
        return ((_c = (_b = (_a = tenantToPlaybooksMap.get(tenantId)) === null || _a === void 0 ? void 0 : _a.find((p) => p.id === playbookId)) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : "");
    };
    let lookupTenants = () => {
        axios
            .get("/api/get_tenants_paginated", {
            params: {
                page: paginationStateControl.currentPage,
                page_size: paginationStateControl.pageSize,
            },
            headers: {
                Authorization: localStorage.getItem("wiser_jwt"),
            },
        })
            .catch(function (error) {
            showErrorModal(error);
        })
            .then((res) => {
            var _a, _b;
            let tenants = res.data.data;
            let newRows = [];
            for (let i = 0; i < tenants.length; i++) {
                newRows.push({
                    id: tenants[i].id,
                    cells: [
                        tenants[i].name,
                        tenants[i].description,
                        (_a = tenants[i].domain_name) !== null && _a !== void 0 ? _a : "",
                        tenants[i].transcription_keywords.join(", "),
                        tenants[i].timestamp,
                        tenants[i].call_recording_deletion_delay_days,
                        ((_b = tenants[i].secondary_domains) === null || _b === void 0 ? void 0 : _b.toString()) || "",
                        getPlaybookName(tenants[i].id, tenants[i].default_playbook_id),
                        tenants[i].default_chat_source_live_call,
                    ],
                });
            }
            setRows(newRows);
            setTenants(tenants);
            paginationStateControl.setTotalRows(res.data.total);
        });
    };
    useEffect(() => {
        lookupTenants();
    }, [paginationStateControl.currentPage]);
    let createTenant = (name, description, domain_name, transcription_keywords = [], tenantIdToCloneFrom, callRecordingDeletionDelayDays, secondaryDomainsRegex) => {
        axios
            .post("/api/create_tenant", {
            name: name,
            description: description,
            domain_name: domain_name,
            transcription_keywords: transcription_keywords,
            import_tenant_id: tenantIdToCloneFrom,
            call_recording_deletion_delay_days: callRecordingDeletionDelayDays,
            secondary_domains_regex: secondaryDomainsRegex,
        }, {
            headers: {
                Authorization: localStorage.getItem("wiser_jwt"),
                "X-CSRFToken": getCookie("csrftoken"),
            },
        })
            .then((res) => {
            lookupTenants();
            setAddTenantOpen(false);
        });
    };
    let editTenant = (id, name, description, domain_name, transcription_keywords = [], defaultPlaybookId, callRecordingDeletionDelayDays, secondaryDomainsRegex, defaultChatSourceForLiveCall) => {
        axios
            .post("/api/update_tenant", {
            id: id,
            name: name,
            description: description,
            domain_name: domain_name,
            transcription_keywords: transcription_keywords,
            default_playbook_id: defaultPlaybookId,
            call_recording_deletion_delay_days: callRecordingDeletionDelayDays,
            secondary_domains_regex: secondaryDomainsRegex,
            default_chat_source_live_call: defaultChatSourceForLiveCall,
        }, {
            headers: {
                Authorization: localStorage.getItem("wiser_jwt"),
                "X-CSRFToken": getCookie("csrftoken"),
            },
        })
            .then((res) => {
            lookupTenants();
            setEditingTenantOpen(false);
        });
    };
    let deleteTenant = (id) => {
        axios
            .post("/api/delete_tenant", {
            id: id,
        }, {
            headers: {
                "X-CSRFToken": getCookie("csrftoken"),
                Authorization: localStorage.getItem("wiser_jwt"),
            },
        })
            .then((res) => {
            lookupTenants();
        });
    };
    const defaultPlaybookOptions = useMemo(() => {
        if (!tenantToPlaybooksMap.has(editingTenant.id)) {
            return [];
        }
        return tenantToPlaybooksMap.get(editingTenant.id).map((playbook) => ({
            value: playbook.id.toString(),
            label: playbook.name,
        }));
    }, [tenantToPlaybooksMap, editingTenant]);
    const defaultPlaybookValue = useMemo(() => {
        var _a;
        const currentValue = (_a = tenants.find((t) => t.id === editingTenant.id)) === null || _a === void 0 ? void 0 : _a.default_playbook_id;
        if (currentValue) {
            return currentValue.toString();
        }
        if (!defaultPlaybookOptions.length) {
            return null;
        }
        return defaultPlaybookOptions[0].value.toString();
    }, [defaultPlaybookOptions]);
    const handleExportClick = () => __awaiter(this, void 0, void 0, function* () {
        setIsExporting(true);
        const response = yield endpoints.exportTenant();
        setFileUrl(response.url);
        setUploaded(true);
        setIsExporting(false);
    });
    const handleImportClick = () => __awaiter(this, void 0, void 0, function* () {
        setIsImporting(true);
        if (backupEnabled) {
            if (importFileUrl) {
                navigator.clipboard.writeText(importFileUrl);
                setIsImporting(false);
                setImportModalOpen(false);
                setImportFileurl(undefined);
                return;
            }
            const response = yield endpoints.exportTenant();
            setImportFileurl(response.url);
        }
        if (importUrl) {
            yield endpoints.importTenant(importUrl, replaceExisting);
        }
        setIsImporting(false);
        setImportModalOpen(false);
    });
    const openImportModal = () => {
        setImportModalOpen(true);
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: "float-right mr-9 mt-11 ml-6" }, { children: _jsx(TenantPicker, {}) })), _jsx(BaseTable, { title: "Tenants", description: "Currently registered customers", addName: "Add Tenant", addCallback: () => {
                    setAddTenantOpen(true);
                }, columnNames: [
                    "Name",
                    "Description",
                    "Company Domain Name",
                    "Keywords",
                    "Last Updated",
                    "Auto delete call recording delay (0, means disabled, may take 1 day to take effect)",
                    "Secondary domains regex",
                    "Default Playbook",
                    "Default chat source for live call",
                ], rows: rows, paginationStateControl: paginationStateControl, editCallback: (tenant) => {
                    setEditingTenant(tenant);
                    setEditingTenantOpen(true);
                }, deleteCallback: (tenant) => {
                    deleteTenant(tenant.id);
                }, tableActions: [
                    {
                        label: (_jsxs("div", Object.assign({ className: "flex flex-row gap-1" }, { children: [isExporting ? "Exporting..." : "Export current tenant", !isExporting && _jsx(ArrowUpTrayIcon, { className: "w-5 h-5" })] }))),
                        buttonDisabled: isExporting || !userIsStaff,
                        callback: handleExportClick,
                    },
                    {
                        label: (_jsx("div", Object.assign({ className: "flex flex-row gap-1" }, { children: isImporting ? "Importing..." : "Import" }))),
                        buttonDisabled: isImporting || !userIsStaff,
                        callback: openImportModal,
                    },
                ] }), _jsx(BaseModal, { title: "Add Tenant", description: "A tenant is a customer of Wiser.", open: addTenantOpen, setOpen: setAddTenantOpen, submitCallback: (fields) => {
                    let name = "";
                    let description = "";
                    let domainName = "";
                    let transcriptionKeywords = [];
                    let tenantIdToCloneFrom = "";
                    let callRecordingDeletionDelayDays = 0;
                    let secondaryDomainsRegex = "";
                    for (let i = 0; i < fields.length; i++) {
                        if (fields[i].name === "Name")
                            name = fields[i].value;
                        if (fields[i].name === "Description")
                            description = fields[i].value;
                        if (fields[i].name === "Company Domain Name")
                            domainName = fields[i].value;
                        if (fields[i].name === "Transcription Keywords") {
                            transcriptionKeywords = fields[i].value;
                        }
                        if (fields[i].name === "Tenant ID to clone from") {
                            tenantIdToCloneFrom = fields[i].value;
                        }
                        if (fields[i].name ===
                            "Auto delete call recording delay (0, means disabled, may take 1 day to take effect)") {
                            callRecordingDeletionDelayDays = fields[i].value;
                        }
                        if (fields[i].name === "Secondary domains regex") {
                            secondaryDomainsRegex = fields[i].value;
                        }
                    }
                    createTenant(name, description, domainName, transcriptionKeywords, tenantIdToCloneFrom, callRecordingDeletionDelayDays, secondaryDomainsRegex);
                }, fields: [
                    {
                        name: "Name",
                        type: "text",
                        value: "",
                        required: true,
                        placeholder: "Name of this tenant",
                    },
                    {
                        name: "Description",
                        type: "textarea",
                        value: "",
                        placeholder: "Description of this tenant",
                        required: true,
                    },
                    {
                        name: "Company Domain Name",
                        type: "text",
                        value: "",
                        placeholder: "Company domain name",
                        required: true,
                    },
                    {
                        name: "Transcription Keywords",
                        type: "stringlist",
                        value: [],
                        placeholder: "Transcription keywords",
                    },
                    {
                        name: "Tenant ID to clone from",
                        type: "text",
                        value: "",
                        placeholder: "Tenant ID to clone from",
                    },
                    {
                        name: "Auto delete call recording delay (0, means disabled, may take 1 day to take effect)",
                        type: "number",
                        value: 0,
                    },
                    {
                        name: "Secondary domains regex",
                        type: "text",
                        value: "",
                        placeholder: "Secondary domains regex comma separated",
                    },
                ], submitText: "Create Tenant" }), _jsx(BaseModal, { title: "Edit Tenant", description: "Edit the existing tenant called " + (editingTenant === null || editingTenant === void 0 ? void 0 : editingTenant.cells[0]), open: editingTenantOpen, setOpen: setEditingTenantOpen, submitCallback: (fields) => {
                    let name = "";
                    let description = "";
                    let domainName = "";
                    let transcriptionKeywords = [];
                    let defaultPlaybookId = -1;
                    let callRecordingDeletionDelayDays = 0;
                    let secondaryDomainsRegex = "";
                    let defaultChatSourceForLiveCall = "knowledge_base";
                    for (let i = 0; i < fields.length; i++) {
                        if (fields[i].name === "Name")
                            name = fields[i].value;
                        if (fields[i].name === "Description")
                            description = fields[i].value;
                        if (fields[i].name === "Company Domain Name")
                            domainName = fields[i].value;
                        if (fields[i].name === "Transcription Keywords") {
                            transcriptionKeywords = fields[i].value;
                        }
                        if (fields[i].name === "Default Playbook") {
                            defaultPlaybookId = Number(fields[i].value);
                        }
                        if (fields[i].name ===
                            "Auto delete call recording delay (0, means disabled, may take 1 day to take effect)") {
                            callRecordingDeletionDelayDays = fields[i].value;
                        }
                        if (fields[i].name === "Secondary domains regex") {
                            secondaryDomainsRegex = fields[i].value;
                        }
                        if (fields[i].name === "Default chat source for live call") {
                            defaultChatSourceForLiveCall = fields[i].value;
                        }
                    }
                    editTenant(editingTenant.id, name, description, domainName, transcriptionKeywords, defaultPlaybookId, callRecordingDeletionDelayDays, secondaryDomainsRegex, defaultChatSourceForLiveCall);
                }, fields: [
                    {
                        name: "Name",
                        value: editingTenant.cells[0],
                        type: "text",
                        required: true,
                        placeholder: "Name of this tenant",
                    },
                    {
                        name: "Description",
                        value: editingTenant.cells[1],
                        type: "textarea",
                        required: true,
                        placeholder: "Description of this tenant",
                    },
                    {
                        name: "Company Domain Name",
                        value: editingTenant.cells[2],
                        type: "text",
                        required: true,
                        placeholder: "Company domain name",
                    },
                    {
                        name: "Transcription Keywords",
                        type: "stringlist",
                        value: (_a = editingTenant.cells[3]) === null || _a === void 0 ? void 0 : _a.split(", ").filter((str) => str),
                        placeholder: "Transcription keywords",
                    },
                    {
                        name: "Default Playbook",
                        type: "select",
                        value: defaultPlaybookValue,
                        options: defaultPlaybookOptions,
                        placeholder: "Default playbook",
                    },
                    {
                        name: "Auto delete call recording delay (0, means disabled, may take 1 day to take effect)",
                        type: "number",
                        value: editingTenant.cells[5],
                    },
                    {
                        name: "Secondary domains regex",
                        type: "text",
                        value: editingTenant.cells[6],
                        placeholder: "Secondary domains regex comma separated",
                    },
                    {
                        name: "Default chat source for live call",
                        type: "select",
                        value: editingTenant.cells[8],
                        options: [
                            { value: "knowledge_base", label: "Knowledge Base" },
                            { value: "route_transcript", label: "Transcript" },
                            { value: "call_research", label: "Call Research" },
                            {
                                value: "past_call_transcripts",
                                label: "Past Call Transcripts",
                            },
                        ],
                    },
                ], submitText: "Save Changes" }), _jsx(BaseModal, { title: "Tenant Exported successfully", description: "File has been uploaded to GCS, please copy the URL", open: uploaded && !isExporting, setOpen: setUploaded, submitText: "Copy URL to clipboard", submitCallback: (fields) => {
                    if (fileUrl) {
                        navigator.clipboard.writeText(fileUrl);
                    }
                    setUploaded(false);
                }, fields: [] }), _jsx(BaseModal, { title: "Import Tenant", description: fileUrl && backupEnabled
                    ? "Backup completed, now importing"
                    : "Enter URL for file import", open: importModalOpen || (!!importFileUrl && backupEnabled), setOpen: setImportModalOpen, submitText: isImporting
                    ? "Importing..."
                    : !!importFileUrl && backupEnabled
                        ? "Copy URL to Clipboard"
                        : "Import tenant", disableSubmit: isImporting, submitCallback: (fields) => {
                    handleImportClick();
                }, fields: [
                    {
                        name: "URL",
                        value: fileImportUrl.cells[0],
                        type: "text",
                        placeholder: "URL",
                        required: true,
                    },
                    {
                        name: "Replace existing (Warning: Call data may be deleted)",
                        type: "checkbox",
                        value: replaceExisting,
                    },
                    {
                        name: "Backup current tenant before import",
                        type: "checkbox",
                        value: backupEnabled,
                    },
                ], setField: (fieldName, value) => {
                    if (fieldName === "Replace existing (Warning: Call data may be deleted)") {
                        setReplaceExisting(value);
                    }
                    else if (fieldName === "URL") {
                        setImportUrl(value);
                    }
                    else if (fieldName === "Backup current tenant before import") {
                        setBackupEnabled(value);
                    }
                } })] }));
}
