import { useQuery } from "react-query";
import { queryLiveCalls } from "../../common/endpoints";
export const useAccountCalls = ({ accountId }) => {
    const jsonQuery = {
        table: "LiveCall",
        order_by_fields: ["call_time", "id"],
        structured_filter: {
            where_condition: {
                operator: "AND",
                subconditions: [
                    {
                        where_condition: {
                            field: "account_id",
                            operator: "EQUALS",
                            value: accountId,
                        },
                    },
                ],
            },
        },
    };
    const { data: queryCallsResponse, isLoading: callsLoading, isError: callsError, } = useQuery({
        queryKey: ["callsByAccount", accountId],
        // We assume that the total number of calls per account do not exceed
        // the per page limit (which is 100 defined in the client).
        queryFn: () => queryLiveCalls(JSON.stringify(jsonQuery)),
        staleTime: Infinity,
    });
    return {
        queryCallsResponse,
        callsLoading,
        callsError,
    };
};
