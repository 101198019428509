var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// This page shows the transcript, recording, and notes for a completed call.
import { useEffect, useMemo, useRef, useState } from "react";
import { formatCallTime } from "./strings";
import { useParams } from "react-router-dom";
import SearchSelect from "./search_select";
import { createEvalExample, deleteEvalExample, getCardNames, getEvalExamples, } from "./common/endpoints";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import classNames from "./class_names";
// Taken from eval/botcity/call_example.py
const CARD_TYPE_ID = 1;
const NOTE_TYPE_ID = 2;
const TRANSCRIPT_TYPE_ID = 4;
export default function EvalLabeler(props) {
    var _a, _b, _c, _d, _e;
    const { callIdParam } = useParams();
    const callId = Number(callIdParam);
    const [labelerDataset, setLabelerDataset] = useState("");
    const [labelType, setLabelType] = useState(CARD_TYPE_ID);
    const [labelPlaybookItemId, setLabelPlaybookItemId] = useState(0);
    const [noteLabelContent, setNoteLabelContent] = useState("");
    const [labelTranscript, setLabelTranscript] = useState("");
    const [allCards, setAllCards] = useState([]);
    const [labelCardId, setLabelCardId] = useState(0);
    const [currentExamples, setCurrentExamples] = useState([]);
    const [showTypePicker, setShowTypePicker] = useState(true);
    const [showIntentPicker, setShowIntentPicker] = useState(true);
    const updateExamples = () => __awaiter(this, void 0, void 0, function* () {
        const examples = yield getEvalExamples(callId);
        setCurrentExamples(examples.sort((a, b) => a.timestamp - b.timestamp));
    });
    useEffect(() => {
        getCardNames().then((cards) => {
            setAllCards(cards);
        });
        updateExamples();
    }, []);
    const createExample = () => __awaiter(this, void 0, void 0, function* () {
        var _f;
        let expectation;
        switch (labelType) {
            case CARD_TYPE_ID:
                expectation = JSON.stringify({
                    card_id: labelCardId,
                    card_name: (_f = allCards.find((card) => card.id === labelCardId)) === null || _f === void 0 ? void 0 : _f.name,
                    timestamp: props.currentTimestamp,
                });
                break;
            case NOTE_TYPE_ID:
                expectation = JSON.stringify({
                    playbook_item_id: labelPlaybookItemId,
                    note_name: props.playbookNotes.find((note) => {
                        return note.playbook_item_id === labelPlaybookItemId;
                    }).question,
                    content: noteLabelContent,
                    timestamp: props.currentTimestamp,
                });
                break;
            case TRANSCRIPT_TYPE_ID:
                expectation = JSON.stringify({
                    transcript: labelTranscript,
                    startTimestamp: props.currentTimestamp,
                    endTimestamp: props.currentTranscriptEndTime,
                    timestamp: props.currentTimestamp,
                });
                break;
            default:
                throw new Error("Unknown label type");
        }
        yield createEvalExample(labelerDataset, callId, props.currentTimestamp, expectation, labelType.toString());
        yield updateExamples();
        // TODO: show a spinner or something (this is ~instant right now)
    });
    const deleteExample = (exampleId) => __awaiter(this, void 0, void 0, function* () {
        yield deleteEvalExample(callId, exampleId);
        yield updateExamples();
    });
    const scrollRef = useRef(null);
    let scrollId = 0;
    for (let i = 0; i < currentExamples.length; i++) {
        if (currentExamples[i].timestamp === props.currentTimestamp) {
            scrollId = currentExamples[i].id;
            break;
        }
    }
    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "start",
            });
        }
    }, [props.currentTimestamp]);
    useEffect(() => {
        if (labelType > 0) {
            setShowTypePicker(false);
        }
    }, [labelType]);
    useEffect(() => {
        if (labelCardId > 0) {
            setShowIntentPicker(false);
        }
    }, [labelCardId]);
    useEffect(() => {
        if (labelPlaybookItemId > 0) {
            setShowIntentPicker(false);
        }
    }, [labelPlaybookItemId]);
    // This gives a short description of the selection for after the search/select
    // is selected and closed.
    let selectedIntent = "";
    if (labelType === CARD_TYPE_ID) {
        selectedIntent =
            (_b = (_a = allCards.find((card) => card.id === labelCardId)) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : "";
    }
    else if (labelType === NOTE_TYPE_ID) {
        selectedIntent =
            (_d = (_c = props.playbookNotes.find((note) => note.playbook_item_id === labelPlaybookItemId)) === null || _c === void 0 ? void 0 : _c.question) !== null && _d !== void 0 ? _d : "";
    }
    const labelName = useMemo(() => {
        switch (labelType) {
            case CARD_TYPE_ID:
                return "Card";
            case NOTE_TYPE_ID:
                return "Note";
            case TRANSCRIPT_TYPE_ID:
                return "Transcript";
        }
        return "Unknown";
    }, [labelType]);
    return (_jsxs("div", { children: [_jsx("h1", Object.assign({ className: "flex flex-row justify-between text-xl font-bold text-gray-900 " }, { children: _jsx("p", Object.assign({ className: "p-2" }, { children: "Eval Labeler" })) })), _jsx("div", Object.assign({ className: "flex flex-col max-h-[500px] overflow-y-scroll" }, { children: currentExamples.map((example) => (_jsxs("div", Object.assign({ className: classNames("flex flex-col border border-gray-300 rounded p-2 m-1", example.timestamp === props.currentTimestamp
                        ? "bg-green-100"
                        : ""), ref: example.id === scrollId ? scrollRef : null }, { children: [_jsxs("div", Object.assign({ className: "flex flex-row justify-between" }, { children: [_jsxs("p", Object.assign({ className: "font-bold" }, { children: ["Timestamp:", " ", formatCallTime(props.startTimestamp, example.timestamp)] })), _jsx("button", Object.assign({ onClick: () => {
                                        deleteExample(example.id);
                                    } }, { children: _jsx(TrashIcon, { className: "h-5 w-5 text-red-700" }) }))] })), _jsxs("p", Object.assign({ className: "font-bold" }, { children: ["Label Type: ", labelName] })), _jsxs("p", Object.assign({ className: "font-bold" }, { children: ["Expectation: ", example.expectation] }))] }), example.id))) })), _jsx("p", Object.assign({ className: "mt-4 font-bold" }, { children: "Dataset" })), _jsx("input", { type: "text", className: "shrink basis-0 p-2 border border-gray-300 rounded-lg", placeholder: "Dataset...", onChange: (e) => setLabelerDataset(e.target.value), value: labelerDataset }), _jsx("p", Object.assign({ className: "mt-4 font-bold" }, { children: "Label Type" })), showTypePicker && (_jsx(SearchSelect, { selectedId: labelType, selectId: setLabelType, options: [
                    {
                        id: CARD_TYPE_ID,
                        name: "Card",
                        description: "Content Card to show",
                    },
                    {
                        id: NOTE_TYPE_ID,
                        name: "Note",
                        description: "Note we should take",
                    },
                    {
                        id: TRANSCRIPT_TYPE_ID,
                        name: "Transcript",
                        description: "Transcript we should get",
                    },
                ] })), !showTypePicker && (_jsxs("div", Object.assign({ className: "flex flex-row" }, { children: [labelType === CARD_TYPE_ID && _jsx("p", { children: "Card" }), labelType === NOTE_TYPE_ID && _jsx("p", { children: "Note" }), labelType === TRANSCRIPT_TYPE_ID && _jsx("p", { children: "Transcript" }), _jsx("button", Object.assign({ onClick: () => setShowTypePicker(true) }, { children: _jsx(PencilIcon, { className: "ml-4 h-5 w-5 text-blue-500" }) }))] }))), _jsx("p", Object.assign({ className: "mt-4 font-bold" }, { children: "Expectation" })), labelType === CARD_TYPE_ID && showIntentPicker && (_jsx("div", { children: _jsx(SearchSelect, { selectedId: labelCardId, selectId: setLabelCardId, options: allCards.map((card) => ({
                        id: card.id,
                        name: card.name,
                        description: "",
                    })) }) })), !!!showIntentPicker && (_jsxs("div", { children: [_jsx("p", { children: selectedIntent }), _jsx("button", Object.assign({ onClick: () => setShowIntentPicker(true) }, { children: _jsx(PencilIcon, { className: "ml-4 h-5 w-5 text-blue-500" }) }))] })), labelType === NOTE_TYPE_ID && showIntentPicker && (_jsx(SearchSelect, { selectedId: labelPlaybookItemId, selectId: setLabelPlaybookItemId, options: (_e = props.playbookNotes.map((note) => ({
                    id: note.playbook_item_id,
                    name: note.question,
                    description: "",
                }))) !== null && _e !== void 0 ? _e : [] })), labelType === NOTE_TYPE_ID && labelPlaybookItemId > 0 && (_jsx("textarea", { className: "w-full basis-0 p-2 border border-gray-300 rounded-lg", placeholder: "Note content...", onChange: (e) => setNoteLabelContent(e.target.value), value: noteLabelContent })), labelType === TRANSCRIPT_TYPE_ID && (_jsx("textarea", { className: "w-full basis-0 p-2 border border-gray-300 rounded-lg", placeholder: "Transcript...", onChange: (e) => setLabelTranscript(e.target.value), value: labelTranscript })), _jsxs("button", Object.assign({ className: "bg-blue-500 disabled:bg-gray-300 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded", onClick: createExample, disabled: !(labelerDataset.length > 0 &&
                    ((labelType === CARD_TYPE_ID && labelCardId > 0) ||
                        (labelType === NOTE_TYPE_ID &&
                            labelPlaybookItemId > 0 &&
                            noteLabelContent.length > 0) ||
                        (labelType === TRANSCRIPT_TYPE_ID && labelTranscript.length > 0))) }, { children: ["Create Label @", formatCallTime(props.startTimestamp, props.currentTimestamp)] }))] }));
}
