import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CalendarIcon, ClockIcon } from "@heroicons/react/24/outline";
import { formatTime } from "../strings";
import classNames from "../class_names";
/**
 *
 * CallTimeDisplay is a React component that displays the start and end time of a call.
 * It takes a LiveCall object as a prop and formats the call_time and call_end_time to a readable format.
 * If the call_end_time is null, it only displays the start time.
 * @param call: LiveCall instance
 * @returns
 */
const CallTimeDisplay = ({ call, showDate, showTime = true, className = "", callTimeClassNames = "", containerClassName = "", // custom styles for call and time pills
showCalendarIcon = true, showClockIcon = false, }) => {
    return (_jsxs(_Fragment, { children: [showDate && (_jsx("div", Object.assign({ className: containerClassName || undefined }, { children: _jsxs("div", Object.assign({ className: classNames("items-center text-wds-gray-6 font-lato leading-20 px-2 flex gap-2", className) }, { children: [showCalendarIcon && (_jsx(CalendarIcon, { className: "h-4 w-4 relative text-wds-gray-5" })), _jsx("span", { children: new Date(call.call_time).toLocaleDateString("en-US", {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                            }) })] })) }))), showTime && (_jsx("div", Object.assign({ className: containerClassName || undefined }, { children: _jsxs("div", Object.assign({ className: classNames("font-lato leading-20 px-2 flex gap-2 items-center", className, callTimeClassNames) }, { children: [showClockIcon && (_jsx(ClockIcon, { className: "h-4 w-4 relative text-wds-gray-5" })), call.call_end_time
                            ? `${formatTime(new Date(call.call_time))} - ${formatTime(new Date(call.call_end_time))}`
                            : formatTime(new Date(call.call_time))] })) })))] }));
};
export default CallTimeDisplay;
