var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import ConnectionStatus from "./connection_status";
import { useEffect, useState } from "react";
const IntegrationModal = ({ name, icon, shortDescription, longDescription, capabilities, connectButtonLogo, connectButtonLabel, isModalOpen, closeModal, disableCloseButton, connectionStatus: connectionStatus, connectIntegration, disconnectIntegration, children = null, }) => {
    const connected = connectionStatus === "Connected";
    const [isConnecting, setIsConnecting] = useState(false);
    useEffect(() => {
        if (connectionStatus === "Connected") {
            setIsConnecting(false);
        }
    }, [connectionStatus]);
    const renderConnectButton = () => {
        return (_jsxs(_Fragment, { children: [connectButtonLogo && (_jsx("img", { src: connectButtonLogo, alt: "connect button logo", className: "h-6 w-6" })), _jsx("span", Object.assign({ className: "text-sm font-semibold text-zinc-800" }, { children: connectButtonLabel || "Connect Integration" }))] }));
    };
    return (_jsxs(Dialog, Object.assign({ open: isModalOpen, onClose: closeModal }, { children: [_jsx("div", { className: "fixed inset-0 bg-black/30", "aria-hidden": "true" }), _jsx("div", Object.assign({ className: "fixed inset-0 flex items-center justify-center p-4" }, { children: _jsxs(Dialog.Panel, Object.assign({ className: "relative bg-white rounded-lg border border-neutral-200" }, { children: [_jsx("div", { className: "absolute w-[1200px] left-[-960px] top-[-300px] bg-neutral-100 rounded-full z-0", "aria-hidden": "true" }), _jsxs("div", Object.assign({ className: "relative z-1" }, { children: [!disableCloseButton && (_jsx("button", Object.assign({ onClick: closeModal, className: "absolute top-0 right-0 mt-4 mr-4 p-2 rounded-full flex items-center gap-2 hover:bg-blue-100" }, { children: _jsx(XMarkIcon, { className: "h-5 w-5" }) }))), _jsxs("div", Object.assign({ className: "w-[720px] h-max justify-start items-start inline-flex px-4" }, { children: [_jsxs("div", Object.assign({ className: "w-60 p-4 py-8 flex-col justify-between items-start inline-flex" }, { children: [_jsxs("div", Object.assign({ className: "self-stretch h-[212px] flex-col justify-start items-start gap-4 flex" }, { children: [_jsx("img", { className: "w-[88px] h-[88px] bg-white rounded-lg border border-neutral-200", src: icon }), _jsxs("div", Object.assign({ className: "self-stretch h-[68px] flex-col justify-start items-start gap-1 flex" }, { children: [_jsx("div", Object.assign({ className: "text-base font-bold leading-normal" }, { children: name })), _jsx("div", Object.assign({ className: "self-stretch text-zinc-800 text-sm leading-tight" }, { children: shortDescription }))] })), _jsx(ConnectionStatus, { status: isConnecting ? "Connecting" : connectionStatus })] })), !connected && (_jsx("button", Object.assign({ className: "flex bg-white items-center justify-center p-2 gap-1 border border-black rounded-full hover:bg-blue-100", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                                        setIsConnecting(true);
                                                        yield connectIntegration();
                                                    }), type: "button", disabled: isConnecting }, { children: renderConnectButton() })))] })), _jsxs("div", Object.assign({ className: "w-[480px] self-stretch justify-between" }, { children: [_jsxs("div", Object.assign({ className: "self-stretch pl-4 pr-8 py-8 flex-col justify-start items-start gap-4 inline-flex" }, { children: [_jsx("div", Object.assign({ className: "self-stretch text-zinc-800 leading-snug" }, { children: longDescription })), (capabilities === null || capabilities === void 0 ? void 0 : capabilities.length) && (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: "self-stretch text-zinc-800 leading-snug" }, { children: ["Our ", name, " integration enables you to:"] })), _jsx("ul", Object.assign({ className: "list-disc list-inside text-zinc-800 leading-snug" }, { children: capabilities === null || capabilities === void 0 ? void 0 : capabilities.map((capability) => (_jsx("li", { children: capability }, capability))) }))] }))] })), children, connected && (_jsxs("div", Object.assign({ className: "w-[432px] h-[60px] p-4 justify-between items-center inline-flex" }, { children: [_jsx("div", Object.assign({ className: "flex-col justify-start items-start inline-flex" }, { children: _jsx("div", Object.assign({ className: "font-bold leading-snug" }, { children: "Disconnect integration" })) })), _jsx("button", Object.assign({ className: "px-4 py-2 bg-red-700 rounded-full justify-start items-center gap-2 flex text-white font-bold leading-snug hover:bg-red-900", onClick: disconnectIntegration }, { children: "Disconnect" }))] })))] }))] }))] }))] })) }))] })));
};
export default IntegrationModal;
