import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Badge from "../common/badge";
const CrmFieldValueRenderer = (props) => {
    var _a;
    switch (props.crmFieldSchema.type) {
        case "boolean":
            return (_jsx("div", Object.assign({ className: "flex flex-wrap" }, { children: _jsx(Badge, { text: props.crmFieldValue === "true" ? "True" : "False", classes: "bg-wds-blue-1", textClasses: "text-sm text-wds-blue-4 font-medium" }, props.crmObjectId) })));
        case "single_select":
            return (_jsx("div", Object.assign({ className: "flex flex-wrap" }, { children: props.crmFieldValue && (_jsx(Badge, { text: props.crmFieldValue, classes: "bg-wds-blue-1", textClasses: "text-sm text-wds-blue-4 font-medium" }, props.crmObjectId)) })));
        case "multi_select":
            return (_jsx("div", Object.assign({ className: "flex gap-1 flex-wrap" }, { children: (_a = props.crmFieldValue) === null || _a === void 0 ? void 0 : _a.split(";").filter((option) => option !== "").map((option) => (_jsx(Badge, { text: option, classes: "bg-wds-blue-1", textClasses: "text-sm text-wds-blue-4 font-medium" }, option))) })));
        case "number":
            return (_jsx("span", Object.assign({ className: "font-medium text-sm text-black" }, { children: isNaN(parseFloat(props.crmFieldValue))
                    ? null
                    : parseFloat(props.crmFieldValue).toLocaleString("en-US") })));
        case "date":
            return (_jsx("span", Object.assign({ className: "font-medium text-sm text-black" }, { children: isNaN(parseInt(props.crmFieldValue))
                    ? null
                    : new Date(parseInt(props.crmFieldValue)).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                    }) })));
        default:
            return (_jsxs(_Fragment, { children: [props.crmFieldValueStatus === "WAITING" && (_jsx("span", Object.assign({ className: "font-medium text-sm text-gray-500 italic" }, { children: "We're analyzing your calls. Check back in ~30 minutes to see your data enriched." }))), props.crmFieldValueStatus === "PROCESSING" && (_jsx("span", Object.assign({ className: "font-medium text-sm text-gray-500 italic" }, { children: "Processing..." }))), (!props.crmFieldValueStatus ||
                        props.crmFieldValueStatus === "COMPLETED") && (_jsx("span", Object.assign({ className: "lg:w-[400px] font-medium text-sm text-black overflow-hidden text-ellipsis break-all truncate" }, { children: props.crmFieldValue })))] }));
    }
};
export default CrmFieldValueRenderer;
