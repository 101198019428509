export function getEpochTimes(startDate, endDate) {
    // Set start date to midnight
    startDate.setHours(0, 0, 0, 0);
    let startEpoch = getEpochTime(startDate);
    // Set end date to 11:59:59 PM
    endDate.setHours(23, 59, 59, 999);
    let endEpoch = getEpochTime(endDate);
    return { startEpoch, endEpoch };
}
export function getEpochTime(date) {
    return Math.floor(date.getTime() / 1000);
}
