import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import classNames from "../class_names";
import { ChevronRightIcon, MagnifyingGlassIcon, } from "@heroicons/react/20/solid";
const IntegrationSelector = (props) => {
    var _a;
    const [searchText, setSearchText] = useState("");
    const [selectedItem, setSelectedItem] = useState((_a = props.defaultSelected) !== null && _a !== void 0 ? _a : props.integrations[0].name);
    const getIntegrationLabel = (integrationName) => {
        return props.integrations.find((i) => i.name === integrationName).label;
    };
    const filteredItems = props.integrations
        .filter((i) => i.label.toLowerCase().includes(searchText.toLowerCase()))
        .filter((i) => i.name !== selectedItem)
        .sort((a, b) => a.label.localeCompare(b.label));
    const handleItemClick = (item) => {
        setSelectedItem(item);
        props.onSelect(item);
    };
    const renderIntegration = (integration) => {
        return (_jsxs("div", Object.assign({ className: "flex items-center gap-2" }, { children: [_jsx("img", { src: integration.icon_url, className: "h-6 w-6" }), getIntegrationLabel(integration.name)] }), integration.name));
    };
    return (_jsxs("div", Object.assign({ className: "flex flex-col gap-4" }, { children: [_jsxs("div", Object.assign({ className: classNames("flex flex-row px-4 py-2 items-center gap-2 self-stretch rounded-full bg-gray-100") }, { children: [_jsx(MagnifyingGlassIcon, { className: "h-5 w-5 text-gray-400 shrink-0" }), _jsx("input", { type: "text", placeholder: "Search", value: searchText, onChange: (e) => setSearchText(e.target.value), className: classNames("w-full flex-grow font-normal text-sm bg-gray-100", "focus:outline-none border-0 focus:ring-0") })] })), _jsxs("div", Object.assign({ className: "flex flex-col gap-1" }, { children: [selectedItem && (_jsxs("div", Object.assign({ className: classNames("flex flex-row items-center self-stretch justify-between bg-blue-100 rounded-full px-4 py-2") }, { children: [renderIntegration(props.integrations.find((i) => i.name === selectedItem)), _jsx(ChevronRightIcon, { className: "h-5 w-5" })] }))), !searchText && (_jsx(_Fragment, { children: filteredItems.map((integration) => (_jsx("div", Object.assign({ className: "cursor-pointer hover:bg-blue-100 hover:rounded-full hover:outline-none px-4 py-2", onClick: () => handleItemClick(integration.name) }, { children: renderIntegration(integration) }), integration.name))) })), searchText && filteredItems.length > 0 && (_jsx("div", Object.assign({ className: "flex flex-col" }, { children: filteredItems.map((item) => (_jsx("div", Object.assign({ className: "cursor-pointer hover:bg-blue-100 hover:rounded-full hover:outline-none px-4 py-2", onClick: () => handleItemClick(item.name) }, { children: renderIntegration(item) }), item.name))) })))] }))] })));
};
export default IntegrationSelector;
