var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CheckIcon } from "@heroicons/react/24/outline";
import AudioCaptureToggle from "./audio_capture_toggle";
import BotToggle from "./bot_toggle";
import { Link } from "react-router-dom";
import { useStreamSocket, } from "../common/stream_socket";
import CallMetric from "../common/call_metric";
import { CheckCircleIcon, ExclamationCircleIcon, ExclamationTriangleIcon, } from "@heroicons/react/20/solid";
import { useContext, useMemo, useRef, useState } from "react";
import classNames from "../class_names";
import { computeSalespersonSpeechRateAndColor, computeSalespersonTalkRatioAndColor, } from "./talk_time";
import { Switch } from "@headlessui/react";
import { UserContext } from "../App";
import useFeatureFlagEnabled from "../use_feature_flag_enabled";
import { CallDetailsChips } from "../call_heading/call_details_chips";
import { isValidUrl } from "../strings";
/**
 * Mostly copy-paste from home/call_heading.tsx. TODO; consolidate
 *
 */
const CallHeading = (props) => {
    var _a;
    const { userIsStaff } = useContext(UserContext);
    const autoCompleteEnabled = useRef(props.autoCompleteEnabled);
    const streamSocket = useStreamSocket();
    const [audioCaptureState, setAudioCaptureState] = useState(false); // Indicates whether audio is being captured or not
    const handleRestartButtonClick = () => __awaiter(void 0, void 0, void 0, function* () {
        streamSocket === null || streamSocket === void 0 ? void 0 : streamSocket.send(JSON.stringify({ type: "restart_active_call", wipeout_call_data: true }));
        props.onCallReset();
    });
    const restartButtonEnabled = useFeatureFlagEnabled("DEMO_MODE");
    const salespersonTalkRatioAndColor = useMemo(() => {
        return computeSalespersonTalkRatioAndColor(props.talkTimeMetrics);
    }, [props.talkTimeMetrics]);
    const salespersonSpeechRateAndColor = useMemo(() => {
        return computeSalespersonSpeechRateAndColor(props.speechRateMetrics);
    }, [props.speechRateMetrics]);
    const showSpeechMetrics = (salespersonTalkRatioAndColor.ratio > 0 &&
        salespersonTalkRatioAndColor.color) ||
        audioCaptureState;
    return (_jsxs("div", Object.assign({ className: "flex gap-4 w-full p-6 bg-white border-b border-neutral-200 flex-col justify-start items-start" }, { children: [_jsx("div", Object.assign({ className: "self-stretch justify-start items-start flex" }, { children: _jsx("div", Object.assign({ className: "shrink basis-0 flex-col justify-start items-start gap-1 flex flex-grow" }, { children: _jsxs("div", Object.assign({ className: "flex flex-col lg:flex-row lg:items-center justify-between w-full lg:h-[28px]" }, { children: [_jsxs("div", Object.assign({ className: "flex gap-2 flex-col lg:flex-row lg:items-center" }, { children: [_jsx("div", Object.assign({ className: "text-lg font-bold text-Black font-lato leading-7 lg:border-r border-gray-300 pr-2" }, { children: props.call.name })), _jsx(CallDetailsChips, { call: props.call, updateCall: props.updateCall })] })), _jsxs("div", Object.assign({ className: "flex items-center justify-between lg:justify-end" }, { children: [_jsx("div", Object.assign({ className: "pr-2" }, { children: props.socketReady &&
                                            (props.call.supports_meeting_bot ? (_jsx(BotToggle, { callId: props.call.id, botStatus: props.zoomBotStatus, setAudioCaptureState: setAudioCaptureState })) : (_jsx(AudioCaptureToggle, { setAudioCaptureState: setAudioCaptureState }))) })), _jsxs("div", Object.assign({ className: "flex flex-row" }, { children: [props.call.playbook_id !== -1 && (_jsx(Link, Object.assign({ to: `/post_call/${props.call.id}` }, { children: _jsx("button", Object.assign({ className: "bg-white text-blue-500 mr-2 flex justify-center items-center px-4 py-2 rounded-full hover:bg-blue-100" }, { children: _jsx("div", Object.assign({ className: "font-bold leading-snug" }, { children: "Review meeting" })) })) }))), userIsStaff && autoCompleteEnabled.current && (_jsxs("div", Object.assign({ className: "flex items-center pr-2" }, { children: [_jsx(Switch, Object.assign({ checked: props.autoCompleteEnabled, onChange: () => __awaiter(void 0, void 0, void 0, function* () {
                                                            props.setAutocompleteEnabled((currentValue) => !currentValue);
                                                        }), className: `${props.autoCompleteEnabled
                                                            ? "bg-blue-600"
                                                            : "bg-gray-200"} relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500` }, { children: _jsx("span", Object.assign({ className: `${props.autoCompleteEnabled
                                                                ? "translate-x-6"
                                                                : "translate-x-1"} inline-block w-4 h-4 transform bg-white rounded-full transition-transform` }, { children: props.autoCompleteEnabled && (_jsx(CheckIcon, { className: "w-3 h-3 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-blue-600" })) })) })), _jsx("span", Object.assign({ className: "text-gray-700 ml-4 mr-2" }, { children: "Autocomplete" }))] }))), restartButtonEnabled && (_jsx("button", Object.assign({ type: "button", onClick: handleRestartButtonClick, className: "flex justify-center items-center px-4 py-2 border border-gray-300 rounded-full" }, { children: "Restart" }))), props.call.zoom_meeting_id &&
                                                isValidUrl((_a = props.call.calendar_event) === null || _a === void 0 ? void 0 : _a.conferenceUri) && (_jsx("button", Object.assign({ type: "button", onClick: () => {
                                                    var _a;
                                                    window.open((_a = props.call.calendar_event) === null || _a === void 0 ? void 0 : _a.conferenceUri, "_blank", "width=600, height=400");
                                                }, className: "flex justify-center items-center ml-2 px-4 py-2 border border-gray-300 rounded-full" }, { children: _jsx("img", { src: "https://storage.googleapis.com/wiser-ai-public2/zoom-meetings-rgb.png", className: "h-5 w-5" }) }))), props.call.google_meet_uri && (_jsx("a", Object.assign({ href: props.call.google_meet_uri, target: "_blank", className: "flex justify-center items-center ml-2 px-4 py-2 border border-gray-300 rounded-full" }, { children: _jsx("img", { src: "https://storage.googleapis.com/wiser-ai-public2/logo_meet_2020q4_color_2x_web_96dp.png", className: "h-5 w-5" }) })))] }))] }))] })) })) })), showSpeechMetrics && (_jsxs("div", Object.assign({ className: "flex flex-col lg:flex-row w-full lg:w-auto items-start gap-8" }, { children: [_jsx(CallMetric, { label: "Talk Ratio", labelBadge: _jsx(_Fragment, { children: salespersonTalkRatioAndColor.ratio <= 10 ? (_jsx(ExclamationTriangleIcon, { className: "w-5 h-5 text-yellow-600" })) : salespersonTalkRatioAndColor.ratio <= 60 ? (_jsx(CheckCircleIcon, { className: "w-5 h-5 text-green-600" })) : salespersonTalkRatioAndColor.ratio <= 85 ? (_jsx(ExclamationTriangleIcon, { className: "w-5 h-5 text-yellow-600" })) : (_jsx(ExclamationCircleIcon, { className: "w-5 h-5 text-red-600" })) }), labelMetricDetail: _jsxs("div", Object.assign({ className: "text-xs font-medium" }, { children: [_jsx("span", Object.assign({ className: classNames(`text-${salespersonTalkRatioAndColor.color}`) }, { children: `${salespersonTalkRatioAndColor.ratio}%` })), _jsxs("span", Object.assign({ className: "text-gray-500" }, { children: [" ", ": ", `${100 - salespersonTalkRatioAndColor.ratio}%`] }))] })), metricPercentage: salespersonTalkRatioAndColor.ratio, 
                        // TODO: This is a hack to make the color work. There's some issue with color
                        // loading when we use strings like {`bg-${salespersonTalkRatioAndColor.color}`}
                        metricColor: salespersonTalkRatioAndColor.color === "red-600"
                            ? "bg-red-600"
                            : salespersonTalkRatioAndColor.color === "green-600"
                                ? "bg-green-600"
                                : "bg-yellow-600" }), salespersonSpeechRateAndColor.color && (_jsx(CallMetric, { label: "Speech Rate", labelBadge: _jsx(_Fragment, { children: salespersonSpeechRateAndColor.color === "yellow-600" ? (_jsx(ExclamationTriangleIcon, { className: "w-5 h-5 text-yellow-600" })) : salespersonSpeechRateAndColor.color === "green-600" ? (_jsx(CheckCircleIcon, { className: "w-5 h-5 text-green-600" })) : salespersonSpeechRateAndColor.color === "red-600" ? (_jsx(ExclamationCircleIcon, { className: "w-5 h-5 text-red-600" })) : (_jsx(ExclamationTriangleIcon, { className: "w-5 h-5 text-yellow-600" })) }), labelMetricDetail: _jsx("div", Object.assign({ className: "text-xs font-medium" }, { children: _jsx("span", Object.assign({ className: "text-gray-500" }, { children: salespersonSpeechRateAndColor.rateLabel })) })), metricPercentage: salespersonSpeechRateAndColor.percentRate, metricColor: `bg-${salespersonSpeechRateAndColor.color}` }))] })))] })));
};
export default CallHeading;
