import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// This page shows the transcript, recording, and notes for a completed call.
import React, { useEffect, useRef, useState } from "react";
import { getCookie } from "./cookies";
import axios from "axios";
import { handleAuthError, showErrorModal } from "./error_modal";
import { VariableSizeList } from "react-window";
import { Note } from "./notes";
import { useParams } from "react-router-dom";
import EvalLabeler from "./eval_labeler";
import useFeatureFlagEnabled from "./use_feature_flag_enabled";
import TranscriptEntryCard from "./transcript_entry_card";
export default function CallTranscript() {
    const { callIdParam } = useParams();
    const callId = Number(callIdParam);
    const [callTranscript, setCallTranscript] = useState([]);
    const [callNotes, setCallNotes] = useState([]);
    const [playbookName, setPlaybookName] = useState("Playbook");
    // Determines what transcript part to highlight.
    // Usually the same as what we're playing, unless someone has linked into a
    // transcript but not pushed play yet.
    const [currentTranscriptTimestamp, setCurrentTranscriptTimestamp] = useState(0);
    const audioRef = useRef(null);
    const [audioSrc, setAudioSrc] = useState("");
    const listRef = React.createRef();
    const showLabeler = useFeatureFlagEnabled("EVAL_LABELER");
    useEffect(() => {
        axios
            .get("/call_nav/get_call_transcript", {
            params: { call_id: callId },
            headers: {
                Authorization: localStorage.getItem("wiser_jwt"),
                "X-CSRFToken": getCookie("csrftoken"),
            },
        })
            .then((response) => {
            setCallTranscript(response.data.transcript);
            setCallNotes(response.data.notes);
            setPlaybookName(response.data.playbookName);
            if (response.data.transcript.length > 0)
                setCurrentTranscriptTimestamp(response.data.transcript[0].startTime);
        })
            .catch((err) => {
            showErrorModal(err);
        });
    }, [callId]);
    let setCurrentTranscript = (time_msec) => {
        let search_time = callTranscript[0].startTime + time_msec;
        for (let i = 1; i < callTranscript.length; i++) {
            if (callTranscript[i].startTime >= search_time) {
                setCurrentTranscriptTimestamp(callTranscript[i - 1].startTime);
                return;
            }
        }
    };
    let lookupAudioSrc = () => {
        let startTime = callTranscript[0].startTime;
        let endTime = callTranscript[callTranscript.length - 1].endTime;
        axios
            .get("/call_nav/get_call_audio", {
            responseType: "blob",
            params: {
                call_id: callId,
                start_msec: startTime,
                end_msec: endTime,
                // An empty speaker string requests audio for all speakers.
                speaker: "",
            },
            headers: {
                Authorization: localStorage.getItem("wiser_jwt"),
                "X-CSRFToken": getCookie("csrftoken"),
            },
        })
            .then((response) => {
            // For some reason this started to break this ts type checker,
            // "TS2339: Property 'createObjectURL' does not exist on type"
            // even though it runs correctly, so we must:
            // @ts-ignore
            const url = URL.createObjectURL(response.data);
            setAudioSrc(url);
        })
            .catch((err) => {
            handleAuthError(err);
        });
    };
    useEffect(() => {
        if (callTranscript.length == 0)
            return;
        lookupAudioSrc();
    }, [callTranscript]);
    let currentPlayIndex = -1;
    for (let i = 0; i < callTranscript.length; i++) {
        if (callTranscript[i].startTime === currentTranscriptTimestamp) {
            currentPlayIndex = i;
            break;
        }
    }
    useEffect(() => {
        if (currentPlayIndex === -1 || !listRef.current)
            return;
        listRef.current.scrollToItem(currentPlayIndex, "center");
    }, [currentTranscriptTimestamp, callTranscript, callId]);
    const startTimestamp = callTranscript.length > 0 ? callTranscript[0].startTime : 0;
    let Row = (rowProps) => {
        return (_jsx("div", Object.assign({ style: rowProps.style }, { children: _jsx(TranscriptEntryCard, { callId: callId, entry: callTranscript[rowProps.index], focused: callTranscript[rowProps.index].startTime ===
                    currentTranscriptTimestamp, callStartTime: startTimestamp, onAudioStarted: () => {
                    if (!audioRef.current)
                        return;
                    audioRef.current.currentTime =
                        (callTranscript[rowProps.index].startTime -
                            callTranscript[0].startTime) /
                            1000;
                    audioRef.current.play();
                }, onRender: () => { } }) })));
    };
    let showTranscriptForNoteTime = (startTime, endTime) => {
        for (let i = 0; i < callTranscript.length; i++) {
            if (callTranscript[i].startTime >= startTime) {
                setCurrentTranscriptTimestamp(callTranscript[i].startTime);
                return;
            }
        }
    };
    return (_jsxs("div", Object.assign({ className: "min-h-full flex flex-row" }, { children: [_jsxs("div", Object.assign({ className: "grid grid-cols-1 md:grid-cols-3 mx-auto w-full max-w-14xl items-start gap-x-8 px-4 py-10 sm:px-6 lg:px-8" }, { children: [_jsxs("div", Object.assign({ className: "md:sticky top-8 bg-white p-4 my-2 border-2 rounded-lg shadow-lg" }, { children: [_jsx("h1", Object.assign({ className: "text-xl font-bold text-gray-900 p-2" }, { children: playbookName })), callNotes.map((note) => (_jsx(Note, { note: note, callId: callId, setNote: () => { }, showTranscriptTimes: showTranscriptForNoteTime }, note.playbook_item_id)))] })), _jsxs("div", Object.assign({ className: "flex flex-col bg-white p-4 border-2 rounded-lg shadow-lg w-[1000px]" }, { children: [_jsxs("h1", Object.assign({ className: "flex flex-row justify-between text-xl font-bold text-gray-900 " }, { children: [_jsx("p", Object.assign({ className: "p-2" }, { children: "Transcript" })), _jsx("audio", { controls: true, src: audioSrc, onTimeUpdate: (e) => {
                                            setCurrentTranscript(e.target.currentTime * 1000);
                                        }, ref: audioRef })] })), _jsx(VariableSizeList, Object.assign({ height: 750, itemCount: callTranscript.length, itemSize: (index) => {
                                    // Some overhead height for first line, name, padding.
                                    // Line width is closer to 100 chars, but I'd rather have extra space than too little.
                                    // There are probably better ways to do this; some js libraries can estimate width
                                    // of text, and we'll want that tuning eventually.
                                    return (75 + Math.floor(callTranscript[index].text.length / 85) * 25);
                                }, width: 900, ref: listRef }, { children: Row }))] }))] })), showLabeler && (_jsx("div", Object.assign({ className: "flex flex-col bg-white p-4 border-2 rounded-lg shadow-lg w-[400px]" }, { children: _jsx(EvalLabeler, { startTimestamp: startTimestamp, currentTimestamp: currentTranscriptTimestamp, playbookNotes: callNotes, currentTranscriptEndTime: currentPlayIndex !== -1
                        ? callTranscript[currentPlayIndex].endTime
                        : 0 }) })))] })));
}
