var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import * as endpoints from "../common/endpoints";
import { createColumnHelper } from "@tanstack/react-table";
import CrmFieldValue from "./crm_field_value";
import { IndeterminateCheckbox } from "./select_checkbox";
import { CrmTable } from "./crm_table";
import { CrmObjectReferenceSwitcher } from "./crm_object_reference_switcher";
const Crmopportunities = () => {
    // State to store the CRM opportunities keyed by their ID.
    const [crmOpportunitiesMap, setCrmOpportunitiesMap] = useState(new Map());
    // State to store the CRM accounts keyed by their ID.
    const [crmAccountsMap, setCrmAccountsMap] = useState(new Map());
    const columnHelper = createColumnHelper();
    const columns = useMemo(() => {
        // Opportunity specific table columns.
        return [
            columnHelper.accessor("id", {
                id: "row-select",
                header: (props) => (_jsx(IndeterminateCheckbox, Object.assign({}, {
                    checked: props.table.getIsAllRowsSelected(),
                    indeterminate: props.table.getIsSomeRowsSelected(),
                    onChange: props.table.getToggleAllRowsSelectedHandler(),
                }))),
                cell: (props) => (_jsx("div", Object.assign({ className: "p-4" }, { children: _jsx(IndeterminateCheckbox, Object.assign({}, {
                        checked: props.row.getIsSelected(),
                        disabled: !props.row.getCanSelect(),
                        indeterminate: props.row.getIsSomeSelected(),
                        onChange: props.row.getToggleSelectedHandler(),
                    })) }))),
                size: 60,
                enableSorting: false,
                enableColumnFilter: false,
            }),
            columnHelper.accessor((row) => row.opportunity_name, {
                id: "opportunity_name",
                header: "Name",
                cell: (props) => (_jsx(CrmFieldValue, { crmObjectId: parseInt(props.row.id), crmFieldId: -1, crmFieldSchema: { type: "text", restrictions: { length: 1000 } }, crmFieldValue: props.getValue(), onNewValueChange: (newValue) => __awaiter(void 0, void 0, void 0, function* () {
                        yield endpoints.updateCrmOpportunity(parseInt(props.row.id), 
                        /* name= */ newValue, 
                        /* accountId= */ undefined);
                        setCrmOpportunitiesMap((prevMap) => {
                            const updatedOpportunity = prevMap.get(parseInt(props.row.id));
                            if (updatedOpportunity) {
                                updatedOpportunity.opportunity_name = newValue;
                                return new Map([
                                    ...prevMap,
                                    [parseInt(props.row.id), updatedOpportunity],
                                ]);
                            }
                            return prevMap;
                        });
                    }) })),
                meta: {
                    fieldId: -1,
                    fieldSchema: {
                        type: "text",
                        restrictions: { length: 1000 },
                    },
                    required: true,
                    columnInfo: {
                        columnType: "CUSTOM",
                    },
                },
                filterFn: "wiserCrmTableFilterFn",
                sortingFn: "alphanumeric",
                size: 320,
            }),
            columnHelper.accessor((row) => {
                var _a;
                return row.account_id > 0 && crmAccountsMap.has(row.account_id)
                    ? (_a = crmAccountsMap.get(row.account_id)) === null || _a === void 0 ? void 0 : _a.account_name
                    : "";
            }, {
                id: "account",
                header: "Account",
                cell: (props) => (_jsx(CrmObjectReferenceSwitcher, { idToLabelMap: new Map(Array.from(crmAccountsMap.values()).map((account) => {
                        var _a;
                        return [
                            account.id,
                            (_a = account.account_name) !== null && _a !== void 0 ? _a : "",
                        ];
                    })), selectedId: props.row.original.account_id, onSelectedIdChange: (newAccountId) => __awaiter(void 0, void 0, void 0, function* () {
                        yield endpoints.updateCrmOpportunity(parseInt(props.row.id), 
                        /* name= */ undefined, 
                        /* accountId= */ newAccountId);
                        setCrmOpportunitiesMap((prevMap) => {
                            const updatedOpportunity = prevMap.get(parseInt(props.row.id));
                            if (updatedOpportunity) {
                                updatedOpportunity.account_id = newAccountId;
                                return new Map([
                                    ...prevMap,
                                    [parseInt(props.row.id), updatedOpportunity],
                                ]);
                            }
                            return prevMap;
                        });
                    }) })),
                meta: {
                    fieldId: -2,
                    // This is used for filtering, and since we use account name as the column value,
                    // we can use text as field type and use filter and sorting fns for text cells.
                    fieldSchema: {
                        type: "text",
                        restrictions: { length: 1000 },
                    },
                    required: true,
                    columnInfo: {
                        columnType: "CUSTOM",
                    },
                },
                filterFn: "wiserCrmTableFilterFn",
                sortingFn: "alphanumeric",
                size: 320,
            }),
        ];
    }, [crmAccountsMap]);
    // Memoize the table data to prevent unnecessary re-renders.
    const tableData = useMemo(() => {
        return Array.from(crmOpportunitiesMap.values());
    }, [crmOpportunitiesMap]);
    const fetchCrmOpportunities = () => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield endpoints.getCrmOpportunities();
        setCrmOpportunitiesMap(new Map(response.map((opportunity) => [opportunity.id, opportunity])));
    });
    useEffect(() => {
        fetchCrmOpportunities();
        endpoints.getCrmAccounts().then((response) => {
            setCrmAccountsMap(new Map(response.map((account) => [account.id, account])));
        });
    }, []);
    const onNewRowCreate = (newRowData, crmFieldValues) => __awaiter(void 0, void 0, void 0, function* () {
        const newCrmOpportunity = yield endpoints.createCrmOpportunity(newRowData.find((rowData) => rowData.columnId === "opportunity_name")
            .value, newRowData.find((rowData) => rowData.columnId === "account").value, crmFieldValues);
        const updatedCrmOpportunity = yield endpoints.getCrmOpportunity(newCrmOpportunity.id);
        setCrmOpportunitiesMap((prevMap) => {
            return new Map([
                ...prevMap,
                [updatedCrmOpportunity.id, updatedCrmOpportunity],
            ]);
        });
    });
    const onCrmFieldValueChange = (opportunityId, field, newValue) => __awaiter(void 0, void 0, void 0, function* () {
        const updatedCrmDataId = yield endpoints.createOrUpdateCrmOpportunityData(opportunityId, field.id, newValue);
        setCrmOpportunitiesMap((prevMap) => {
            const updatedOpportunity = prevMap.get(opportunityId);
            if (updatedOpportunity) {
                // If crm_data contains a field with the same ID, update the value.
                // else add a new crm_data object.
                if (updatedOpportunity.crm_data.some((crmData) => crmData.crm_field.id === field.id)) {
                    updatedOpportunity.crm_data = updatedOpportunity.crm_data.map((crmData) => crmData.crm_field.id === field.id
                        ? Object.assign(Object.assign({}, crmData), { id: updatedCrmDataId, value: newValue }) : crmData);
                }
                else {
                    updatedOpportunity.crm_data.push({
                        id: updatedCrmDataId,
                        crm_field: field,
                        value: newValue,
                    });
                }
                return new Map([...prevMap, [opportunityId, updatedOpportunity]]);
            }
            return prevMap;
        });
    });
    return (_jsx(CrmTable, { tableData: {
            crmObject: "Opportunity",
            rows: tableData,
        }, columns: columns, columnData: [
            {
                columnId: "account",
                idToAccountNameMap: new Map(Array.from(crmAccountsMap.values()).map((account) => {
                    var _a;
                    return [
                        account.id,
                        (_a = account.account_name) !== null && _a !== void 0 ? _a : "",
                    ];
                })),
            },
        ], onNewRowCreate: onNewRowCreate, onCrmFieldValueChange: onCrmFieldValueChange, refetchCrmData: fetchCrmOpportunities }));
};
export default Crmopportunities;
