import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Popover } from "@headlessui/react";
import { TableOptionButton } from "./table_option_button";
import { ViewColumnsIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { DragDropContext, Draggable, Droppable, } from "react-beautiful-dnd";
import DragIndicatorIcon from "../icons/drag_indicator_icon";
import { SearchableDropdown } from "../common/searchable_dropdown";
import { useCrmColumnEnrichment } from "./use_crm_column_enrichment";
import { useEffect, useState } from "react";
export const TableColumnVisibility = (props) => {
    const { allColumns, hiddenColumns, addColumn, removeColumn } = useCrmColumnEnrichment(props.crmObjectType, props.tableCols, props.columnViewState, props.setColumnViewState, props.refetchCrmFields, props.refetchCrmData);
    const [localColumnViewState, setLocalColumnViewState] = useState(props.columnViewState);
    useEffect(() => {
        setLocalColumnViewState([...props.columnViewState]);
    }, [props.columnViewState]);
    const renderColumn = (columnId, index) => {
        const selectableColumns = [...hiddenColumns];
        // If columnId is not empty, it means that the column is already selected.
        // So, we need to add it to selectableColumns to show it in the dropdown.
        if (columnId.length > 0) {
            selectableColumns.push(allColumns.find((col) => col.id === columnId));
        }
        return (_jsxs("div", Object.assign({ className: "flex gap-2 w-max" }, { children: [_jsx(SearchableDropdown, { options: selectableColumns.map((col) => ({
                        value: col.id,
                        label: col.label,
                    })), selectedOption: columnId.length === 0
                        ? undefined
                        : {
                            value: columnId,
                            label: selectableColumns.find((col) => col.id === columnId)
                                .label,
                        }, onSelect: (option) => {
                        if (option) {
                            addColumn(option.value, index);
                        }
                    }, placeholder: "Choose a column", buttonClasses: "grow min-w-[400px] w-fit hover:none bg-white" }), _jsx("button", Object.assign({ onClick: () => removeColumn(columnId), className: "text-gray-500" }, { children: _jsx(XMarkIcon, { className: "h-4 w-4" }) }))] })));
    };
    const onColumnDragEnd = (result) => {
        if (!result.destination)
            return;
        const newColumnViewState = Array.from(props.columnViewState);
        const [removed] = newColumnViewState.splice(result.source.index, 1);
        newColumnViewState.splice(result.destination.index, 0, removed);
        props.setColumnViewState(newColumnViewState);
    };
    return (_jsxs(Popover, Object.assign({ as: "div", className: "relative" }, { children: [_jsx(Popover.Button, Object.assign({ className: "focus:outline-none hover:bg-gray-200 rounded-lg py-1" }, { children: _jsx(TableOptionButton, { icon: _jsx(ViewColumnsIcon, { className: "h-4 w-4" }), text: "Columns" }) })), _jsx(Popover.Overlay, { className: "fixed inset-0 bg-black opacity-10 z-10" }), _jsx(Popover.Panel, Object.assign({ className: "flex grow absolute z-10 w-[360px]" }, { children: _jsxs("div", Object.assign({ className: "flex grow shrink-0 items-start flex-col gap-4 px-6 py-4 bg-white border border-gray-300 rounded-2xl" }, { children: [_jsxs("div", Object.assign({ className: "flex gap-6 items-center justify-between self-stretch" }, { children: [_jsx("span", Object.assign({ className: "text-gray-500 font-bold text-sm" }, { children: "Show me these columns" })), _jsx("span", Object.assign({ className: "text-gray-500 font-bold text-sm" }, { children: `${localColumnViewState.length} selected` }))] })), _jsx(DragDropContext, Object.assign({ onDragEnd: onColumnDragEnd }, { children: _jsx(Droppable, Object.assign({ droppableId: "columnEnrichment" }, { children: (dndProps) => {
                                    return (_jsxs("div", Object.assign({ className: "flex flex-col gap-2" }, dndProps.droppableProps, { ref: dndProps.innerRef }, { children: [localColumnViewState.map((columnId, index) => (_jsx(Draggable, Object.assign({ index: index, draggableId: `${columnId}-${index}` }, { children: (dndProps) => {
                                                    return (_jsxs("div", Object.assign({}, dndProps.draggableProps, dndProps.dragHandleProps, { ref: dndProps.innerRef, className: "flex gap-2 w-max items-center" }, { children: [renderColumn(columnId, index), _jsx(DragIndicatorIcon, {})] })));
                                                } }), `${columnId}-${index}`))), dndProps.placeholder] })));
                                } })) })), hiddenColumns.length > 0 && (_jsx("div", { children: _jsx("button", Object.assign({ className: "text-sm font-bold text-blue-500", onClick: () => {
                                    // Adding a new empty column to the localColumnViewState
                                    // This will ensure that we render a new dropdown in the UI
                                    setLocalColumnViewState((prev) => [...prev, ""]);
                                } }, { children: "+ Add column" })) }))] })) }))] })));
};
