import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { markLatestActivityTime } from "../activity_utils";
import Navbar from "../common/nav_bar";
import { useLocation, Outlet } from "react-router-dom";
const Settings = () => {
    const location = useLocation();
    const activeTab = location.pathname;
    // For jwt token refresh
    markLatestActivityTime();
    return (_jsxs("div", Object.assign({ className: "w-full h-[calc(100vh - 3.125rem)] lg:h-screen bg-neutral-100" }, { children: [_jsxs("div", Object.assign({ className: "w-full bg-white border-b border-neutral-200 flex flex-col" }, { children: [_jsx("div", Object.assign({ className: "text-black text-2xl font-bold leading-loose mt-8 ml-8" }, { children: "My profile settings" })), _jsx(Navbar, { tabs: [
                            { label: "User settings", toPath: "/settings/user_settings" },
                            { label: "Integrations", toPath: "/settings/integrations" },
                        ], activeTab: activeTab })] })), _jsx(Outlet, {})] })));
};
export default Settings;
