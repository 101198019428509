import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Mosaic } from "react-mosaic-component";
import "react-mosaic-component/react-mosaic-component.css";
import "./index.css";
import VideoPlayback from "./video_playback";
import "./index.css";
import { getPlaybackPage } from "./common/endpoints";
import Transcript from "./live_call/transcript";
import HomePageManualNotes from "./home/home_page_manual_notes";
import { Tab } from "@headlessui/react";
import classNames from "./class_names";
import PreCallPrep from "./pre_call_prep";
import { ChatModal } from "./post_call/chat_modal";
import TrackerV2 from "./live_call/tracker_v2";
import { CenterScreen } from "./settings/styled_components";
import { SettingsLoader } from "./common/loaders";
import { CallHighlights } from "./call_highlights";
import tw from "tailwind-styled-components";
import useFeatureFlagEnabled from "./use_feature_flag_enabled";
const TabContainer = tw.div `
  bg-white
  border
  rounded-2xl
`;
/**
 * The Video playback page with transcript and other tabs
 * @param {string | null} subTab - The subtab to display, passing empty displays trackers
 * @returns
 */
const VideoWithTranscript = ({ subTab }) => {
    const { callIdParam } = useParams();
    const navigate = useNavigate();
    const callId = Number(callIdParam);
    const [call, setCall] = useState(null);
    const [callNotes, setCallNotes] = useState([]);
    const [playbookName, setPlaybookName] = useState("Playbook");
    const [videoUrl, setVideoUrl] = useState("");
    const [timeline, setTimeline] = useState([]);
    const [transcript, setTranscript] = useState([]);
    const [currentVideoTime, setCurrentVideoTime] = useState(0);
    const [currentTranscriptIndex, setCurrentTranscriptIndex] = useState(0);
    const [videoStartMsec, setVideoStartMsec] = useState(0);
    // Determines what transcript part to highlight.
    // Usually the same as what we're playing, unless someone has linked into a
    // transcript but not pushed play yet.
    const [currentTranscriptTimestamp, setCurrentTranscriptTimestamp] = useState(0);
    const [isMosaicScrolling, setIsMosaicScrolling] = useState(false);
    const callHighlightsVisible = useFeatureFlagEnabled("CALL_HIGHLIGHTS_TAB");
    const transcriptLengthMsec = useMemo(() => {
        if (transcript === undefined)
            return 0;
        if (transcript.length === 0)
            return 0;
        return transcript[transcript.length - 1].endTime - transcript[0].startTime;
    }, [transcript]);
    useEffect(() => {
        const playbackPageResponse = getPlaybackPage(callId);
        playbackPageResponse.then((response) => {
            setCall(response.call);
            setVideoUrl(response.video_url);
            setTimeline(response.speaker_timeline);
            setTranscript(response.transcript);
            setCallNotes(response.notes);
            setPlaybookName(response.playbook_name);
            setVideoStartMsec(response.video_start_msec);
        });
    }, [callId]);
    useEffect(() => {
        for (let i = 0; i < transcript.length; i++) {
            if (transcript[i].startTime - videoStartMsec > currentVideoTime) {
                setCurrentTranscriptIndex(i - 1);
                return;
            }
        }
        setCurrentTranscriptIndex(transcript.length - 1);
    }, [currentVideoTime]);
    const [mosaicState, setMosaicState] = useState({
        direction: "row",
        first: "dashboard",
        second: "video",
        splitPercentage: 50,
    });
    let MeetingType;
    (function (MeetingType) {
        MeetingType[MeetingType["GoogleMeet"] = 1] = "GoogleMeet";
        MeetingType[MeetingType["Zoom"] = 2] = "Zoom";
        MeetingType[MeetingType["LocalAudio"] = 3] = "LocalAudio";
    })(MeetingType || (MeetingType = {}));
    const getMeetingType = (call) => {
        return call.google_meet_uri
            ? MeetingType.GoogleMeet
            : call.is_zoom_call
                ? MeetingType.Zoom
                : MeetingType.LocalAudio;
    };
    const VideoPlaybackContainer = useMemo(() => {
        if (!call) {
            return (_jsx(CenterScreen, { children: _jsx(SettingsLoader, {}) }));
        }
        const meetingType = getMeetingType(call);
        const IMAGE_SRCS = new Map([
            [
                MeetingType.GoogleMeet,
                "https://storage.googleapis.com/wiser-ai-public2/logo_meet_2020q4_color_2x_web_96dp.png",
            ],
            [
                MeetingType.Zoom,
                "https://storage.googleapis.com/wiser-ai-public2/zoom-meetings-rgb.png",
            ],
            [
                MeetingType.LocalAudio,
                "https://storage.googleapis.com/wiser-ai-public2/wiser_logo_color.png",
            ],
        ]);
        const MEETING_TEXTS = new Map([
            [MeetingType.GoogleMeet, "Google Meet"],
            [MeetingType.Zoom, "Zoom"],
            [MeetingType.LocalAudio, "Local Audio"],
        ]);
        return (_jsx("div", Object.assign({ className: "flex items-start justify-start h-full md:mt-5 py-2 pl-4" }, { children: _jsxs("div", Object.assign({ className: "flex flex-col w-full rounded-lg items-center justify-center" }, { children: [_jsxs("div", Object.assign({ className: "flex justify-end items-center w-full text-wds-gray-5 py-1 md:py-4 text-sm" }, { children: [_jsx("img", { src: IMAGE_SRCS.get(meetingType), className: "h-4 w-4 mx-2" }), "Call source: ", MEETING_TEXTS.get(meetingType)] })), _jsx(VideoPlayback, { src: videoUrl, playbackTimeline: timeline, currentVideoTime: currentVideoTime, setCurrentVideoTime: setCurrentVideoTime, transcriptLengthMsec: transcriptLengthMsec, callSourceSupported: meetingType === MeetingType.GoogleMeet })] })) })));
    }, [call, videoUrl, currentVideoTime, timeline, transcriptLengthMsec]);
    const roleSwitcherRef = useRef(null);
    const startMsec = videoStartMsec > 0
        ? videoStartMsec
        : transcript.length > 0
            ? transcript[0].startTime
            : 0;
    const dashboardItems = useMemo(() => {
        const items = [];
        if (call) {
            if (callHighlightsVisible) {
                items.push({
                    label: "Highlights",
                    renderer: () => _jsx(CallHighlights, { call: call }),
                });
            }
            items.push(...[
                {
                    label: "Trackers",
                    renderer: () => (_jsx(TabContainer, { children: _jsx(TrackerV2, { call: call, notes: callNotes, setCallAndNotes: () => { }, noteGenerationInProgress: false, setNoteGenerationRequestTime: () => { }, lastRecommendationReceivedTimeMsecs: new Map(), goToChatTab: () => { }, trackerItemsPanelCollapsed: false, trackerNoteGenerationInProgress: new Map(), setTrackerNoteGenerationRequested: () => { }, postCall: true }) })),
                },
                {
                    label: "Transcript",
                    renderer: () => (_jsx(Transcript, { isLive: false, transcriptEntries: transcript, startTimeMsec: startMsec, speakerToRoleMap: new Map(), roleSwitcherRef: roleSwitcherRef, scrollToBottom: true, setScrollToBottom: () => { }, currentTranscriptIndex: currentTranscriptIndex, setVideoTime: (timeMsec) => setCurrentVideoTime(timeMsec - videoStartMsec) })),
                },
                {
                    label: "Notes",
                    renderer: () => (_jsx(TabContainer, { children: _jsx(HomePageManualNotes, { callId: callId }) })),
                },
                {
                    label: "Research",
                    renderer: () => (_jsx(TabContainer, { children: _jsx(PreCallPrep, { call: call, dashboardView: true }) })),
                },
            ]);
        }
        return items;
    }, [call]);
    const getSelectedTab = (subTab) => {
        if (!subTab)
            return 0;
        const tabIdx = dashboardItems.findIndex((item) => `#${item.label.toLowerCase()}` === subTab);
        return tabIdx === -1 ? 0 : tabIdx;
    };
    const [selectedTabIndex, setSelectedTabIndex] = useState(getSelectedTab(subTab));
    useEffect(() => {
        if (dashboardItems.length <= selectedTabIndex)
            return;
        if (location.hash !==
            `#${dashboardItems[selectedTabIndex].label.toLowerCase()}`) {
            navigate(`#${dashboardItems[selectedTabIndex].label.toLowerCase()}`);
        }
    }, [selectedTabIndex, dashboardItems]);
    const DashboardContainer = (_jsx("div", Object.assign({ className: "w-full h-full flex flex-col justify-start items-start rounded-lg border-b border-wds-gray-3" }, { children: _jsxs(Tab.Group, Object.assign({ selectedIndex: selectedTabIndex, onChange: setSelectedTabIndex }, { children: [_jsx(Tab.List, Object.assign({ className: "flex my-4 items-center border border-wds-gray-3 bg-white rounded-full p-1" }, { children: dashboardItems.map((item) => (_jsx(Tab, Object.assign({ className: ({ selected }) => classNames("outline-none px-2 md:px-4 py-2 justify-start items-center flex rounded-full focus:outline-none focus:ring-0", selected
                            ? "bg-wds-blue-1 font-bold"
                            : "hover:bg-wds-blue-1 font-normal") }, { children: item.label }), item.label))) })), _jsx(Tab.Panels, Object.assign({ className: "w-full md:overflow-auto" }, { children: dashboardItems.map((item) => (_jsx(Tab.Panel, Object.assign({ className: "w-full h-full" }, { children: item.renderer() }), item.label))) }))] })) })));
    const MOSAIC_ELEMENT_MAP = {
        dashboard: DashboardContainer,
        video: VideoPlaybackContainer,
    };
    const onMosaicChange = (currentNode) => {
        // Checking currentNode to ensure that a change has actually occurred in the Mosaic layout
        if (currentNode) {
            setMosaicState(currentNode);
        }
    };
    const onMosaicRelease = (currentNode) => {
        setIsMosaicScrolling(false);
    };
    if (!call) {
        return (_jsx(CenterScreen, { children: _jsx(SettingsLoader, {}) }));
    }
    return (_jsxs("div", Object.assign({ className: "bg-neutral-100 h-screen flex flex-col" }, { children: [_jsx("div", Object.assign({ className: "flex-grow overflow-hidden w-full h-full px-8 hidden md:flex" }, { children: _jsx(Mosaic, { resize: { minimumPaneSizePercentage: 1 }, renderTile: (id) => _jsx("div", { children: MOSAIC_ELEMENT_MAP[id] }), value: mosaicState, onChange: onMosaicChange, onRelease: onMosaicRelease, className: "my-mosaic h-full" }) })), _jsxs("div", Object.assign({ className: "flex flex-col overflow-scroll w-full h-full px-8 pb-8 md:hidden" }, { children: [VideoPlaybackContainer, DashboardContainer] })), _jsx(ChatModal, { call: call })] })));
};
export default VideoWithTranscript;
