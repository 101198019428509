var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import BaseModal from "../base_modal";
import CreateOrEditBriefing from "./create_or_edit_briefing";
import { createPreCallPrepBlock, updatePreCallPrepBlock, } from "../common/endpoints";
const CreateOrEditBriefingModal = ({ open, onClose, refetch, callPrepBlock, templateId, }) => {
    const editMode = callPrepBlock !== null;
    const [name, setName] = useState((callPrepBlock === null || callPrepBlock === void 0 ? void 0 : callPrepBlock.name) || "");
    const [prompt, setPrompt] = useState((callPrepBlock === null || callPrepBlock === void 0 ? void 0 : callPrepBlock.prompt) || "");
    // ChatGPT recommended using a "Set" here for better performance & simpler syntax (.add, .delete, etc)
    const [selectedDataSources, setSelectedDataSources] = useState(new Set((callPrepBlock === null || callPrepBlock === void 0 ? void 0 : callPrepBlock.data_sources) || []));
    const [deliveryTargets, setDeliveryTargets] = useState(new Set((callPrepBlock === null || callPrepBlock === void 0 ? void 0 : callPrepBlock.delivery_targets) || []));
    const saveAndClose = () => __awaiter(void 0, void 0, void 0, function* () {
        if (editMode) {
            yield updatePreCallPrepBlock(callPrepBlock.id, name, [...selectedDataSources], prompt, [...deliveryTargets]);
        }
        else {
            yield createPreCallPrepBlock(name, [...selectedDataSources], prompt, [...deliveryTargets], templateId, "CALL");
        }
        yield refetch();
        onClose();
    });
    const formDisabled = useMemo(() => {
        return !name || !prompt;
    }, [name, prompt]);
    return (_jsx(BaseModal, Object.assign({ open: open, setOpen: (open) => {
            if (!open) {
                onClose();
            }
        }, title: editMode ? "Edit research block" : "Add research block", description: "A research block helps prepare you for a call using information retrieved by an AI prompt.", fields: [], submitCallback: saveAndClose, submitText: editMode ? "Save" : "Add", disableSubmit: formDisabled }, { children: _jsx(CreateOrEditBriefing, { researchBlockType: "CALL", name: name, setName: setName, prompt: prompt, setPrompt: setPrompt, selectedDataSources: selectedDataSources, deliveryTargets: deliveryTargets, setSelectedDataSources: setSelectedDataSources, setDeliveryTargets: setDeliveryTargets }) })));
};
export default CreateOrEditBriefingModal;
