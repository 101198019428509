import { jsx as _jsx } from "react/jsx-runtime";
import { BarsArrowDownIcon, BarsArrowUpIcon, } from "@heroicons/react/24/outline";
export const ColumnSortButton = (props) => {
    var _a;
    const showDescSort = !!!((_a = props.sortState.find((sortRule) => sortRule.id === props.columnId)) === null || _a === void 0 ? void 0 : _a.desc);
    return (_jsx("button", Object.assign({ className: "p-2 opacity-0 hover:opacity-100 hover:bg-blue-100 hover:cursor-pointer hover:outline-none hover:rounded-full", onClick: () => {
            const updatedSortState = props.sortState.map((sortRule) => {
                if (sortRule.id === props.columnId) {
                    return Object.assign(Object.assign({}, sortRule), { desc: !sortRule.desc });
                }
                return sortRule;
            });
            if (!updatedSortState.find((sortRule) => sortRule.id === props.columnId)) {
                updatedSortState.push({
                    id: props.columnId,
                    desc: showDescSort,
                });
            }
            props.setSortState(updatedSortState);
        } }, { children: showDescSort ? (_jsx(BarsArrowDownIcon, { className: "h-5 w-5 text-black" })) : (_jsx(BarsArrowUpIcon, { className: "w-5 h-5 text-black" })) })));
};
