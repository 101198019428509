import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import WiserSparklesIcon from "../wiser_sparkles_icon";
import classNames from "../class_names";
// Call assistance carousel item representing an advice pill for coaching prompts
const CallAssistanceItem = ({ name, onClick, }) => {
    return (_jsx("button", Object.assign({ className: classNames("flex items-center justify-start h-14 gap-2", "border overflow-hidden rounded-lg bg-white border-wds-gray-3", "w-full py-6 px-14"), onClick: onClick }, { children: _jsxs("div", Object.assign({ className: "flex flex-row items-center px-4 gap-6" }, { children: [_jsx(WiserSparklesIcon, {}), _jsx("div", Object.assign({ className: "w-full overflow-hidden text-black font-Lato font-normal", style: { lineHeight: "20px" } }, { children: _jsx("div", Object.assign({ className: "overflow-hidden", style: {
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                            textOverflow: "ellipsis",
                        } }, { children: name })) }))] })) })));
};
export default CallAssistanceItem;
