import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Editor } from "@tinymce/tinymce-react";
import { useRef, useEffect, useState, } from "react";
import "./editor.css";
import { NoteLoaderFrame } from "../common/loaders";
export default function ManualNotes({ enabled, roleSwitcherRef, manualNote, autocomplete, onManualNoteChange, requestAutocomplete, isMosaicScrolling, autoCompleteEnabled, setManualNote, }) {
    const [note, setNote] = useState(manualNote);
    const noteRef = useRef(note);
    // Autocomplete-related staet
    const editorRef = useRef(null);
    const autocompleteRef = useRef(null);
    const readyForAutocomplete = useRef(false);
    const lastKey = useRef(null);
    useEffect(() => {
        if (manualNote != null)
            setNote(manualNote);
        noteRef.current = manualNote;
    }, [manualNote]);
    // Persist manual notes everytime the editor changes
    const handleEditorChange = (content, editor) => {
        if (!enabled)
            return;
        const noteWithoutAutocomplete = removeWiserAutoSpans(content);
        if (noteWithoutAutocomplete !== manualNote) {
            // Only send the change if it's different from our input.
            // This avoids update loops in multiplayer.
            onManualNoteChange(noteWithoutAutocomplete);
        }
        setNote(content);
        noteRef.current = content;
    };
    useEffect(() => {
        if (!autoCompleteEnabled)
            return;
        if (!editorRef.current)
            return;
        if (!autocomplete)
            return;
        if (!readyForAutocomplete.current)
            return;
        autocompleteRef.current = autocomplete;
        let editorContent = editorRef.current.getContent();
        if (editorContent.includes("wiserauto")) {
            // Replace the existing wiserauto span with the new one
            editorRef.current.setContent(editorContent.replace(/(<span class="wiserauto" style="color: #95a5a6;">.*?<\/span>)/g, '<span class="wiserauto" style="color: #95a5a6;">' +
                autocomplete.text +
                "</span>"));
        }
        else {
            editorRef.current.insertContent('<span class="wiserauto" style="color: #95a5a6;">' +
                autocomplete.text +
                "</span>");
        }
        // Inserting the suggestion moves the cursor. We need to move it back.
        // This seems to work pretty well, since we always insert a predictable
        // span node right after the current cursor position.
        let wiserSpan = editorRef.current.dom.select("span.wiserauto");
        if (!wiserSpan || !wiserSpan[0])
            return;
        let previousNode = wiserSpan[0].previousSibling;
        if (previousNode && previousNode.textContent) {
            editorRef.current.selection.setCursorLocation(previousNode, previousNode.textContent.length);
        }
    }, [autocomplete]);
    const removeWiserAutoSpans = (content) => {
        return content.replace(/(<span class="wiserauto" style="color: #95a5a6;">.*?<\/span>)/g, "");
    };
    useEffect(() => {
        // On component unmount set the notes in the parent component
        // This ensures that when the component re-mounts
        // It has the latest "correct" notes
        // These cannot be managed in a state variable since changes to state
        // cause re-render which moves the cursor to the start of the editor
        return () => {
            if (setManualNote) {
                setManualNote(noteRef.current);
            }
        };
    }, []);
    if (!enabled) {
        return _jsx(NoteLoaderFrame, {});
    }
    return (_jsxs("div", Object.assign({ className: "h-full" }, { children: [_jsx(Editor, { apiKey: "d5k8z9kycjmnjh6rduiv20644jg43k5r0yb8kpux0pae0xuf", init: {
                    keep_styles: false,
                    plugins: [
                        "powerpaste",
                        "advcode",
                        "casechange",
                        "formatpainter",
                        "linkchecker",
                        "autolink",
                        "lists",
                        "checklist",
                        "media",
                        "mediaembed",
                        "pageembed",
                        "permanentpen",
                        "table",
                        "advtable",
                        "image",
                        "link",
                        "table",
                        "lists",
                        "nonbreaking",
                    ],
                    toolbar: "fontfamily | fontsize | blocks | forecolor backcolor | bold italic underline | numlist bullist | image insertfile link  | code",
                    menubar: false,
                    height: "100%",
                    branding: false,
                    statusbar: false,
                    // Setting single space inside the editor
                    forced_root_block: "div",
                    // Styles for the edit area
                    content_style: "body { background-color:#FFFFFF; padding: 3% 3% 0%;}",
                    skin: "snow",
                    powerpaste_allow_local_images: true,
                    powerpaste_word_import: "merge",
                    powerpaste_html_import: "merge",
                    powerpaste_googledocs_import: "merge",
                    nonbreaking_force_tab: true,
                    paste_as_text: false,
                    // Short cut keys
                    textpattern_patterns: [
                        { start: "1. ", cmd: "InsertOrderedList" },
                        { start: "* ", cmd: "InsertUnorderedList" },
                        { start: " * ", cmd: "InsertUnorderedList" },
                        { start: "- ", cmd: "InsertUnorderedList" },
                        { start: " - ", cmd: "InsertUnorderedList" },
                    ],
                    setup: function (editor) {
                        editorRef.current = editor;
                        editor.on("click", function (event) {
                            var _a, _b;
                            // TODO: This to handle click event from role picker modal to dismiss it, seems a little hacky but can't find a solution yet
                            // Throw up a  throw up an invisible full-page div behind the modal but on top of everything else and let that get any click events
                            if (((_a = roleSwitcherRef === null || roleSwitcherRef === void 0 ? void 0 : roleSwitcherRef.current) === null || _a === void 0 ? void 0 : _a.getAttribute("data-headlessui-state")) === "open") {
                                (_b = roleSwitcherRef === null || roleSwitcherRef === void 0 ? void 0 : roleSwitcherRef.current) === null || _b === void 0 ? void 0 : _b.click();
                            }
                            else {
                                // Clicking around breaks the assumption that the autosuggest is right before
                                // the cursor, so we clear the suggestion. This also makes it ~impossible to
                                // get into all manner of bad states by interacting further with the suggestion.
                                setNote((prevNote) => {
                                    return removeWiserAutoSpans(prevNote);
                                });
                            }
                        });
                        // Sets Arial as default font for the font family toolbar
                        editor.on("init", function () {
                            editor.execCommand("fontName", false, "Arial");
                            editor.getBody().style.fontFamily = "Arial";
                            // Stops propagation of the scroll event coming from Mosaic during resize of the tile
                            let iframe = editor.getWin();
                            iframe.addEventListener("scroll", function (event) {
                                event.stopPropagation();
                            }, true);
                        });
                        if (!autoCompleteEnabled)
                            return;
                        // This prevents the default action for the tab event to move the focus to the next focusable element and instead insert a tab into the editor.
                        editor.on("keydown", function (event) {
                            if (event.key === "Shift")
                                return;
                            if (event.key === "Tab") {
                                // preventing browsers default tab behvaior
                                event.preventDefault();
                                if (event.shiftKey)
                                    return;
                                if (!autocompleteRef.current) {
                                    // indents the current line mimics a real tab
                                    editor.execCommand("Indent");
                                    return false;
                                }
                                else {
                                    // Accepts the autocomplete suggestion. By selecting the
                                    // suggestion before inserting the text, we delete the suggestion on
                                    // insertion.
                                    editor.selection.select(editor.dom.select("span.wiserauto")[0]);
                                    editor.insertContent(autocompleteRef.current.text);
                                    readyForAutocomplete.current = false;
                                }
                            }
                            else if (event.key === "ArrowUp" ||
                                event.key === "ArrowDown" ||
                                event.key === "ArrowLeft" ||
                                event.key === "ArrowRight") {
                                // Similar to clicks, moving the cursor should dismiss the suggestion.
                                // This feels right too!
                                readyForAutocomplete.current = false;
                                setNote(removeWiserAutoSpans(editor.getContent()));
                            }
                            else if (
                            // And these keys should also dismiss the suggestion.
                            // Note that these are different, because we still need to execute
                            // the keystroke, so setting the content would create issues.
                            event.key === "Delete" ||
                                event.key === "Backspace" ||
                                event.key === "Enter" ||
                                event.key === "Escape") {
                                // Sneaky way to force "enter" to delete the suggestion!
                                editor.selection.select(editor.dom.select("span.wiserauto")[0]);
                                readyForAutocomplete.current = false;
                            }
                            else if (event.key === " ") {
                                // On space, we request a new autocomplete suggestion. We'll disable the
                                // keydown and insert the space manually below.
                                event.preventDefault();
                                readyForAutocomplete.current = true;
                                let prevContentWithoutSuggestions = removeWiserAutoSpans(editor.getContent());
                                editor.insertContent(" ");
                                let contentWithoutSuggestions = removeWiserAutoSpans(editor.getContent());
                                // The autocomplete backend uses the difference between before and
                                // after the keystroke to find the cursor location. There does not
                                // seem to be another better API to find it!
                                if (autoCompleteEnabled) {
                                    requestAutocomplete(contentWithoutSuggestions, prevContentWithoutSuggestions);
                                }
                            }
                            else if (autoCompleteEnabled &&
                                autocompleteRef.current &&
                                readyForAutocomplete.current &&
                                event.key.length === 1 // Not a special char
                            ) {
                                // If the user starts typing text that matches the suggestion, keep the rest of the
                                // suggestion on screen. If it conflicts, clear the suggestion.
                                if (event.key === autocompleteRef.current.text[0]) {
                                    autocompleteRef.current.text =
                                        autocompleteRef.current.text.slice(1);
                                    editor.dom.select("span.wiserauto")[0].textContent =
                                        autocompleteRef.current.text;
                                }
                                else {
                                    // The selection trick doesn't work here, as it then types inside the styled div.
                                    // However, just removing it seems to work without messing up the cursor.
                                    editor.dom.select("span.wiserauto")[0].remove();
                                    readyForAutocomplete.current = false;
                                    if (lastKey.current === " ") {
                                        // We need to insert the space manually, since we prevented the default action.
                                        editor.insertContent(event.key);
                                        event.preventDefault();
                                    }
                                }
                            }
                            lastKey.current = event.key;
                            if (event.shiftKey) {
                                lastKey.current = lastKey.current.toUpperCase();
                            }
                        });
                    },
                }, onEditorChange: handleEditorChange, initialValue: manualNote, value: note }, "manual-notes"), isMosaicScrolling && (_jsx("div", { className: "transparentDiv", style: {
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(0, 0, 0, 0)",
                    zIndex: 9999,
                } }))] })));
}
