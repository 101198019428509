import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, memo } from "react";
import { ChevronLeftIcon, ChevronRightIcon, } from "@heroicons/react/24/outline";
import { formatToMM_DD_YYYY } from "../strings";
const DateInputPicker = forwardRef(({ startDate, endDate, onClick, onPreviousDateClick, onNextDateClick, onTodayClick, customWidth, showTodayIcon, showPill = false, }, ref) => {
    const startLabel = formatToMM_DD_YYYY(startDate);
    const endLabel = endDate && formatToMM_DD_YYYY(endDate);
    return (_jsxs("div", Object.assign({ ref: ref, className: "flex justify-center items-center gap-2" }, { children: [showTodayIcon && (_jsx("button", Object.assign({ className: "h-10 px-4 rounded-full border border-wds-gray-3 justify-start items-center gap-2 inline-flex hover:bg-blue-100", onClick: onTodayClick }, { children: _jsx("div", Object.assign({ className: "text-wds-gray-5 font-bold" }, { children: "Today" })) }))), _jsxs("div", Object.assign({ className: `${customWidth !== null && customWidth !== void 0 ? customWidth : "w-full"}  flex justify-between items-center rounded-full border border-wds-gray-3 bg-white` }, { children: [_jsx("button", Object.assign({ className: "p-2 rounded-full justify-center items-center gap-2 flex hover:bg-blue-100", onClick: onPreviousDateClick }, { children: _jsx(ChevronLeftIcon, { className: "h-5 w-5 text-wds-gray-5" }) })), _jsx("div", Object.assign({ className: "flex-grow h-full flex justify-center items-center" }, { children: _jsx("div", Object.assign({ onClick: onClick, className: `${showPill && "bg-wds-blue-1"} min-w-[188px] cursor-pointer px-4 py-2 hover:bg-wds-blue-1 border-none rounded-full flex items-center justify-center` }, { children: _jsxs("div", Object.assign({ className: "h-[22px] font-lato text-[15px] font-bold", style: { lineHeight: "22px" } }, { children: [startLabel, startLabel !== endLabel && endLabel && ` - ${endLabel}`] })) })) })), _jsx("button", Object.assign({ className: "p-2 rounded-full justify-center items-center gap-2 flex hover:bg-blue-100", onClick: onNextDateClick }, { children: _jsx(ChevronRightIcon, { className: "h-5 w-5 text-wds-gray-5" }) }))] }))] })));
});
export default memo(DateInputPicker);
