import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from "react-query";
import { getLiveCallSummaries } from "../common/endpoints";
import { SettingsLoader } from "../common/loaders";
import { CenterScreen } from "../settings/styled_components";
import { useState } from "react";
import { formatCallTime } from "../strings";
export const CallKeypoints = ({ call }) => {
    const [startMsec, setStartMsec] = useState(0);
    const { data: callKeypoints, isLoading: callKeypointsLoading } = useQuery({
        queryKey: ["callKeypoints", call.id],
        queryFn: () => getLiveCallSummaries(call.id),
        onSuccess: (data) => {
            if (data.length === 0)
                return;
            const minStartTimeMsec = Math.min(...data.map((kp) => kp.start_msec));
            setStartMsec(minStartTimeMsec);
        },
    });
    if (!callKeypoints || callKeypoints.length === 0) {
        return null;
    }
    const renderKeypoint = (keypoint) => {
        if (keypoint.summary_text.length === 0)
            return null;
        return (_jsxs("div", Object.assign({ className: "flex items-center self-stretch justify-between px-2" }, { children: [_jsx("div", { className: "whitespace-pre-wrap w-full", dangerouslySetInnerHTML: { __html: keypoint.summary_html.trim() } }), _jsx("span", Object.assign({ className: "text-sm text-wds-gray-5 font-medium text-right" }, { children: formatCallTime(startMsec, keypoint.end_msec) }))] }), keypoint.id));
    };
    return (_jsxs("div", Object.assign({ className: "flex flex-col gap-2 self-stretch px-6 py-4 rounded-2xl bg-wds-gray-3 bg-white" }, { children: [_jsxs("div", Object.assign({ className: "flex flex-col items-start gap-[2px]" }, { children: [_jsx("span", Object.assign({ className: "text-lg font-bold" }, { children: "Key points" })), _jsx("span", Object.assign({ className: "bg-clip-text text-transparent bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500" }, { children: "AI generated" }))] })), callKeypointsLoading && (_jsx(CenterScreen, { children: _jsx(SettingsLoader, {}) })), _jsx("div", Object.assign({ className: "flex flex-col h-full" }, { children: callKeypoints
                    .sort((kp1, kp2) => kp1.start_msec - kp2.start_msec)
                    .map((keypoint) => renderKeypoint(keypoint)) }))] })));
};
