import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// This file defines a generic component with a list of options to select from and
// a search box to filter down which are shown.
import { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import classNames from "./class_names";
export default function SearchSelect(props) {
    const [searchText, setSearchText] = useState("");
    let selection = undefined;
    for (let i = 0; i < props.options.length; i++) {
        if (props.options[i].id === props.selectedId) {
            selection = props.options[i];
            break;
        }
    }
    let selectOption = (option) => {
        props.selectId(option.id);
    };
    let filteredOptions = props.options.filter((option) => {
        return option.name.toLowerCase().includes(searchText.toLowerCase());
    });
    return (_jsxs("div", Object.assign({ className: "px-2" }, { children: [_jsxs("div", { children: [_jsx("label", Object.assign({ htmlFor: "search", className: "block text-sm font-medium leading-6 text-gray-900" }, { children: "Search" })), _jsx("div", Object.assign({ className: "relative mt-2 flex items-center" }, { children: _jsx("input", { type: "text", name: "search", id: "search", value: searchText, onChange: (e) => setSearchText(e.target.value), className: "block w-full rounded-md border-0 py-1.5 pr-14 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" }) }))] }), _jsxs(RadioGroup, Object.assign({ value: selection, onChange: selectOption }, { children: [_jsx(RadioGroup.Label, Object.assign({ className: "sr-only" }, { children: "Privacy setting" })), _jsx("div", Object.assign({ className: "-space-y-px rounded-md bg-white" }, { children: filteredOptions.map((option, settingIdx) => (_jsx(RadioGroup.Option, Object.assign({ value: option, className: ({ checked }) => classNames(settingIdx === 0 ? "rounded-tl-md rounded-tr-md" : "", settingIdx === filteredOptions.length - 1
                                ? "rounded-bl-md rounded-br-md"
                                : "", checked
                                ? "z-10 border-indigo-200 bg-indigo-50"
                                : "border-gray-200", "relative flex cursor-pointer border p-4 focus:outline-none") }, { children: ({ active, checked }) => (_jsxs("div", Object.assign({ className: "flex flex-col" }, { children: [_jsxs("div", Object.assign({ className: "flex flex-row" }, { children: [_jsx("span", Object.assign({ className: classNames(checked
                                                    ? "bg-indigo-600 border-transparent"
                                                    : "bg-white border-gray-300", active ? "ring-2 ring-offset-2 ring-indigo-600" : "", "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"), "aria-hidden": "true" }, { children: _jsx("span", { className: "rounded-full bg-white w-1.5 h-1.5" }) })), _jsxs("span", Object.assign({ className: "ml-3 flex flex-col" }, { children: [_jsx(RadioGroup.Label, Object.assign({ as: "span", className: classNames(checked ? "text-indigo-900" : "text-gray-900", "block text-sm font-medium") }, { children: option.name })), _jsx(RadioGroup.Description, Object.assign({ as: "span", className: classNames(checked ? "text-indigo-700" : "text-gray-500", "block text-sm") }, { children: option.description }))] }))] })), _jsx("div", Object.assign({ className: "flex mt-2" }, { children: option.content || null }))] }))) }), option.name))) }))] }))] })));
}
