var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { PencilIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import * as endpoints from "../common/endpoints";
import BaseModal from "../base_modal";
/**
 * Call Agenda tab in the Call Launch page for calls in the past.
 * It mainly displays the notes from the call transcript.
 * The UI is extremely similar to the call_agenda_future_calls.tsx & in fact there is lot of code duplication from there. We should refactor them to remove code duplication.
 */
export default ({ transcript, call, updateTranscript }) => {
    const [playbookItemIdBeingEdited, setPlaybookItemIdBeingEdited] = useState(null);
    const [allPlaybooks, setAllPlaybooks] = useState([]);
    useEffect(() => {
        endpoints.getPlaybooks().then(setAllPlaybooks);
    }, []);
    const groupNotesByPlaybook = (notes) => {
        // Sort gives priority to the current playbook in the case where one item is part of multiple playbooks
        const sortedPlaybooks = allPlaybooks.sort((a, b) => {
            if (a.name === transcript.playbookName)
                return -1;
            if (b.name === transcript.playbookName)
                return 1;
            return 0;
        });
        return notes.reduce((acc, note) => {
            var _a;
            const playbookId = ((_a = sortedPlaybooks.find((playbook) => playbook.playbook_item_groups
                .map((itemGroup) => itemGroup.default_item.id)
                .includes(note.playbook_item_id))) === null || _a === void 0 ? void 0 : _a.id) || -1;
            if (!acc[playbookId]) {
                acc[playbookId] = [];
            }
            acc[playbookId].push(note);
            return acc;
        }, {});
    };
    const renderNotes = (notes) => {
        const notesGrouped = groupNotesByPlaybook(notes);
        return Object.entries(notesGrouped).map(([playbookId, notes]) => {
            const playbook = allPlaybooks.find((playbook) => playbook.id === parseInt(playbookId, 10));
            return (_jsxs("div", Object.assign({ className: "self-stretch p-4 bg-white rounded-lg border border-neutral-200 flex-col justify-start items-start gap-4 flex mb-4" }, { children: [_jsx("div", Object.assign({ className: "self-stretch justify-start items-center gap-2 inline-flex" }, { children: _jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsx("div", Object.assign({ className: "text-base font-bold leading-normal" }, { children: playbook === null || playbook === void 0 ? void 0 : playbook.name })), _jsx("div", Object.assign({ className: "flex ml-2 px-2 py-0.5 items-center space-x-2 rounded-lg border border-wds-gray-3 bg-wds-gray-1" }, { children: _jsx("div", Object.assign({ className: "text-[14px] text-wds-black font-lato leading-20 px-2 flex gap-2 items-center" }, { children: "Trackers" })) }))] })) })), notes.map((note) => {
                        const isDone = Boolean(note.notes.length);
                        return (_jsxs("div", Object.assign({ className: "self-stretch p-4 bg-white rounded-lg border border-neutral-200 flex-col justify-start items-start gap-2 flex" }, { children: [_jsxs("div", Object.assign({ className: "self-stretch justify-start items-center inline-flex gap-2" }, { children: [_jsx("div", Object.assign({ className: "font-bold leading-snug grow" }, { children: note.question })), _jsxs("div", Object.assign({ className: "flex items-center gap-2" }, { children: [isDone ? (_jsxs("div", Object.assign({ className: "flex items-center gap-1 whitespace-nowrap" }, { children: [_jsx("div", Object.assign({ className: "text-emerald-600 truncate" }, { children: "Done" })), _jsx(CheckCircleIcon, { className: "w-4 h-4 text-emerald-600" })] }))) : (_jsxs("div", Object.assign({ className: "flex items-center gap-1 whitespace-nowrap" }, { children: [_jsx("div", Object.assign({ className: "text-red-700 truncate" }, { children: "Not Done" })), _jsx(XCircleIcon, { className: "w-4 h-4 text-red-700" })] }))), _jsx("button", Object.assign({ className: "p-2 rounded-full justify-center items-center gap-2 flex hover:bg-blue-100", onClick: () => setPlaybookItemIdBeingEdited(note.playbook_item_id) }, { children: _jsx(PencilIcon, { className: "w-4 h-4 relative" }) }))] }))] })), _jsx("div", Object.assign({ className: "self-stretch text-zinc-800 font-normal leading-tight whitespace-pre-wrap" }, { children: note.notes.map(({ content }, index) => {
                                        return _jsx("div", { children: content }, index);
                                    }) }))] }), note.playbook_item_id));
                    })] })));
        });
    };
    if (!transcript.playbookName) {
        return (_jsx("div", Object.assign({ className: "w-full rounded-lg flex-col justify-start items-start gap-4 inline-flex overflow-y-auto" }, { children: _jsx("div", Object.assign({ className: "self-stretch p-4 bg-white rounded-lg border border-neutral-200 flex-col justify-start items-start gap-4 flex" }, { children: "No trackers were added to this call." })) })));
    }
    return (_jsxs("div", Object.assign({ className: "w-full rounded-lg flex-col justify-start items-start inline-flex overflow-y-auto px-8" }, { children: [renderNotes(transcript.notes), playbookItemIdBeingEdited && (_jsx(BaseModal, { title: "Edit Note", description: "", open: Boolean(playbookItemIdBeingEdited), setOpen: (open) => {
                    if (!open) {
                        setPlaybookItemIdBeingEdited(null);
                    }
                }, submitCallback: (fields) => __awaiter(void 0, void 0, void 0, function* () {
                    // Find the note that matches the playbook item being edited
                    const noteToEdit = transcript.notes.find((note) => note.playbook_item_id === playbookItemIdBeingEdited);
                    // Create a new note with updated contents
                    const updatedNote = Object.assign(Object.assign({}, noteToEdit), { notes: noteToEdit.notes.map((note, i) => (Object.assign(Object.assign({}, note), { content: fields[i].value }))) });
                    // Create a new transcript with the updated note
                    const updatedTranscript = Object.assign(Object.assign({}, transcript), { notes: transcript.notes.map((note) => note.playbook_item_id === playbookItemIdBeingEdited
                            ? updatedNote
                            : note) });
                    // Save the updated note
                    yield endpoints.saveNote(call.id, playbookItemIdBeingEdited, updatedNote.notes);
                    // Update the transcript and reset the playbook item being edited
                    updateTranscript(updatedTranscript);
                    setPlaybookItemIdBeingEdited(null);
                }), fields: transcript.notes
                    .find((note) => note.playbook_item_id === playbookItemIdBeingEdited)
                    .notes.map((note) => ({
                    name: "Note",
                    type: "text",
                    value: note.content,
                })), submitText: "Save" }))] })));
};
