import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useReactTable, getCoreRowModel, flexRender, getFilteredRowModel, } from "@tanstack/react-table";
import { ChevronRightIcon, MagnifyingGlassIcon, PlusIcon, } from "@heroicons/react/24/outline";
// T is the generic type for a table row, can be instance of any database models, example LiveCall, Tenant, etc.
// Using generics ensures that the all the rows in the table have the same type. see: https://www.freecodecamp.org/news/how-typescript-generics-work/
// How it works:
// 1. We create a Tanstack table with the columns and rows
// 2. Tanstack gives us a bunch of helper functions to render the table
// 3. We use the helper functions to render the table
// The helper functions include:
// 1. getHeaderGroups: to get the header groups, this method is used to render the headers, this is mainly the column names
// 2. getRowModel: to get the row model, this method is used to get the row model from the table, it returns the rows and the cells in them
// 3. flexRender: to render the table, this method is used to render the content of the table cells, this content is derived from the row model (T) & the column definitions
const BaseTable2 = (props) => {
    const { title, columns, rows, expandCallback, addCallback, additionalActions, } = props;
    const [globalFilter, setGlobalFilter] = useState("");
    const table = useReactTable({
        data: rows,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        filterFns: {
            wiserCrmTableFilterFn: (row, column, value) => {
                return true;
            },
        },
        globalFilterFn: "includesString",
        state: {
            globalFilter: globalFilter,
        },
        enableGlobalFilter: true,
    });
    return (_jsxs("div", Object.assign({ className: "w-full h-screen pl-4 pr-12 py-4 bg-neutral-100" }, { children: [_jsx("div", Object.assign({ className: "w-full flex flex-col" }, { children: _jsx("div", Object.assign({ className: "flex justify-between items-center bg-neutral-100" }, { children: _jsx("div", Object.assign({ className: "mt-4" }, { children: _jsx("div", Object.assign({ className: "w-60 text-black text-3xl" }, { children: title })) })) })) })), _jsx("div", Object.assign({ className: "w-full justify-end inline-flex items-end" }, { children: _jsxs("div", Object.assign({ className: "flex gap-4" }, { children: [additionalActions === null || additionalActions === void 0 ? void 0 : additionalActions.map((action) => (_jsx("button", Object.assign({ className: "px-3 bg-blue-600 hover:bg-sky-800 rounded-full justify-start items-center gap-2 flex text-white", onClick: () => action.callback(), disabled: action.disabled }, { children: action.label })))), _jsxs("div", Object.assign({ className: "w-60 px-3 bg-white rounded-lg border border-zinc-200 justify-start items-center flex" }, { children: [_jsx(MagnifyingGlassIcon, { className: "text-gray-400 w-5 h-5 relative" }), _jsx("input", { className: "focus:outline-none border-0 focus:ring-0 placeholder:text-gray-400 text-sm", placeholder: "Search", onChange: (e) => {
                                        setGlobalFilter(e.target.value);
                                    } })] })), addCallback ? (_jsxs("button", Object.assign({ className: "px-3 bg-blue-600 hover:bg-sky-800 rounded-full justify-start items-center gap-2 flex", onClick: addCallback }, { children: [_jsx("div", Object.assign({ className: "text-white text-sm font-bold leading-tight" }, { children: "Create" })), _jsx(PlusIcon, { className: "text-white w-4 h-4 relative" })] }))) : null] })) })), _jsx("div", Object.assign({ className: "w-full flex flex-col mt-4" }, { children: _jsx("div", Object.assign({ className: "rounded-lg border border-solid border-gray-300" }, { children: _jsxs("table", Object.assign({ className: "w-full bg-white rounded-lg" }, { children: [_jsx("thead", { children: table.getHeaderGroups().map((headerGroup) => (_jsxs("tr", Object.assign({ className: "[&>*:first-child]:rounded-tl-lg [&>*:last-child]:rounded-tr-lg [&>*:not(:last-child)]:border-r" }, { children: [headerGroup.headers.map((header) => (_jsx("th", Object.assign({ className: "font-bold text-gray-500 bg-gray-100 p-2 border-gray-300" }, { children: _jsx("div", Object.assign({ className: "flex flex-row items-center justify-between self-stretch px-2 gap-4" }, { children: header.isPlaceholder
                                                    ? null
                                                    : flexRender(header.column.columnDef.header, header.getContext()) })) }), header.id))), expandCallback ? (_jsx("th", { className: "font-bold text-gray-500 bg-gray-100 p-2 border-gray-300" })) : null] }), headerGroup.id))) }), _jsx("tbody", { children: table.getRowModel().rows.map((row) => (_jsxs("tr", Object.assign({ className: "[&>*:first-child]:rounded-bl-lg [&>*:last-child]:rounded-br-lg [&>*:not(:last-child)]:border-r" }, { children: [row.getVisibleCells().map((cell, index) => (_jsx("td", Object.assign({ className: "border-t border-t-gray-100 p-2 bg-white" }, { children: flexRender(cell.column.columnDef.cell, cell.getContext()) }), cell.id))), expandCallback ? (_jsx("td", Object.assign({ className: "text-gray-500 border-t border-t-gray-100 bg-white text-center w-12" }, { children: _jsx("button", Object.assign({ className: "rounded-full justify-center items-center inline-flex hover:bg-gray-200 w-8 h-8", onClick: () => {
                                                    expandCallback(row.original);
                                                }, "aria-label": "Edit" }, { children: _jsx(ChevronRightIcon, { className: "w-5 h-5 relative" }) })) }))) : null] }), row.id))) })] })) })) }))] })));
};
export default BaseTable2;
