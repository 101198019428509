import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Badge from "../common/badge";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import useFeatureFlagEnabled from "../use_feature_flag_enabled";
import { LoadingEllipsis } from "../common/loading_ellipsis";
const getTagStyles = (tag) => {
    if (tag.toLowerCase() === "pricing") {
        return "bg-green-100 text-green-700";
    }
    else if (tag.toLowerCase() === "competitors") {
        return "bg-yellow-100 text-yellow-700";
    }
    else if (tag.toLowerCase() === "pain points") {
        return "bg-red-100 text-red-700";
    }
    else if (tag.toLowerCase().includes("product")) {
        return "bg-indigo-100 text-indigo-700";
    }
    else if (tag.toLowerCase().includes("feedback")) {
        return "bg-purple-100 text-purple-700";
    }
    return "bg-blue-100 text-blue-700";
};
export const RunningSummary = (props) => {
    const renderTags = useFeatureFlagEnabled("RENDER_TAGS_LIVE_CALL_SUMMARY");
    const startTimeOfSummaries = Math.min(...props.summaries.map((summary) => summary.start_msec));
    const renderEmptySummary = () => {
        return (_jsx("div", Object.assign({ className: "p-2" }, { children: _jsx("span", Object.assign({ className: "font-normal text-sm text-black" }, { children: "No summary available" })) })));
    };
    const renderSummary = (summary) => {
        const tagsToRender = summary.summary_tags.filter((tag) => tag && tag.toLowerCase() !== "no tag");
        return (_jsx("div", Object.assign({ className: "flex flex-row items-start justify-between gap-2" }, { children: _jsxs("div", Object.assign({ className: "p-2 bg-gray-100 rounded-lg flex flex-col gap-2 whitespace-pre-wrap w-full" }, { children: [renderTags && tagsToRender.length > 0 && (_jsx("div", Object.assign({ className: "flex items-center gap-2" }, { children: tagsToRender.map((tag, index) => (_jsx(Badge, { text: tag, classes: getTagStyles(tag), textClasses: "text-xs" }))) }))), summary.id === 0 && _jsx(LoadingEllipsis, {}), _jsx("div", { className: "font-normal text-sm text-black", dangerouslySetInnerHTML: { __html: summary.summary_html.trim() } })] })) }), summary.id));
    };
    if (props.summaries.length === 0) {
        return renderEmptySummary();
    }
    return (_jsxs("div", Object.assign({ className: "flex flex-col gap-4 pt-2 px-6 pb-6 bg-white w-full h-fit" }, { children: [_jsxs("div", Object.assign({ className: "flex flex-col gap-1" }, { children: [_jsx("span", Object.assign({ className: "font-bold text-lg" }, { children: "Key points" })), _jsxs("div", Object.assign({ className: "flex items-center gap-1" }, { children: [_jsx("span", Object.assign({ className: "bg-clip-text text-transparent bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500" }, { children: "AI generated" })), _jsx(InformationCircleIcon, { className: "h-4 w-4 text-pink-500" })] }))] })), _jsx("div", Object.assign({ className: "flex flex-col gap-4 h-full" }, { children: props.summaries.map((summary) => renderSummary(summary)) }))] })));
};
