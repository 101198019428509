/**
 * Creates a debounced function that delays invoking `func` until after `delay` milliseconds
 * have elapsed since the last time the debounced function was invoked. The debounced function
 * comes with a context (`this` binding) and supports passing arguments to the `func`.
 *
 * @param func The function to debounce.
 * @param delay The number of milliseconds to delay.
 * @returns The new debounced function.
 *
 * Note: lodash's implementation is more extensive, if in future we find this implemetation
 * inadequate we can use lodash's debounce instead. For now, this works.
 */
export function debounce(func, delay) {
    let timer;
    return function (...args) {
        const context = this;
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(context, args);
        }, delay);
    };
}
