var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tab } from "@headlessui/react";
import classNames from "../class_names";
import CallAgendaFutureCall from "./call_agenda_future_call";
import CallAgendaPastOrOngoingCall from "./call_agenda_past_or_ongoing_call";
import CallDetailsPanel from "./call_details_panel";
import { useEffect, useMemo, useRef, useState } from "react";
import * as endpoints from "../common/endpoints";
import AddPlaybookModal from "../add_playbook_modal";
import PreCallPrep from "../pre_call_prep";
import HomePageManualNotes from "./home_page_manual_notes";
import { ChatModal } from "../post_call/chat_modal";
import useFeatureFlagEnabled from "../use_feature_flag_enabled";
import Transcript from "../live_call/transcript";
/**
 * Main call content of the call launch page. Contains Different tabs like Summary (transript), Agenda (playbooks), & more in future.
 *
 * @param {LiveCall} call - The current live call object.
 * @param {(call: LiveCall) => void} updateSelectedCall - Passes updated call object to the parent component if any call attributes change.
 */
export default ({ call, updateSelectedCall, fetchUpdatedCalls, }) => {
    var _a;
    const [transcript, setTranscript] = useState(null);
    const [playbook, setPlaybook] = useState(null);
    const [addPlaybookModalOpen, setAddPlaybookModalOpen] = useState(false);
    const chatModalEnabled = useFeatureFlagEnabled("CHAT_ON_HOME_PAGE");
    // "Are we before or after the call"
    // We don't rely on call time because plenty of calls happen out of sync with the planned call (often late, sometimes early)
    // following is a heuristic to determine if the call happened or is in future
    const isFutureCall = !(transcript === null || transcript === void 0 ? void 0 : transcript.transcript.length);
    useEffect(() => {
        endpoints.getCallTranscript(call.id).then(setTranscript);
        endpoints.getPlaybookForLiveCall(call.id).then(setPlaybook);
    }, [call]);
    const tabs = [
        { name: "Research" },
        { name: "Trackers" },
        { name: "Notes" },
        { name: "Call details" },
        { name: "Transcript" },
    ];
    const deletePlaybook = () => __awaiter(void 0, void 0, void 0, function* () {
        yield updateCallPlaybook(null);
    });
    const updatePlaybookGoalsOrder = (order) => __awaiter(void 0, void 0, void 0, function* () {
        if (!playbook)
            return;
        const reorderedItems = order.map((id) => playbook.playbook_item_groups.find((item) => item.id === id));
        setPlaybook(Object.assign(Object.assign({}, playbook), { playbook_item_groups: reorderedItems }));
        yield endpoints.reorderPlaybookGroupsInLiveCall(call.id, order);
    });
    const updateCallPlaybook = (playbook) => __awaiter(void 0, void 0, void 0, function* () {
        setPlaybook(playbook);
        const selectedPlaybookId = playbook ? playbook.id : null;
        const { call: updatedCall } = yield endpoints.setCallPlaybook(call.id, selectedPlaybookId);
        if (!playbook) {
            // playbook has been cleared
            updateSelectedCall(updatedCall);
            return;
        }
        updateSelectedCall(updatedCall);
    });
    const deletePlaybookItemGroup = (id) => __awaiter(void 0, void 0, void 0, function* () {
        if (!playbook)
            return;
        const updatedItems = playbook.playbook_item_groups.filter((item) => item.id !== id);
        yield endpoints.removePlaybookGroupFromLiveCall(call.id, id);
        setPlaybook(Object.assign(Object.assign({}, playbook), { playbook_item_groups: updatedItems }));
    });
    const onAddPlaybook = (playbook) => __awaiter(void 0, void 0, void 0, function* () {
        yield updateCallPlaybook(playbook);
    });
    const addPlaybookItemGroup = (playbookItemGroupId) => __awaiter(void 0, void 0, void 0, function* () {
        if (!playbook)
            return;
        yield endpoints.addPlaybookGroupToLiveCall(call.id, playbookItemGroupId);
        const updatedPlaybook = yield endpoints.getPlaybookForLiveCall(call.id);
        setPlaybook(updatedPlaybook);
    });
    const roleSwitcherRef = useRef(null);
    const transcriptEntries = useMemo(() => {
        if (transcript) {
            return transcript.transcript;
        }
        return [];
    }, [transcript]);
    const firstTranscriptMsec = ((_a = transcriptEntries[0]) === null || _a === void 0 ? void 0 : _a.startTime) || 0;
    return (_jsxs(Tab.Group, Object.assign({ as: "div", className: "grow" }, { children: [_jsx("div", Object.assign({ className: "w-full pl-4 pr-8 h-[38px] justify-between items-center inline-flex" }, { children: _jsx(Tab.List, Object.assign({ className: "bg-white justify-start items-center flex" }, { children: tabs.map((tab) => (_jsx(Tab, Object.assign({ className: ({ selected }) => classNames("outline-none px-4 py-2 justify-start items-center flex rounded-tl-lg rounded-tr-lg border-blue-600", selected
                            ? "border-b-2 font-bold"
                            : "hover:bg-blue-100 font-normal") }, { children: _jsx("div", Object.assign({ className: classNames("leading-snug") }, { children: tab.name })) }), tab.name))) })) })), _jsxs(Tab.Panels, Object.assign({ className: "flex flex-col grow w-full h-full pt-8 bg-wds-gray-1" }, { children: [_jsx(Tab.Panel, { children: _jsx(PreCallPrep, { call: call }) }), _jsx(Tab.Panel, { children: isFutureCall ? (_jsx(CallAgendaFutureCall, { playbook: playbook, addPlaybook: () => setAddPlaybookModalOpen(true), deletePlaybook: deletePlaybook, updatePlaybookGoalsOrder: updatePlaybookGoalsOrder, deletePlaybookItemGroup: deletePlaybookItemGroup, addPlaybookItemGroup: addPlaybookItemGroup, updateCallPlaybook: updateCallPlaybook })) : (_jsx(CallAgendaPastOrOngoingCall, { call: call, transcript: transcript, updateTranscript: setTranscript })) }), _jsx(Tab.Panel, { children: _jsx("div", Object.assign({ className: "px-8" }, { children: _jsx(HomePageManualNotes, { callId: call.id }) })) }), _jsx(Tab.Panel, { children: _jsx(CallDetailsPanel, { call: call, fetchUpdatedCalls: fetchUpdatedCalls }) }), _jsx(Tab.Panel, { children: _jsx(Transcript, { isLive: false, transcriptEntries: transcriptEntries, startTimeMsec: firstTranscriptMsec, speakerToRoleMap: new Map(), roleSwitcherRef: roleSwitcherRef, scrollToBottom: true, setScrollToBottom: () => { } }) })] })), _jsx(AddPlaybookModal, { addPlaybookModalOpen: addPlaybookModalOpen, setAddPlaybookModalOpen: setAddPlaybookModalOpen, addPlaybook: (playbook) => onAddPlaybook(playbook) }), chatModalEnabled && _jsx(ChatModal, { call: call })] })));
};
