var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { PaperAirplaneIcon, XMarkIcon, ChevronLeftIcon, } from "@heroicons/react/24/outline";
import { useEffect, useRef, useState } from "react";
import Message from "./message";
import { recordCardEvent } from "../common/endpoints";
import { useStreamSocket, } from "../common/stream_socket";
import { markLatestActivityTime } from "../activity_utils";
import CallAssistanceCarousel from "./call_assistance_carousel";
import ChatSourceMenu, { CHAT_SOURCES, ChatSourceMenuItems, } from "./chat_source_menu";
import { Menu } from "@headlessui/react";
import WaitingSpinner from "../waiting_spinner";
import classNames from "../class_names";
const LiveCardsChatBot = ({ allCards, callId, clientId, chatMessages, addChatMessage, setCollapse, defaultChatSource, socketReady, allLlmOutputFeedback, updateLlmOutputFeedbackMutation, deleteLlmOutputFeedbackMutation, }) => {
    const [userMessage, setUserMessage] = useState("");
    const streamSocket = useStreamSocket();
    const [showMoreSuggestions, setShowMoreSuggestions] = useState(false);
    const [selectedSource, setSelectedSource] = useState(defaultChatSource !== null && defaultChatSource !== void 0 ? defaultChatSource : CHAT_SOURCES[0]);
    const [chatSourceOptionsVisible, setChatSourceOptionsVisible] = useState(false);
    // These refs help in moving the focus to and from the chat source selector.
    // The inputRef points to the input query box, and the chatSourceLastMenuItemRef
    // points to the last item in the chat source selector menu.
    // When the user types '/' in the input query, it opens up the chat source
    // selector menu, and if the user presses "ArrowUp" or "ArrowDown" keys,
    // we move the focus to the menu items to allow navigating between them.
    // And when the user selects one of the sources, we move the focus back to the
    // input box so that user can continue typing their query.
    const inputRef = useRef(null);
    const chatSourceLastMenuItemRef = useRef(null);
    // For jwt token refresh
    markLatestActivityTime();
    const addCardToChat = (card) => {
        addChatMessage({ sender: "User", messageContent: card.name });
        addChatMessage({ sender: "Bot", messageType: "Card", card: card });
        card.clicked = true;
        // Record the card click event in a non-blocking manner
        if (callId) {
            recordCardEvent(card, callId, "CLICK");
        }
    };
    const addUserMessageToChatAndRespond = (userMessage, forceSource) => __awaiter(void 0, void 0, void 0, function* () {
        setUserMessage("");
        userMessage = userMessage.trim();
        if (!userMessage.length) {
            return;
        }
        addChatMessage({ sender: "User", messageContent: userMessage });
        addChatMessage({
            sender: "Bot",
            messageType: "Loading",
        });
        setShowMoreSuggestions(false);
        streamSocket.send(JSON.stringify({
            type: "get_best_card_from_string_input",
            inputString: userMessage,
            forceSource: forceSource !== null && forceSource !== void 0 ? forceSource : selectedSource.id,
            clientId: clientId,
        }));
    });
    const addCoachingPromptsToChatAndRespond = (prompt) => __awaiter(void 0, void 0, void 0, function* () {
        const { inputString, overrideTitle, autodetectCustomerQuestion, useFastModel, forceSource, } = prompt;
        if (!inputString.length) {
            return;
        }
        setShowMoreSuggestions(false);
        addChatMessage({ sender: "User", messageContent: overrideTitle });
        addChatMessage({
            sender: "Bot",
            messageType: "Loading",
        });
        const delay = autodetectCustomerQuestion ? 1000 : 0;
        setTimeout(() => {
            streamSocket.send(JSON.stringify({
                type: "get_best_card_from_string_input",
                inputString,
                overrideTitle,
                autodetectCustomerQuestion,
                useFastModel,
                forceSource,
                clientId: clientId,
            }));
        }, delay);
    });
    const scrollContainerRef = useRef(null);
    useEffect(() => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTop =
                scrollContainerRef.current.scrollHeight;
        }
    }, [allCards, showMoreSuggestions, chatMessages]);
    useEffect(() => {
        if (userMessage.startsWith("/")) {
            setChatSourceOptionsVisible(true);
        }
        else {
            setChatSourceOptionsVisible(false);
        }
    }, [userMessage]);
    const ChatTextEntry = (_jsxs("div", Object.assign({ className: "w-full self-stretch flex-col h-24 my-4 px-2 py-3 rounded-lg border border-gray-300 bg-wds-gray-1 justify-between" }, { children: [_jsx("input", { ref: inputRef, type: "text", className: "border-none w-full basis-0 bg-wds-gray-1 overflow-hidden text-black font-lato text-sm font-normal outline-none p-2 focus:ring-0", placeholder: 'Ask Wiser anything. Type "/" for more commands.', onChange: (e) => setUserMessage(e.target.value), value: userMessage, onKeyDown: (e) => {
                    if (chatSourceOptionsVisible) {
                        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                            if (chatSourceLastMenuItemRef.current) {
                                chatSourceLastMenuItemRef.current.focus();
                            }
                        }
                    }
                    if (e.key === "Enter" && socketReady) {
                        addUserMessageToChatAndRespond(userMessage);
                    }
                } }), chatSourceOptionsVisible && (_jsx(Menu, Object.assign({ as: "div", className: "relative flex flex-auto" }, { children: _jsx("div", Object.assign({ className: "min-w-full absolute bottom-10 z-10" }, { children: _jsx(ChatSourceMenuItems, { selectedSource: selectedSource, setSelectedSource: (chatSource) => {
                            setChatSourceOptionsVisible(false);
                            setUserMessage("");
                            setSelectedSource(chatSource);
                            // Move the focus back to input box after selecting
                            // the chat source.
                            if (inputRef.current) {
                                inputRef.current.focus();
                            }
                        }, filterQuery: userMessage.slice(1), openByDefault: true, lastMenuItemRef: chatSourceLastMenuItemRef }) })) }))), _jsxs("div", Object.assign({ className: "flex flex-row justify-between items-center" }, { children: [_jsx(ChatSourceMenu, { selectedSource: selectedSource, setSelectedSource: setSelectedSource }), _jsx("button", Object.assign({ className: "p-2 origin-top-left rounded-full justify-center text-blue-600 items-center flex hover:bg-blue-100", disabled: !socketReady, onClick: () => addUserMessageToChatAndRespond(userMessage) }, { children: _jsx(PaperAirplaneIcon, { className: "w-5 h-5 relative" }) }))] }))] })));
    const ChatHeader = () => {
        return (_jsxs("div", Object.assign({ className: classNames("flex w-full items-center self-stretch justify-between py-2 px-4 text-white rounded", 
            // Tailwind doesn't support radial gradient utility class yet
            "bg-[radial-gradient(141.42%_141.42%_at_100%_0%,_theme(colors.purple.600)_0%,_theme(colors.blue.600)_100%)]") }, { children: [_jsx("div", Object.assign({ className: "text-base font-bold" }, { children: "Ask Wiser anything" })), _jsx("div", Object.assign({ className: "flex self-stretch items-center shrink-0" }, { children: _jsx("button", Object.assign({ onClick: () => { }, className: "hidden md:flex" }, { children: _jsx(XMarkIcon, { className: "h-5 w-5 shrink-0", onClick: () => setCollapse(true) }) })) }))] })));
    };
    const sugestionsOrChatHeader = () => {
        if (!socketReady)
            return _jsx(ChatHeader, {});
        if (showMoreSuggestions) {
            return (_jsxs("button", Object.assign({ className: "flex items-center p-4", onClick: () => setShowMoreSuggestions(false) }, { children: [_jsx(ChevronLeftIcon, { className: "h-4 w-4 text-black stroke-2" }), _jsx("div", Object.assign({ className: "text-black text-normal font-bold leading-normal ml-2" }, { children: "More Suggestions" }))] })));
        }
        return _jsx(ChatHeader, {});
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: "flex justify-between items-center w-full" }, { children: sugestionsOrChatHeader() })), _jsxs("div", Object.assign({ className: "flex flex-col w-full bg-white h-full flex-grow md:overflow-y-auto rounded-lg px-4", ref: scrollContainerRef }, { children: [!!!showMoreSuggestions && (_jsx("div", Object.assign({ className: "w-full" }, { children: chatMessages.map((message, index) => (_jsx(Message, { message: message, addUserMessageToChatAndRespond: addUserMessageToChatAndRespond, userLlmOutputFeedback: message.sender === "Bot" &&
                                message.messageType === "Card" &&
                                message.card.provenanceLogId
                                ? allLlmOutputFeedback === null || allLlmOutputFeedback === void 0 ? void 0 : allLlmOutputFeedback.find((f) => f.provenance_log_id === message.card.provenanceLogId)
                                : undefined, updateLlmOutputFeedbackMutation: updateLlmOutputFeedbackMutation, deleteLlmOutputFeedbackMutation: deleteLlmOutputFeedbackMutation }, index))) }))), !socketReady ? (_jsx("div", Object.assign({ className: "flex flex-col h-full w-full flex items-center justify-center" }, { children: _jsx(WaitingSpinner, { text: "Connecting... may need to refresh page." }) }))) : (_jsx(CallAssistanceCarousel, { addCoachingPromptsToChatAndRespond: addCoachingPromptsToChatAndRespond, showMoreSuggestions: setShowMoreSuggestions, showingMoreSuggestions: showMoreSuggestions, suggestionSpace: chatMessages.length < 2 ? 4 : 1, allCards: allCards, addCardToChat: addCardToChat })), _jsx("div", Object.assign({ className: "md:hidden" }, { children: ChatTextEntry }))] })), _jsx("div", Object.assign({ className: "hidden md:flex md:w-full md:px-4" }, { children: ChatTextEntry }))] }));
};
export default LiveCardsChatBot;
