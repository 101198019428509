var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// This file contains the component for rendering field mapping card details
// for a post-call output item.
// This is used for all integrations, and has support for showing/hiding the
// current field value.
import classNames from "../class_names";
import { ArrowUturnLeftIcon, ChevronDownIcon, ChevronRightIcon, } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { FieldMappingNewValue } from "./field_mapping_new_value";
import { INTEGRATION_DETAILS } from "./data_processing_config";
const FieldMappingCardContent = ({ imageUrl, contentTitle, contentBody, }) => {
    return (_jsxs("div", Object.assign({ className: "inline-flex gap-4 pt-2 items-start" }, { children: [_jsxs("div", Object.assign({ className: "w-32 text-gray-500 font-medium text-sm shrink-0" }, { children: [imageUrl && _jsx("img", { src: imageUrl, className: "w-5 h-5" }), contentTitle] })), _jsx("div", Object.assign({ className: "self-stretch grow text-black font-normal whitespace-pre-wrap line-clamp-5 hover:line-clamp-none" }, { children: contentBody }))] })));
};
export const FieldMappingCardValue = (props) => {
    var _a;
    const [expanded, setExpanded] = useState(false);
    const [showUndoButton, setShowUndoButton] = useState(false);
    useEffect(() => {
        setShowUndoButton(props.undoStack.length > 0);
    }, [props.undoStack]);
    const onExpandIconClick = () => {
        setEditNewValue(false);
        setExpanded(!expanded);
    };
    const [editNewValue, setEditNewValue] = useState(false);
    const onNewValueEdit = (content) => __awaiter(void 0, void 0, void 0, function* () {
        yield props.onDataItemValueUpdate(content, /* undoClicked= */ false);
    });
    const onUndoClick = () => {
        props.onDataItemValueUpdate(props.undoStack.pop(), 
        /* undoClicked= */ true);
        setShowUndoButton(props.undoStack.length > 0);
    };
    const itemIgnored = props.postCallOutputItem.state === "IGNORED";
    return (_jsxs("div", Object.assign({ className: "flex items-start gap-4 pb-2" }, { children: [_jsx("button", Object.assign({ className: "p-2 text-gray-400 hover:cursor-pointer hover:outline-none hover:rounded-full hover:bg-blue-100", onClick: onExpandIconClick }, { children: expanded ? (_jsx(ChevronDownIcon, { className: "w-5 h-5" })) : (_jsx(ChevronRightIcon, { className: "w-5 h-5" })) })), _jsxs("div", Object.assign({ className: classNames("inline-flex flex-col gap-2 pt-2 items-stretch flex-1", expanded && showUndoButton ? "pr-4" : "pr-12", itemIgnored ? "opacity-50" : "") }, { children: [_jsx(FieldMappingNewValue, { editNewValue: editNewValue, setEditNewValue: (value) => {
                            setEditNewValue(value);
                            props.toggleDataItemEditOpen(value);
                        }, postCallOutputItem: props.postCallOutputItem, onNewValueChange: onNewValueEdit, fieldSchema: props.fieldSchema, expanded: expanded }), expanded && (_jsxs(_Fragment, { children: [props.showCurrentFieldValue && (_jsxs(_Fragment, { children: [_jsx("div", { className: "pt-2 border-b border-b-gray-200" }), _jsx(FieldMappingCardContent, { imageUrl: (_a = Array.from(INTEGRATION_DETAILS.values()).find((i) => i.name === props.postCallOutputItem.target)) === null || _a === void 0 ? void 0 : _a.icon_url, contentTitle: "Current field value", contentBody: props.postCallOutputItem.currentValue })] })), props.showTrackerNotes && (_jsxs(_Fragment, { children: [_jsx("div", { className: "pt-2 border-b border-b-gray-200" }), _jsx(FieldMappingCardContent, { imageUrl: "https://storage.googleapis.com/wiser-ai-public2/wiser_logo_color.png", contentTitle: "Content captured by Wiser", contentBody: props.postCallOutputItem.contentCaptured })] }))] }))] })), (props.postCallOutputItem.state === "PENDING" ||
                props.postCallOutputItem.state === "FAILED") &&
                showUndoButton && (_jsx("button", Object.assign({ className: "py-2 pr-2 text-gray-400 hover:cursor-pointer hover:outline-none hover:rounded-full hover:bg-blue-100", onClick: onUndoClick }, { children: _jsx(ArrowUturnLeftIcon, { className: "w-5 h-5" }) })))] })));
};
