var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PostCallOutputTarget, } from "../types";
import { useEffect, useMemo, useRef, useState } from "react";
import * as endpoints from "../common/endpoints";
import { isPostCallOutputEligibleForExecuting, isTenantConnectedToExternalCrm, } from "./utils";
import useIntegrations from "./use_integrations";
import FieldMappingCard from "./field_mapping_card";
import { FieldMappingCardSummary } from "./field_mapping_card";
import usePostCallOutputs from "./use_post_call_outputs";
import * as actions from "./actions";
import { INTERNAL_MESSAGING_TARGETS } from "./data_processing_config";
const DataProcessingTab = (props) => {
    const { postCallOutputMap, updatePostCallOutputInState, fetchPostCallOutput, } = usePostCallOutputs({
        callId: props.call.id,
    });
    const [connectedIntegrations, setConnectedIntegrations] = useState([]);
    const [integrationsData, setIntegrationsData] = useState([]);
    const [fetchedIntegrationsData, setFetchedIntegrationsData] = useState(false);
    const [undoStack, setUndoStack] = useState(new Map());
    const { integrations, loaded } = useIntegrations();
    // This state is used to render the loading state on the field mapping card
    // while the item is being updated or processed.
    // The key is the post call output item id and the value is a string indicating
    // the status of the item.
    const [itemLoadingStatus, setItemLoadingStatus] = useState(new Map());
    // This map is used to store the note taking status of the post-call only playbook items
    // This is updated every 5 seconds until all the playbook items have been marked as COMPLETE
    const playbookItemStreamStatus = useRef(new Map());
    // Ref for getting the latest state value during playbook item updates received via polling
    const postCallOutputMapRef = useRef(postCallOutputMap);
    useEffect(() => {
        postCallOutputMapRef.current = postCallOutputMap;
    }, [postCallOutputMap]);
    const updateUndoStack = (itemId, value) => {
        setUndoStack((prev) => {
            const stack = prev.get(itemId) || [];
            return new Map(prev.set(itemId, [...stack, value]));
        });
    };
    const updateItemLoadingStatus = (postCallOutputId, status) => {
        setItemLoadingStatus((prev) => {
            const updatedMap = new Map(prev);
            updatedMap.set(postCallOutputId, status);
            return updatedMap;
        });
    };
    const updatePlaybookItemStreamStatus = (playbookItemId, status) => {
        const currentStatus = playbookItemStreamStatus.current.get(playbookItemId);
        if (currentStatus === status) {
            return;
        }
        playbookItemStreamStatus.current.set(playbookItemId, status);
        const postCallOutputIdsForPlaybookItem = Array.from(postCallOutputMapRef.current.values())
            .map(({ item }) => item)
            .filter((item) => { var _a; return ((_a = item.fieldMap.sourcePlaybookItem) === null || _a === void 0 ? void 0 : _a.id) === playbookItemId; })
            .map((item) => item.id);
        if (status === "COMPLETE") {
            // Refetch all the pending post-call outputs for this playbook item id.
            postCallOutputIdsForPlaybookItem.forEach((id) => {
                fetchPostCallOutput(id);
            });
        }
    };
    useEffect(() => {
        if (!loaded) {
            setConnectedIntegrations([]);
        }
        setConnectedIntegrations(integrations);
    }, [integrations, loaded]);
    useEffect(() => {
        if (props.call.playbook_id === -1) {
            return;
        }
        // Fetch the playbook notes status every 5 seconds until all the playbook items
        // for the call's playbook have been marked as COMPLETE.
        // Note that COMPLETE status doesn't mean that the playbook notes will be present,
        // it only is an indicator of whether the stream stage for that item was completed.
        const interval = setInterval(() => {
            endpoints.getPostCallPlaybookItemStatus(props.call.id).then((playbookItemStatus) => {
                playbookItemStatus.forEach((item) => {
                    updatePlaybookItemStreamStatus(item.playbookItemId, item.status);
                });
                const allItemsComplete = !playbookItemStatus
                    .map((i) => i.status)
                    .some((status) => status !== "COMPLETE");
                if (allItemsComplete) {
                    clearInterval(interval);
                }
            });
        }, 5000);
        return () => {
            clearInterval(interval);
        };
    }, []);
    useEffect(() => {
        setItemLoadingStatus((prev) => {
            const updatedMap = new Map(prev);
            Array.from(postCallOutputMap.keys()).forEach((itemId) => {
                if (!updatedMap.has(itemId)) {
                    updatedMap.set(itemId, "LOADED");
                }
            });
            return updatedMap;
        });
    }, [postCallOutputMap]);
    const postCallOutputs = useMemo(() => {
        return Array.from(postCallOutputMap.values());
    }, [postCallOutputMap]);
    const postCallOutputItemIdsToRender = useMemo(() => {
        return postCallOutputs.map(({ item }) => item.id);
    }, [postCallOutputs]);
    // This is the list of post call outputs that are eligible for Sending at
    // any given point of time. This is used to render the "Send all" button
    // at the top of the page, and then to send the items when the button is clicked.
    const postCallItemsEligibleForSending = useMemo(() => {
        return (postCallOutputItemIdsToRender
            .filter((itemId) => postCallOutputMap.has(itemId))
            .filter((itemId) => itemLoadingStatus.get(itemId) === "LOADED")
            .map((id) => postCallOutputMap.get(id))
            .filter(({ isLoaded }) => isLoaded)
            .filter(({ item }) => isPostCallOutputEligibleForExecuting(item))
            // It is safe to cast item as PostCallOutput here because we filter the loaded
            // items above.
            .filter(({ item }) => {
            const postCallOutput = item;
            return postCallOutput.valueToUpdate || postCallOutput.proposedValue;
        })
            .map(({ item }) => item));
    }, [postCallOutputItemIdsToRender, postCallOutputMap, itemLoadingStatus]);
    const onSendItemClick = (itemId) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        updateItemLoadingStatus(itemId, "SYNCING");
        const postCallOutputToExecute = postCallOutputMap.get(itemId)
            .item;
        const updatePostCallOutputResponse = yield endpoints.executePostCallOutput(postCallOutputToExecute.id, (_b = (_a = postCallOutputToExecute.valueToUpdate) !== null && _a !== void 0 ? _a : postCallOutputToExecute.proposedValue) !== null && _b !== void 0 ? _b : "");
        if (updatePostCallOutputResponse.status === "FAILURE") {
            updateItemLoadingStatus(itemId, "INVALID_FIELD_VALUE");
            return;
        }
        updatePostCallOutputInState(updatePostCallOutputResponse.postCallOutput);
        updateItemLoadingStatus(itemId, "LOADED");
    });
    const onDataItemValueUpdate = (itemId, value, undoClicked) => __awaiter(void 0, void 0, void 0, function* () {
        var _c, _d;
        const postCallOutputToUpdate = postCallOutputMap.get(itemId).item;
        const updatedDataItem = Object.assign(Object.assign({}, postCallOutputToUpdate), { state: "PENDING", valueToUpdate: value });
        updateItemLoadingStatus(itemId, "UPDATING");
        const updatePostCallOutputPromise = endpoints.updatePostCallOutput(updatedDataItem.id, updatedDataItem);
        // We update the items in state before waiting for the promise to resolve
        // becase we want to render the new state as soon as the user clicks on the
        // "Undo" button or save the edits.
        if (!undoClicked) {
            updateUndoStack(itemId, (_d = (_c = postCallOutputToUpdate.valueToUpdate) !== null && _c !== void 0 ? _c : postCallOutputToUpdate.proposedValue) !== null && _d !== void 0 ? _d : "");
        }
        updatePostCallOutputInState(updatedDataItem);
        const updatePostCallOutputResponse = yield updatePostCallOutputPromise;
        if (updatePostCallOutputResponse.status === "FAILURE") {
            updateItemLoadingStatus(itemId, "INVALID_FIELD_VALUE");
            return;
        }
        updateItemLoadingStatus(itemId, "LOADED");
    });
    const onDataItemTargetUpdate = (itemId, target, targetDetails) => __awaiter(void 0, void 0, void 0, function* () {
        const dataItem = postCallOutputMap.get(itemId)
            .item;
        const updatedDataItem = Object.assign(Object.assign({}, dataItem), { fieldMap: Object.assign(Object.assign({}, dataItem.fieldMap), { target,
                targetDetails }), target: target, targetDetails: targetDetails, valueToUpdate: undefined });
        updateItemLoadingStatus(itemId, "UPDATING");
        const response = yield endpoints.updatePostCallOutput(updatedDataItem.id, updatedDataItem);
        updatePostCallOutputInState(Object.assign(Object.assign({}, updatedDataItem), response.postCallOutput));
        if (response.status === "FAILURE" && response.error) {
            updateItemLoadingStatus(itemId, "INVALID_FIELD_VALUE");
            return;
        }
        updateItemLoadingStatus(itemId, "LOADED");
    });
    useEffect(() => {
        if (connectedIntegrations.length === 0) {
            return;
        }
        const connectedIntegrationTargets = connectedIntegrations.map((i) => i.name);
        if (connectedIntegrationTargets.includes(PostCallOutputTarget.SALESFORCE)) {
            actions.fetchSalesforceIntegrationData().then((data) => {
                setIntegrationsData((prev) => [...prev, data]);
            });
        }
        if (connectedIntegrationTargets.includes(PostCallOutputTarget.HUBSPOT)) {
            actions.fetchHubspotIntegrationData().then((data) => {
                setIntegrationsData((prev) => [...prev, data]);
            });
        }
        if (!isTenantConnectedToExternalCrm(connectedIntegrations)) {
            // Wiser CRM is automatically connected if the tenant is not using any external CRM.
            actions.fetchWiserCrmIntegrationData().then((data) => {
                setIntegrationsData((prev) => [...prev, data]);
            });
        }
    }, [connectedIntegrations]);
    useEffect(() => {
        // This is to make sure we fetch all the integration data before rendering
        // the field mapping cards as they require the integration data fetched from all
        // applicable third party apps to render the details of the objects correctly.
        // E.g. mapping salesforce object id to the object name, etc.
        if (connectedIntegrations.length === 0) {
            return;
        }
        const connectedIntegrationTargets = connectedIntegrations.map((i) => i.name);
        if (connectedIntegrationTargets.includes(PostCallOutputTarget.SALESFORCE)) {
            if (!integrationsData.find((i) => i.name === PostCallOutputTarget.SALESFORCE)) {
                return;
            }
        }
        if (!isTenantConnectedToExternalCrm(connectedIntegrations) &&
            !integrationsData.find((i) => i.name === PostCallOutputTarget.WISER_CRM)) {
            return;
        }
        setFetchedIntegrationsData(true);
    }, [connectedIntegrations, integrationsData]);
    const renderSection = (sectionTitle, postCallItemIds, showTrackerNotes) => {
        if (postCallItemIds.length === 0) {
            return null;
        }
        return (_jsxs("div", Object.assign({ className: "flex flex-col items-start gap-2 self-stretch" }, { children: [_jsx("span", Object.assign({ className: "self-stretch font-bold text-base" }, { children: sectionTitle })), postCallItemIds.map((itemId) => (_jsx("div", Object.assign({ className: "self-stretch" }, { children: fetchedIntegrationsData &&
                        postCallOutputMap.has(itemId) &&
                        itemLoadingStatus.has(itemId) &&
                        postCallOutputMap.get(itemId).isLoaded ? (_jsx(FieldMappingCard, { postCallOutputItem: postCallOutputMap.get(itemId).item, undoStack: undoStack.get(itemId) || [], call: props.call, itemLoadingStatus: itemLoadingStatus.get(itemId), onSendClick: () => __awaiter(void 0, void 0, void 0, function* () { return yield onSendItemClick(itemId); }), onDataItemValueUpdate: (value, undoClicked) => __awaiter(void 0, void 0, void 0, function* () {
                            yield onDataItemValueUpdate(itemId, value, undoClicked);
                        }), onDataItemTargetUpdate: (target, targetDetails) => __awaiter(void 0, void 0, void 0, function* () {
                            yield onDataItemTargetUpdate(itemId, target, targetDetails);
                        }), toggleDataItemEditOpen: (value) => {
                            if (value) {
                                updateItemLoadingStatus(itemId, "USER_EDITING");
                            }
                            else {
                                updateItemLoadingStatus(itemId, "LOADED");
                            }
                        }, postCallIntegrations: connectedIntegrations, integrationsData: integrationsData, showTrackerNotes: showTrackerNotes }, itemId)) : (_jsx(FieldMappingCardSummary, { postCallOutputItem: postCallOutputMap.get(itemId).item }, itemId)) }), itemId)))] })));
    };
    return (_jsxs("div", Object.assign({ className: "flex flex-col gap-6 items-start" }, { children: [renderSection("Follow-up email", postCallOutputItemIdsToRender
                .filter((itemId) => postCallOutputMap.has(itemId))
                .filter((itemId) => {
                var _a;
                return ((_a = postCallOutputMap.get(itemId).item.fieldMap.sourcePlaybookItem) === null || _a === void 0 ? void 0 : _a.call_stage) === "FOLLOW_UP_EMAIL";
            }), 
            /* showTrackerNotes= */ false), renderSection("Internal messaging", postCallOutputItemIdsToRender
                .filter((itemId) => postCallOutputMap.has(itemId))
                .filter((itemId) => INTERNAL_MESSAGING_TARGETS.includes(postCallOutputMap.get(itemId).item.target)), 
            /* showTrackerNotes= */ false), renderSection("Update CRM data", postCallOutputItemIdsToRender
                .filter((itemId) => postCallOutputMap.has(itemId))
                .filter((itemId) => {
                var _a;
                return ((_a = postCallOutputMap.get(itemId).item.fieldMap.sourcePlaybookItem) === null || _a === void 0 ? void 0 : _a.call_stage) !== "FOLLOW_UP_EMAIL";
            })
                .filter((itemId) => !INTERNAL_MESSAGING_TARGETS.includes(postCallOutputMap.get(itemId).item.target)), 
            /* showTrackerNotes= */ true)] })));
};
export default DataProcessingTab;
