//These are the important styles to make sticky column pinning work!
export const getCommonPinningStyles = (column) => {
    const isPinned = column.getIsPinned();
    // zIndex is set to 1 when the column is pinned to make sure it is above the other columns.
    // But we don't set it to 0 because it interferes with the behaviour of single
    // select and multi select fields.
    const zIndex = isPinned ? 1 : "";
    return {
        left: `${column.getStart("left")}px`,
        position: isPinned ? "sticky" : "relative",
        width: `${column.getSize()}px`,
        zIndex,
    };
};
// getColumnSize is a helper function to determine the width of the column based on the field type.
export const getColumnSize = (field) => {
    switch (field.content_type_definition.type) {
        case "boolean":
            return 60;
        case "single_select":
            return 200;
        case "number":
            return 100;
    }
    return 400;
};
