import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from "react-query";
import { getLiveCallSummary } from "../common/endpoints";
import { SettingsLoader } from "../common/loaders";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { CenterScreen } from "../settings/styled_components";
export const CallSummary = ({ call }) => {
    const { data: callSummary, isLoading: callSummaryLoading } = useQuery({
        queryKey: ["callSummary", call.id],
        queryFn: () => getLiveCallSummary(call.id),
    });
    const renderNoCallSummary = () => {
        return (_jsxs("div", Object.assign({ className: "flex flex-col justify-center items-start gap-4 px-2 h-full" }, { children: [_jsx(InformationCircleIcon, { className: "w-[60px] h-[60px] stroke-wds-blue-2 stroke-2" }), _jsxs("div", { children: [_jsx("div", Object.assign({ className: "text-[24px] leading-8 font-lato", style: { fontWeight: 600 } }, { children: "Call hasn't started" })), _jsx("div", Object.assign({ className: "w-[336px] font-normal leading-6 text-[16px] font-lato" }, { children: "The summary of this call is not available." }))] })] })));
    };
    return (_jsxs("div", Object.assign({ className: "flex flex-col gap-2 self-stretch px-6 py-4 rounded-2xl bg-wds-gray-3 bg-white" }, { children: [_jsxs("div", Object.assign({ className: "flex flex-col items-start gap-[2px]" }, { children: [_jsx("span", Object.assign({ className: "text-lg font-bold" }, { children: "Summary" })), _jsx("span", Object.assign({ className: "bg-clip-text text-transparent bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500" }, { children: "AI generated" }))] })), callSummaryLoading && (_jsx(CenterScreen, { children: _jsx(SettingsLoader, {}) })), (callSummary === null || callSummary === void 0 ? void 0 : callSummary.call_summary) ? (_jsx("div", Object.assign({ className: "whitespace-pre-wrap self-stretch" }, { children: callSummary === null || callSummary === void 0 ? void 0 : callSummary.call_summary }))) : (renderNoCallSummary())] })));
};
