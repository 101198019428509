import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// Notes section.
//
// TODO: editing is not yet built.
import { CheckCircleIcon, InformationCircleIcon, } from "@heroicons/react/20/solid";
import { DocumentMagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import TabHeader from "./tab_header";
import SearchSelect from "./search_select";
import axios from "axios";
import { showErrorModal } from "./error_modal";
import { getCookie } from "./cookies";
import { ListenButton } from "./listen_button";
export function Note(props) {
    const [editingIndex, setEditingIndex] = useState(-1);
    const [noteContents, setNoteContents] = useState([]);
    useEffect(() => {
        setNoteContents(props.note.notes.map((note) => note.content));
    }, [props.note]);
    let saveNote = (note) => {
        axios
            .post("/call_nav/save_note", {
            call_id: props.callId,
            playbook_item_id: note.playbook_item_id,
            notes: note.notes,
        }, {
            headers: {
                Authorization: localStorage.getItem("wiser_jwt"),
                "X-CSRFToken": getCookie("csrftoken"),
            },
        })
            .then((response) => {
            props.setNote(note);
        })
            .catch((err) => {
            showErrorModal(err);
        });
    };
    let editableNotes = props.note.notes.slice();
    // We add an extra empty note at the end so that the user can add a separate new note
    // regardless of whether the system has added any automated note.
    editableNotes.push({
        content: "",
        startTime: 0,
        endTime: 0,
        source: "user",
        score: 0,
    });
    return (_jsxs("div", Object.assign({ className: "flex flex-row justify-between" }, { children: [_jsx("article", Object.assign({ className: "flex max-w-xl flex-col items-start justify-between w-full" }, { children: _jsxs("div", Object.assign({ className: "group relative w-full" }, { children: [_jsx("h3", Object.assign({ className: "mt-3 text-md font-base leading-6 text-gray-900" }, { children: props.note.question })), editableNotes.map((note, index) => (_jsx("div", { children: editingIndex !== index ? (_jsxs("div", Object.assign({ className: "flex flex-row justify-between" }, { children: [_jsx("p", Object.assign({ className: "mt-3 line-clamp-3 px-1 py-2 text-sm font-base leading-6 text-gray-600 whitespace-pre-wrap hover:bg-gray-300 w-full", onClick: () => {
                                            setEditingIndex(index);
                                        } }, { children: note.content })), props.showTranscriptTimes && (_jsx("button", Object.assign({ className: "text-blue-500", onClick: () => {
                                            props.showTranscriptTimes(note.startTime, note.endTime);
                                        } }, { children: _jsx(DocumentMagnifyingGlassIcon, { className: "w-8 h-8" }) })))] }))) : (_jsx("textarea", { className: "mt-3 line-clamp-3 px-1 py-2 text-sm font-base leading-6 text-gray-600 whitespace-pre-wrap w-full h-36", value: noteContents[index], onChange: (e) => {
                                    let updatedNoteContents = [...noteContents];
                                    updatedNoteContents[index] = e.target.value;
                                    setNoteContents(updatedNoteContents);
                                }, onBlur: (e) => {
                                    setEditingIndex(-1);
                                    let updatedNote = Object.assign({}, props.note);
                                    if (index < updatedNote.notes.length) {
                                        updatedNote.notes[index].content = e.target.value;
                                    }
                                    else {
                                        // A new note, rather than a change to a system-generated note.
                                        updatedNote.notes.push({
                                            content: e.target.value,
                                            startTime: Date.now() - 1000,
                                            endTime: Date.now(),
                                            source: "user",
                                            score: 0,
                                        });
                                    }
                                    saveNote(updatedNote);
                                }, autoFocus: true })) }, index)))] })) })), props.setListening && (_jsxs("div", Object.assign({ className: "flex" }, { children: [_jsx(ListenButton, { listening: props.note.listening, onClick: () => {
                            props.setListening(props.note.intent, !props.note.listening);
                        } }), props.note.notes.map((note) => note.content).join("").length < 10 ? (_jsx(InformationCircleIcon, { className: "h-6 w-6 mt-4 ml-2 text-gray-400", "aria-hidden": "true" })) : (_jsx(CheckCircleIcon, { className: "h-6 w-6 mt-4 ml-2 text-green-500", "aria-hidden": "true" }))] })))] })));
}
export default function Notes(props) {
    const [selectedTab, setSelectedTab] = useState("Select Playbook");
    const [playbookOptions, setPlaybookOptions] = useState([]);
    const [selectedPlaybookId, setSelectedPlaybookId] = useState(-1);
    const [playbook, setPlaybook] = useState({
        id: -1,
        name: "",
        details: "",
        playbook_item_groups: [],
        publish_state: "",
    });
    useEffect(() => {
        axios
            .get("/call_nav/get_playbooks", {
            headers: {
                Authorization: localStorage.getItem("wiser_jwt"),
            },
        })
            .then((res) => {
            let playbooks = res.data.playbooks;
            let newRows = [];
            for (let i = 0; i < playbooks.length; i++) {
                newRows.push({
                    id: playbooks[i].id,
                    name: playbooks[i].name,
                    description: playbooks[i].description,
                });
            }
            setPlaybookOptions(newRows);
            if (selectedPlaybookId === -1 && playbooks.length > 0) {
                if (props.playbookId !== -1 &&
                    playbooks.some((playbook) => playbook.id === props.playbookId)) {
                    // Call already has a valid playbook selected, use it.
                    setSelectedPlaybookId(props.playbookId);
                }
                else {
                    // Use a default; user can change it.
                    props.selectPlaybook(playbooks[0].id);
                }
            }
        })
            .catch((err) => {
            showErrorModal(err);
        });
    }, [props.playbookId]);
    useEffect(() => {
        if (selectedPlaybookId === -1)
            return;
        setSelectedTab("All");
    }, [selectedPlaybookId]);
    let setNote = (intent, content) => {
        let newNoteSections = new Map(props.noteSections);
        newNoteSections.set(intent, content);
        props.setNotes(newNoteSections);
    };
    let selectPlaybook = (playbookId) => {
        setSelectedPlaybookId(playbookId);
        props.selectPlaybook(playbookId);
    };
    let selectTab = (tab) => {
        if (selectedPlaybookId === -1 && tab !== "Select Playbook") {
            // Don't allow moving to see a playbook unless one is actually selected.
            setSelectedTab("Select Playbook");
            return;
        }
        setSelectedTab(tab);
    };
    // TODO(mark): Update how we handle this list of note content using openai functions.
    let noteSectionsList = Array.from(props.noteSections.values()).sort((a, b) => a.index - b.index);
    return (_jsxs("div", Object.assign({ className: "py-2" }, { children: [_jsx(TabHeader, { tabs: [
                    { name: "Remaining", href: "#" },
                    { name: "All", href: "#" },
                    { name: "Select Playbook", href: "#" },
                ], selectedTab: selectedTab, onSelect: selectTab }), _jsxs("div", Object.assign({ className: "bg-white py-2 my-2 border-2 rounded-lg shadow-lg" }, { children: [selectedTab === "Select Playbook" && (_jsx(SearchSelect, { options: playbookOptions, selectedId: selectedPlaybookId, selectId: selectPlaybook })), selectedTab !== "Select Playbook" && (_jsx("div", Object.assign({ className: "mx-auto max-w-7xl px-6 lg:px-8 py-4" }, { children: _jsxs("div", Object.assign({ className: "mx-auto max-w-2xl" }, { children: [_jsx("div", Object.assign({ className: "flex flex-row justify-between" }, { children: _jsx("h2", Object.assign({ className: "flex text-lg font-bold tracking-tight text-gray-900 sm:text-xl" }, { children: playbook.name })) })), _jsx("p", Object.assign({ className: "mt-2 text-sm leading-8 text-gray-600" }, { children: "Your discovery questions auto-document themselves based on your conversation." })), _jsx("div", Object.assign({ className: "mt-1" }, { children: noteSectionsList.map((note, i) => (_jsx(Note, { note: note, callId: props.callId, setNote: (content) => {
                                            setNote(note.intent, content);
                                        }, setListening: props.setListening }, i))) }))] })) })))] }))] })));
}
