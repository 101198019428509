import { jsx as _jsx } from "react/jsx-runtime";
import LiveCardsChatBot from "../live_cards_chat_bot";
import WiserSparklesIcon from "../wiser_sparkles_icon";
const FeedAssist = ({ allCards, callId, clientId, chatMessages, addChatMessage, collapsed, setCollapse, defaultChatSource, socketReady, allLlmOutputFeedback, updateLlmOutputFeedbackMutation, deleteLlmOutputFeedbackMutation, }) => {
    if (collapsed) {
        return (_jsx("div", Object.assign({ className: "flex h-full flex-col justify-start items-center w-full bg-white p-4 rounded-lg border-wds-gray-3 border" }, { children: _jsx("button", Object.assign({ onClick: () => setCollapse(false) }, { children: _jsx(WiserSparklesIcon, {}) })) })));
    }
    return (_jsx("div", Object.assign({ className: "h-full flex-col justify-start items-start inline-flex w-full bg-white rounded-lg border-wds-gray-3 border overflow-y-auto md:overflow-y-none" }, { children: _jsx(LiveCardsChatBot, { allCards: allCards, callId: callId, clientId: clientId, chatMessages: chatMessages, addChatMessage: addChatMessage, setCollapse: setCollapse, defaultChatSource: defaultChatSource, socketReady: socketReady, allLlmOutputFeedback: allLlmOutputFeedback, updateLlmOutputFeedbackMutation: updateLlmOutputFeedbackMutation, deleteLlmOutputFeedbackMutation: deleteLlmOutputFeedbackMutation }) })));
};
export default FeedAssist;
