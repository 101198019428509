import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { TableFilter } from "./table_filter";
import { TableSort } from "./table_sort";
import { TableColumnVisibility } from "./table_column_visibility";
import { TableViewButton } from "./table_view_button";
const FilterButton = ({ crmObjectType, tableCols, columnFilters, setColumnFilters, }) => {
    return (_jsx(TableFilter, { crmObjectType: crmObjectType, tableCols: tableCols, columnFilters: columnFilters, setColumnFilters: setColumnFilters }));
};
const SortButton = ({ crmObjectType, tableCols, sortState, setSortState, }) => {
    return (_jsx(TableSort, { crmObjectType: crmObjectType, tableCols: tableCols, sortState: sortState, setSortState: setSortState }));
};
const ColumnOptionsButton = ({ crmObjectType, tableCols, columnViewState, setColumnViewState, refetchCrmFields, refetchCrmData, }) => {
    return (_jsx(TableColumnVisibility, { crmObjectType: crmObjectType, tableCols: tableCols, columnViewState: columnViewState, setColumnViewState: setColumnViewState, refetchCrmFields: refetchCrmFields, refetchCrmData: refetchCrmData }));
};
const SearchBar = ({ value, onChange, }) => {
    return (_jsxs("div", Object.assign({ className: "flex w-80 h-8 px-3 items-center gap-1 shrink-0 border border-gray-300 bg-white rounded-lg" }, { children: [_jsx(MagnifyingGlassIcon, { className: "h-4 w-4 text-gray-500" }), _jsx("input", { type: "text", placeholder: "Search", className: "w-full h-full focus:outline-none border-0 focus:ring-0 text-gray-500", value: value, onChange: (e) => onChange(e.target.value) })] })));
};
export const TableOptions = (props) => {
    return (_jsxs("div", Object.assign({ className: "flex justify-between items-start mt-6 mx-8" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center gap-4" }, { children: [_jsx(TableViewButton, { crmObjectType: props.crmObjectType, tableCols: props.tableCols, columnViewState: props.columnViewState, currentCrmView: props.currentCrmView, setCurrentCrmView: props.setCurrentCrmView }), _jsxs("div", Object.assign({ className: "flex self-center" }, { children: [props.showFilter && (_jsx(FilterButton, { crmObjectType: props.crmObjectType, tableCols: props.tableCols, columnFilters: props.columnFilters, setColumnFilters: props.setColumnFilters })), props.showSort && (_jsx(SortButton, { crmObjectType: props.crmObjectType, tableCols: props.tableCols, sortState: props.sortState, setSortState: props.setSortState })), props.showColumnOptions && (_jsx(ColumnOptionsButton, { crmObjectType: props.crmObjectType, tableCols: props.tableCols, columnViewState: props.columnViewState, setColumnViewState: props.setColumnViewState, refetchCrmFields: props.refetchCrmFields, refetchCrmData: props.refetchCrmData }))] }))] })), _jsx("div", { children: props.showSearch && (_jsx(SearchBar, { value: props.tableGlobalFilterQuery, onChange: props.setTableGlobalFilterQuery })) })] })));
};
