import { jsx as _jsx } from "react/jsx-runtime";
import { useRef } from "react";
import { getManualNote, updateManualNote } from "../common/endpoints";
import ManualNotes from "../live_call/manual_notes";
import useFeatureFlagEnabled from "../use_feature_flag_enabled";
import { useMutation, useQuery } from "react-query";
import { NoteLoaderFrame } from "../common/loaders";
const HomePageManualNotes = ({ callId }) => {
    const autoCompleteEnabled = useFeatureFlagEnabled("MANUAL_AUTOCOMPLETE");
    const timer = useRef();
    const { data: manualNote, isLoading: manualNoteLoading, isError: manualnoteError, } = useQuery({
        queryKey: ["manualNote", callId],
        queryFn: () => getManualNote(callId),
        refetchOnWindowFocus: false,
    });
    const updateNoteMutation = useMutation({
        mutationFn: (note_text) => updateManualNote(callId, note_text),
        // Do not invalidate the query data, this causes re-render and pushes cursor to the start again
        onSuccess: (data) => { },
        onError: (error) => {
            console.error("Error updating manual note", error);
            // TODO: Handle error states better
        },
    });
    if (manualNoteLoading || !manualNote) {
        return _jsx(NoteLoaderFrame, {});
    }
    return (_jsx("div", Object.assign({ className: "h-screen" }, { children: _jsx(ManualNotes, { enabled: !!manualNote, manualNote: manualNote, autocomplete: null, onManualNoteChange: updateNoteMutation.mutate, requestAutocomplete: () => { }, isMosaicScrolling: false, autoCompleteEnabled: autoCompleteEnabled }) })));
};
export default HomePageManualNotes;
