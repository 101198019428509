import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from "../class_names";
import CallCrmDetailsPopover, { NoCrmObjectMappedPopoverPanel, } from "./call_crm_details_popover";
import { PencilIcon } from "@heroicons/react/24/outline";
import CallCrmOpportunityModal from "./call_crm_opportunity_modal";
import { useState } from "react";
const OpportunityDetails = ({ call, setCallCrmOpportunityModalOpen, }) => {
    const renderEditButton = () => {
        return (_jsx("button", Object.assign({ onClick: () => setCallCrmOpportunityModalOpen(true), className: "flex w-8 h-8 justify-center items-center rounded-full" }, { children: _jsx(PencilIcon, { className: "w-5 h-5 text-wds-blue-3" }) })));
    };
    return (_jsx(_Fragment, { children: call.opportunity ? (_jsxs("div", Object.assign({ className: "flex justify-between items-center self-stretch" }, { children: [_jsx("span", Object.assign({ className: "text-base font-medium text-black" }, { children: call.opportunity.opportunity_name })), renderEditButton()] }))) : (_jsx(NoCrmObjectMappedPopoverPanel, { title: "No opportunity mapped", editButtonClickHandler: () => setCallCrmOpportunityModalOpen(true) })) }));
};
const OpportunityPopover = (props) => {
    // Boolean to find if the opportunity exists in user's CRM.
    // If the user is connected to an external CRM,
    // we check if the external_id of the opportunity is populated.
    // If the user is not connected to external CRM, presence of an opportunity is
    // necessary and sufficient condition.
    const opportunityExistsInCrm = !!props.call.opportunity &&
        (!props.tenant.connected_crm || !!props.call.opportunity.external_id);
    const [callCrmOpportunityModalOpen, setCallCrmOpportunityModalOpen] = useState(false);
    return (_jsxs(_Fragment, { children: [_jsx(CallCrmDetailsPopover, { button: _jsxs("div", Object.assign({ className: classNames("flex py-[2px] px-2 gap-2 items-center rounded-lg border", opportunityExistsInCrm
                        ? "border-wds-gray-3 bg-wds-gray-1 text-wds-gray-6"
                        : "border-yellow-300 bg-yellow-100 text-yellow-800") }, { children: [_jsx("img", { className: "h-4 w-4", src: opportunityExistsInCrm
                                ? "https://storage.googleapis.com/wiser-ai-public2/Crown.png"
                                : "https://storage.googleapis.com/wiser-ai-public2/YellowCrown.png" }), _jsx("div", Object.assign({ className: "font-normal leading-snug" }, { children: props.call.opportunity
                                ? props.call.opportunity.opportunity_name
                                : "No opportunity" }))] })), panel: _jsx(OpportunityDetails, { call: props.call, setCallCrmOpportunityModalOpen: setCallCrmOpportunityModalOpen }) }), _jsx(CallCrmOpportunityModal, { tenant: props.tenant, call: props.call, open: callCrmOpportunityModalOpen, setOpen: setCallCrmOpportunityModalOpen, updateSelectedCall: props.updateSelectedCall })] }));
};
export default OpportunityPopover;
