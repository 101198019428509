import { CallFilter, MatchOptions, } from "../types";
const contactSubqueryCondition = (contactIdList) => {
    return {
        where_condition: {
            field: "id",
            operator: "IN",
            subquery: {
                table: "Participants",
                select_field: "call_id",
                structured_filter: {
                    where_condition: {
                        field: "contact_id",
                        operator: "IN",
                        value: contactIdList,
                    },
                },
            },
        },
    };
};
const dateStartSubqueryCondition = (start) => {
    return {
        where_condition: {
            field: "call_time",
            operator: "GTE",
            value: start,
        },
    };
};
const dateEndSubqueryCondition = (end) => {
    return {
        where_condition: {
            field: "call_time",
            operator: "LTE",
            value: end,
        },
    };
};
const getSubQueryCondtion = (subQuery) => {
    var _a, _b;
    const result = {
        operator: subQuery.operator === MatchOptions.MATCH_ANY ? "OR" : "AND",
        subconditions: [],
    };
    Object.keys(subQuery).flatMap((key) => {
        const value = subQuery[key];
        if (Array.isArray(value) && value.length !== 0) {
            if (key === "contact") {
                result.subconditions.push(contactSubqueryCondition(value));
            }
            else {
                result.subconditions.push({
                    where_condition: {
                        field: `${key}_id`,
                        operator: "IN",
                        value: value,
                    },
                });
            }
        }
    });
    if (((_a = subQuery.date) === null || _a === void 0 ? void 0 : _a.start) && subQuery.date.start > 0) {
        result.subconditions.push(dateStartSubqueryCondition(subQuery.date.start));
    }
    if (((_b = subQuery.date) === null || _b === void 0 ? void 0 : _b.end) && subQuery.date.end > 0) {
        result.subconditions.push(dateEndSubqueryCondition(subQuery.date.end));
    }
    if (subQuery.recordedOnly) {
        result.subconditions.push({
            where_condition: {
                field: "wiser_joined",
                operator: "EQUALS",
                value: true,
            },
        });
    }
    return {
        where_condition: result,
    };
};
export const buildFilterQuery = (filterType, account, date, contact, owner, subQuery, order_by) => {
    let json_query = {
        table: "LiveCall",
        order_by_fields: order_by || ["id"],
    };
    switch (filterType) {
        case CallFilter.ACCOUNT:
            json_query = Object.assign(Object.assign({}, json_query), { structured_filter: {
                    where_condition: {
                        operator: "AND",
                        subconditions: [
                            {
                                where_condition: {
                                    field: "account_id",
                                    operator: "IN",
                                    value: account,
                                },
                            },
                            ...(subQuery
                                ? [
                                    getSubQueryCondtion({
                                        operator: subQuery.operator,
                                        account: subQuery.account,
                                        contact: subQuery.contact,
                                        owner: subQuery.owner,
                                        date: subQuery.date,
                                        recordedOnly: subQuery.recordedOnly,
                                    }),
                                ]
                                : []),
                        ],
                    },
                } });
            break;
        case CallFilter.DATE:
            json_query = Object.assign(Object.assign({}, json_query), { structured_filter: date && date.start !== 0 && date.end !== 0
                    ? {
                        where_condition: {
                            operator: "AND",
                            subconditions: [
                                {
                                    where_condition: {
                                        field: "call_time",
                                        operator: "GTE",
                                        value: date.start,
                                    },
                                },
                                {
                                    where_condition: {
                                        field: "call_time",
                                        operator: "LTE",
                                        value: date.end,
                                    },
                                },
                                ...(subQuery
                                    ? [
                                        getSubQueryCondtion({
                                            operator: subQuery.operator,
                                            account: subQuery.account,
                                            contact: subQuery.contact,
                                            owner: subQuery.owner,
                                            date: subQuery.date,
                                            recordedOnly: subQuery.recordedOnly,
                                        }),
                                    ]
                                    : []),
                            ],
                        },
                    }
                    : {} });
            break;
        case CallFilter.CONTACT:
            json_query = Object.assign(Object.assign({}, json_query), { structured_filter: {
                    where_condition: {
                        operator: "AND",
                        subconditions: [
                            {
                                where_condition: {
                                    field: "id",
                                    operator: "IN",
                                    subquery: {
                                        table: "Participants",
                                        select_field: "call_id",
                                        structured_filter: {
                                            where_condition: {
                                                field: "contact_id",
                                                operator: "IN",
                                                value: contact,
                                            },
                                        },
                                    },
                                },
                            },
                            ...(subQuery
                                ? [
                                    getSubQueryCondtion({
                                        operator: subQuery.operator,
                                        account: subQuery.account,
                                        contact: subQuery.contact,
                                        owner: subQuery.owner,
                                        date: subQuery.date,
                                        recordedOnly: subQuery.recordedOnly,
                                    }),
                                ]
                                : []),
                        ],
                    },
                } });
            break;
        case CallFilter.OWNER:
            json_query = Object.assign(Object.assign({}, json_query), { structured_filter: {
                    where_condition: {
                        operator: "AND",
                        subconditions: [
                            {
                                where_condition: {
                                    field: "owner_id",
                                    operator: "IN",
                                    value: owner,
                                },
                            },
                            ...(subQuery
                                ? [
                                    getSubQueryCondtion({
                                        operator: subQuery.operator,
                                        account: subQuery.account,
                                        contact: subQuery.contact,
                                        owner: subQuery.owner,
                                        date: subQuery.date,
                                        recordedOnly: subQuery.recordedOnly,
                                    }),
                                ]
                                : []),
                        ],
                    },
                } });
            break;
        default:
            break;
    }
    return json_query;
};
