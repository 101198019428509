import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo, useRef } from "react";
import { AccountOverviewBlock } from "./account_overview_block";
import { AccountDetailsBlock } from "./account_details_block";
import { AccountCallsBlock } from "./account_calls_block";
import { AccountContactsBlock } from "./account_contacts_block";
// This hook manages everything related to account blocks:
// both static: (Account Overview, Account Details, Calls, Contacts)
// as well as dynamic research blocks that the user adds/creates.
export const useAccountResearchBlocks = ({ account, }) => {
    // This ref is used to manipulate the scrolling of the blocks when
    // the user "jumps to" a different block using the dropdown.
    // Why ref instead of state?
    // This is the preferred way of such DOM manipulation in react
    // Details: https://react.dev/learn/manipulating-the-dom-with-refs
    const blocksRef = useRef(new Map());
    const getBlockRefMap = () => {
        return blocksRef.current;
    };
    // This is invoked when React mounts/unmounts each block.
    // Essentially we're setting the corresponding div ref in the map for
    // each account block when it is rendered, and unsetting it when it is
    // cleared from the DOM.
    const blocksRefCallback = (blockId, blockDivContainer) => {
        const blocksRefMap = getBlockRefMap();
        if (blockDivContainer) {
            blocksRefMap.set(blockId, blockDivContainer);
        }
        else {
            blocksRefMap.delete(blockId);
        }
    };
    // The order of the blocks here determines the order in which they
    // will be rendered in the UI.
    const accountBlocks = useMemo(() => {
        if (!account)
            return [];
        return [
            {
                id: "account-overview",
                label: "Overview",
                renderer: (_jsx(AccountOverviewBlock, { account: account }, "account-overview")),
            },
            {
                id: "account-details",
                label: "Account details",
                renderer: (_jsx(AccountDetailsBlock, { account: account }, "account-details")),
            },
            {
                id: "account-calls",
                label: "Calls",
                renderer: _jsx(AccountCallsBlock, { account: account }, "account-calls"),
            },
            {
                id: "account-contacts",
                label: "Contacts",
                renderer: (_jsx(AccountContactsBlock, { account: account }, "account-contacts")),
            },
        ];
    }, [account]);
    const scrollToBlock = (blockId) => {
        const blocksRefMap = getBlockRefMap();
        if (blocksRefMap.has(blockId)) {
            blocksRefMap.get(blockId).scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        }
    };
    return { accountBlocks, scrollToBlock, blocksRefCallback };
};
